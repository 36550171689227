import React, { Fragment } from 'react';
import { useHistory } from 'react-router';
import {
    Grid, Table, TableBody, TableCell, TableContainer, TableHead, Paper, TableRow,
    Button,Box
} from '@mui/material';
import { getTaxFillingData } from './selectors/TaxFilingPlanSelector';
import { useSelector,useDispatch } from 'react-redux';
import { getTaxFilingPlan,setNewPlan } from '../../redux/actions/taxFilingPlanAction';

export default function HistoryScreen({firstName}) {
    const taxFilingPlanData = useSelector(getTaxFillingData);
    let rowDataService = [];
    const dispatch = useDispatch();
    const history = useHistory();

    const renderData = () => {
      let taxFilingData = [];
        if (taxFilingPlanData && taxFilingPlanData.taxFilingPlans) {
          taxFilingData = taxFilingPlanData.taxFilingPlans.taxfilingPlans;
            if (taxFilingData) {
              rowDataService = [];
            }
            taxFilingData && taxFilingData.forEach(data => {
                return rowDataService.push(
                    {
                        id: data.id,
                        businessInfoId: data.businessInfoId,
                        businessName: data.businessName,
                        lastVisitedPage: data.lastVisitedPage,
                        locationInfoId: data.locationInfoId,
                        status: data.status,
                        taxYear: data.taxYear
                    })
            })
        }
        return rowDataService;
    }

  const onSubmit = (taxFilingPlanId) => {
    dispatch(getTaxFilingPlan(taxFilingPlanId));
    history.push('/appClientHome');
  };
let rows = renderData();
    return (
        <Fragment>
            {/* <form onSubmit={handleSubmit(onSubmit)} > */}
            <Grid container >
            Welcome Back {firstName} !

            You were working on below tax filings earlier, click on the continue button to complete it or click Get Started button to start new tax filing. 

            <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Business Name</TableCell>
            <TableCell align="right">Tax  Year</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Last Visited Page</TableCell>
            <TableCell align="right">Take Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell >{row.businessName}</TableCell>
              <TableCell align="right">{row.taxYear}</TableCell>
              <TableCell align="right">{row.status}</TableCell>
              <TableCell align="right">{row.lastVisitedPage}</TableCell>
              <TableCell align="right">
              <Button type="button" disabled={row.status !== 'ACTIVE'} onClick={ e => {
                  onSubmit(row.id);
              }} variant="contained" size="medium" sx={{ mr: 1 , width: '50px',height:'20px' }}>
                {row.status === 'ACTIVE'?'Continue':'View'}
              </Button>
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={{ marginRight:'0',marginLeft:'auto',display:'block'}}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button variant="contained" size="medium" sx={{ mr: 1 , width: '200px',height:'50px' }} style={{float:'right'}}
            onClick={ e => {   
              dispatch(setNewPlan());
              history.push('/appClientHome');
            }} type="button">Get Started</Button>
          </Box>
            </Grid>

        </Fragment>
    );
}

