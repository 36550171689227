import React, {useState,useEffect} from "react";
import { useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import { Grid , Box, FormControl, FormControlLabel, Radio, FormLabel,RadioGroup,Typography,Divider,LinearProgress} from '@mui/material';
import { useDispatch,useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { postRepresentationInfo } from '../../../redux/actions/RepresentationAction';
import { verifyRepresentationInfoZipCode } from '../../../redux/actions/RepresentationAction';
import { getRepresentationInfo,getIsLoadingRepresentationStateCityValues } from '../selectors/RepresentationInfoSelector';
import { getTaxInfo } from '../selectors/TaxInfoSelector';
import { getCityValue, getStateValue } from '../AppClientSelectors';
import { getRepresentation } from '../../../redux/actions/RepresentationAction';
import { getTaxFillingData } from '../selectors/TaxFilingPlanSelector';
import { setAppServiceInfo } from '../../../redux/actions/AppServiceInfoAction';


const useStyles = makeStyles((theme) => ({
    fieldContainer: {
      width:'90vw',
      [theme.breakpoints.down("sm")]: {
        width: '45vw',
        left: '20%'
      },
    },
  }));


export default function Representation({nextButton,saveButton}) {
    const classes = useStyles();
    const { register, handleSubmit, setValue,formState: { errors } } = useForm();
    const dispatch = useDispatch();
    const representationInfoData = useSelector(getRepresentationInfo);
    let stateValue = representationInfoData.state;
    let cityValue = representationInfoData.city;
    const [ representationInfo,setRepresentationInfo ] = useState({...representationInfoData}); 
    const [ securedParty,setSecuredParty ] = useState("");
   
    const taxInfo = useSelector(getTaxInfo);
    const taxFilingPlanData = useSelector(getTaxFillingData);
    const stateValues = useSelector(getStateValue);
    const cityValues = useSelector(getCityValue);
    const isLoadingStateValues = useSelector(getIsLoadingRepresentationStateCityValues);
    let taxFilingPlanId = null;
    if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
      if(taxFilingPlanData.taxFilingPlan){
          taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
 
      }
  }
  useEffect(() => {
    dispatch(getRepresentation(taxFilingPlanId));
    dispatch(setAppServiceInfo(0,null,false,false));
  },[]);
  useEffect(() => {

    let data = {};
    if(representationInfoData.address) {
        data = {
            addressLine1:representationInfoData.address.line1,
            addressLine2:representationInfoData.address.line2,
            zip:representationInfoData.address.zipcode
        };
         cityValue = representationInfoData.address.city;
        stateValue = representationInfoData.address.state;
        setValue('zip',representationInfoData.address.zipcode);
        
    }
    if(representationInfoData.city) {
        cityValue = representationInfoData.city;
    }
    if(representationInfoData.state) {
        stateValue = representationInfoData.state;
    }
    setValue('city', Number.isInteger(cityValue) ? getCityCodeValue(cityValue) : cityValue);
    setValue('state', Number.isInteger(stateValue) ? getStateCodeValue(stateValue) : stateValue);
    setValue('ownerName',representationInfoData.ownerName);
    setValue('phoneNumber',representationInfoData.phoneNumber);
   
      if (representationInfoData.securedPartyConsentInd !==undefined) {
        setSecuredParty(representationInfoData.securedPartyConsentInd === 1 ? "Yes":"No");
   }
    setRepresentationInfo({...representationInfoData,...data})
  },[representationInfoData]);
    

    const getStateId = (stateName) => {
        for(let value of stateValues){
            if(value.codeValueKey === stateName){
                return value.id
            }
        }
    }

    const getStateCodeValue = (stateId) => {
        for(let value of stateValues){
            if(value.id === stateId){
                return value.codeValueKey
            }
        }
    }
    const getCityId = (cityName) => {
        for (let value of cityValues) {
            if (value.codeValueKey === cityName) {
                return value.id
            }
        }  
    }
    const getCityCodeValue = (cityId) => {
        for (let value of cityValues) {
            if (value.id === cityId) {
                return value.codeValueKey
            }
        }  
    }

    useEffect(() => {
        setValue('city', Number.isInteger(cityValue) ? getCityCodeValue(cityValue) : cityValue);
        setValue('state', Number.isInteger(stateValue) ? getStateCodeValue(stateValue) : stateValue);
    },[cityValue, stateValue])

    const onSubmit = (data,lastVisitedPage) => {
       let stateValue = Number.isInteger(data.state) ? data.state : getStateId(data.state);
       let cityValue = Number.isInteger(data.city) ? data.city : getCityId(data.city);
       let securedPartyConsentInd = securedParty === 'Yes'? 1: 0;
       let updateFlag = representationInfoData.id ? true: false
       let addressId = updateFlag ? representationInfo.address.id : ''
        dispatch(postRepresentationInfo({...data,state:stateValue,city:cityValue,taxFilingPlanId:taxInfo.id,securedPartyConsentInd: securedPartyConsentInd,
            representationAs:'OWNER', id: representationInfo.id, addressId: addressId},true,lastVisitedPage, updateFlag));
    }

    const zipCodeLookUpValues = (zipcode) => {
        if (zipcode.length === 5) {
            dispatch(verifyRepresentationInfoZipCode(representationInfo,zipcode));
        } else {
            setValue('city', '');
            setValue('state', '');
        }
    }
   

    return (
        <Box style={{position:'relative',left:'2%',bottom:'15%',textAlign:'justify',minHeight:'350px'}}>
        <form onSubmit={handleSubmit(onSubmit)} >
            <Grid container direction="column">
                <Grid item xs={12} sx={{ m: 1 }}>
                    <h1 style={{textAlign:'center'}}>Representation
                        <Typography className={classes.loginTitle} style={{ float: 'right' }}>
                            <span style={{color: 'red'}}>*</span>  Required fields 
                        </Typography><Divider />
                    </h1> 
                </Grid>
                 <Grid item xs={12} sx={{ m: 1 ,width: '70vw' }}>
                    <TextField required id="businessName" label="Name of Authorized Agent" variant="outlined" 
                    {...register('ownerName', { required: "Please enter owner name", maxLength: 30,
                    onChange: (e) => { setRepresentationInfo({...representationInfo, ownerName: e.target.value}); } })}
                    className={classes.fieldContainer}
                    value = {representationInfo.ownerName}
                    helperText={errors.ownerName ? errors.ownerName.message : ""}
                    error={errors.ownerName}
                    />
                </Grid>
                <Grid item xs={12} sx={{ m: 1 ,width: '70vw' }}>
                <TextField 
                    required id="mobilePhoneNumber" label="Mobile Phone Number" type="number"
                    variant="outlined"
                    {...register('phoneNumber',
                        { 
                            required: 'Phone number is required', 
                            maxLength: { value: 10, message: 'Only 10 characters are allowed' },
                            pattern: {
                                value: /^[0-9]+$/,
                                message: 'Only numericals are allowed'
                            },
                            onChange: (e) => { setRepresentationInfo({...representationInfo, phoneNumber: e.target.value}); }
                    })}
                    className={classes.fieldContainer}
                    helperText={errors.phoneNumber ? errors.phoneNumber.message : ""}
                    error={errors.phoneNumber}
                    value = {representationInfo.phoneNumber ? representationInfo.phoneNumber : ''}
                />
            </Grid>
                <Grid item sx={{ m: 1}} > 
                    <TextField 
                        required id="addressLine1" label="Company Mailing Address" 
                        variant="outlined" {...register('addressLine1', {
                             required: "Please enter mailing address line1", maxLength: 30
                             ,onChange: (e) => { setRepresentationInfo({...representationInfo, addressLine1: e.target.value}); } })}
                        className={classes.fieldContainer}
                        value = {representationInfo.addressLine1 ? representationInfo.addressLine1:''}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item sx={{ m: 1}} >
                    <TextField 
                        id="addressLine2" label="Address Line 2" 
                        variant="outlined" {...register('addressLine2',{  maxLength: 30,
                            onChange: (e) => { setRepresentationInfo({...representationInfo, addressLine2: e.target.value}); } })}
                        className={classes.fieldContainer}
                        value = {representationInfo.addressLine2 ? representationInfo.addressLine2:''}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item sx={{ m: 1}} >
                <TextField 
                    required id="zip" label="Zip" 
                    variant="outlined" 
                    {...register('zip', 
                        {
                            required: 'Zipcode is required', 
                            maxLength: { value: 5, message: 'Only 5 characters are allowed' },
                            pattern: {
                                value: /^[0-9]+$/,
                                message: 'Only numericals are allowed'
                            },
                            onChange: (e) => {
                                 setRepresentationInfo({...representationInfo, zip: e.target.value});
                                  zipCodeLookUpValues(e.target.value); }
                        })
                    }
                    className={classes.fieldContainer}
                    helperText={errors.zip ? errors.zip.message : ""}
                    error={errors.zip}
                    value = {representationInfo.zip?representationInfo.zip:''}
                />
                </Grid>
                {isLoadingStateValues && <Grid item>
                <LinearProgress />
            </Grid>}
                <Grid item sx={{ m: 1}} >
                    <TextField 
                        id="city" label="City" 
                        variant="outlined" {...register('city', {  minLength: 1 })}
                        InputLabelProps={{ shrink: true }}
                        className={classes.fieldContainer}
                        value={Number.isInteger(cityValue) ? getCityCodeValue(cityValue) : cityValue}
                    />
                </Grid>
                <Grid item sx={{ m: 1}} >
                    <TextField 
                        id="state" label="State" 
                        variant="outlined" {...register('state', { minLength: 1 })}
                        InputLabelProps={{ shrink: true }}
                        className={classes.fieldContainer}
                        value={Number.isInteger(stateValue) ? getStateCodeValue(stateValue) : stateValue}
                    />
                </Grid>
                <Grid item sx={{ m: 1}} >
                    <FormControl>
                        <FormLabel required id="demo-radio-buttons-group-label">Are you a secured party with a security interest in the property subject to this rendition and with a historical cost new of more than $50,000 as defined by 
Tax Code Section 22.01(c-1) and (c-2)?</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            {...register('securedParty')}
                            value = {securedParty}
                            onChange={e => setSecuredParty(e.target.value)}
                        >
                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            
                
                <input type="submit" ref={nextButton} style={{ display: 'none' }} onClick={handleSubmit((data) => {onSubmit(data,'BusinessInfo')})}/>
                <input type="submit" ref={saveButton} style={{ display: 'none' }} onClick={handleSubmit((data) => {onSubmit(data,'BasicInfo')})}/>
            </Grid>
            
        </form>
        </Box>
      );
}