import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { homeContent } from './HomeContent';
import ScrollUpButton from "react-scroll-up-button";
import { getIsLoggingIn, getIsAuthorized } from "./login/LoginSelectors";
import LoginForm from './login/LoginForm';
import Spinner from '../spinner/Spinner';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../constants/colorScheme';
import { useHistory } from 'react-router';
import { useEffect } from 'react';

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    homeContainer: {
      color: '#2e415c',
      backgroundColor: 'white',
      padding: '30px',
    },
    homeTitle: {
      fontWeight: 'bold'
    },
    desc: {
      padding: '25px',
      textAlign: 'left'
    },
    actionBtn: {
      width: 'calc(100% - 100px)',
      fontWeight: 'bold',
      margin: '10px 0',
      '&:hover': {
        backgroundColor: PRIMARY_COLOR,
        color: SECONDARY_COLOR,
        animation: 0
    }
    }
  }));

const HomeView = () => {
    const classes = useStyles();
    const isLoggingIn = useSelector(getIsLoggingIn);
    const isLoggedIn = useSelector(getIsAuthorized);
    const history = useHistory();

    useEffect(()=> {
      if(isLoggedIn){
        history.push('/appClientHome');
      }
    })
    return (
      <Box className={classes.root} >
        <Grid container justifyContent='center' alignItems='center' className={classes.homeContainer}>
          <Grid container justifyContent='center'>
            <Grid item xs={12} md={7}>
              <Grid container justifyContent='center' alignItems="center">
                  <Grid item xs={12}>
                    <Typography variant="h4"  className={classes.homeTitle} >{homeContent.title.value} </Typography>
                  </Grid>
                {homeContent.mainContent.map((item, index) => (
                    // <Fade in={true} timeout={1000 + (index * 1000)} key={item.key}>
                      <Grid item xs={12} className={classes.desc} key={item.key}>
                        <Typography variant="h5">
                            {item.value}
                          </Typography>
                      </Grid>
                    // </Fade>
                  ))}
                </Grid>
              </Grid>
              {!isLoggedIn ? <Grid item xs={12} md={5}>
                <Grid container justifyContent='center' alignItems="center">
                  <Grid item xs={12}>
                    {/* <Fade in={true} timeout={3000}> */}
                      <LoginForm />
                    {/* </Fade> */}
                  </Grid>
                </Grid>
              </Grid> : <React.Fragment />}
          </Grid>
         
        </Grid>
        {isLoggingIn && <Spinner />}
        <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/>
      </Box>
    );
}
export default HomeView;

// {!isLoggedIn ? <Grid item xs={12} md={5}>
// <Grid container justifyContent='center' alignItems="center">
//   <Grid item xs={12}>
//     {/* <Fade in={true} timeout={3000}> */}
//       <LoginForm />
//     {/* </Fade> */}
//   </Grid>
// </Grid>
// </Grid> : history.push('/appClientHome')}