import { NotificationManager } from "react-notifications";
import { postSuppliesInfo, getSuppliesInfo, patchSuppliesInfo} from "../../api/SuppliesApi";
import { setSuppliesInfoStatus } from "../../components/appClient/AppClientActions";
import { putTaxInfo } from './taxInfoAction';
import { setAppServiceInfo } from './AppServiceInfoAction';

const { logger } = require('../../logger');

export const SET_SUPPLIES_INFO = "SET_SUPPLIES_INFO";
export const SET_SUPPLIES_INFO_SUCCESS = "SET_SUPPLIES_INFO_SUCCESS";
export const SET_SUPPLIES_INFO_ERROR = "SET_SUPPLIES_INFO_ERROR";

export const GET_SUPPLIES_INFO = "GET_SUPPLIES_INFO";
export const GET_SUPPLIES_INFO_SUCCESS = "GET_SUPPLIES_INFO_SUCCESS";
export const GET_SUPPLIES_INFO_ERROR = "GET_SUPPLIES_INFO_ERROR";

export const UPDATE_SUPPLIES_INFO_SUCCESS = 'UPDATE_SUPPLIES_INFO_SUCCESS';

export const submitSuppliesInfo = (payload,businessInfoId,isPageSubmitted,lastVisitedPage) => {
    return (dispatch, getState) => {
        let locationId = getState().appClientCtx.currLocation;
        if(typeof locationId === "string" && locationId.trim().length === 0) {
            let locationInfo = getState().locationInfo;
            if(locationInfo && locationInfo.locationInfo && locationInfo.locationInfo.id) {
             locationId = getState().locationInfo.locationInfo.id;
            }
            if(typeof locationId === "string" && locationId.trim().length === 0){
                locationId = getState().taxFilingPlanInfoReducer.taxFilingPlan.locationInfoId;
            }
        }
        let taxYear = getState().taxInfoReducer.taxInfo.taxYear;
        let taxFilingPlanId = getState().taxInfoReducer.taxInfo.id;
        // let businessInfoId = getState().taxFilingPlanInfoReducer.taxFilingPlan.businessInfoId;
        // let locationInfoId = getState().taxFilingPlanInfoReducer.taxFilingPlan.locationInfoId;

        let postPayload = {
            locationInfoId : locationId,
            taxYear : taxYear,
            expenseForYear: payload.totalExpensesYearly,
            averageReorder: payload.reOrderTimes,
            taxFilingPlanId:getState().taxInfoReducer.taxInfo.id
        }
        dispatch({ type: SET_SUPPLIES_INFO });
        postSuppliesInfo({ ...postPayload }).then((response) => {
            if (response.status === 200) {
                logger.info(`Successfully posted Supplies Information for locationId:${locationId},taxYear:${taxYear}`);
                dispatch({ type: SET_SUPPLIES_INFO_SUCCESS, payload });
                dispatch(putTaxInfo(taxFilingPlanId,businessInfoId,locationId,lastVisitedPage,true));
                dispatch(getSuppliesInfoRelatedToYearAndLocation());
                // dispatch(setAppServiceInfo(response.status,"Supplies Info successfully submitted",false,isPageSubmitted));
            }
        }).catch((err) => {
            logger.error(`Failed to post Supplies Information for locationId:${locationId},taxYear:${taxYear},err:${err}`);
            dispatch({ type: SET_SUPPLIES_INFO_ERROR, payload: err });
            NotificationManager.error(err.message);
            dispatch(setAppServiceInfo(err.response.status,"Supplies Info error",true,isPageSubmitted));
        })  

    }
}

export const getSuppliesInfoRelatedToYearAndLocation = () => {
    return (dispatch, getState) => {
        dispatch({type: GET_SUPPLIES_INFO})
        let taxYear = getState().taxFilingPlanInfoReducer.taxFilingPlan.taxYear;
        let locationId,taxFilingPlanId;
        let taxFilingPlanData = getState().taxFilingPlanInfoReducer;
        let locationInfo = getState().locationInfo.locationInfo;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
            }
        }
        if(locationId == null || locationId === undefined){
            locationId = locationInfo != null ? locationInfo.id:'';
        }
        if(taxFilingPlanId == null || taxFilingPlanId === undefined){
            taxFilingPlanId = locationInfo != null ? locationInfo.taxInfoId:'';
        }
     
        getSuppliesInfo(locationId, taxFilingPlanId, taxYear).then((response) => {
            if (response.status === 200 && response.data.id !== undefined) {
                let data = response.data;
                let payloadData = {
                    totalExpensesYearly :data.expenseForYear,
                    reOrderTimes: data.averageReorder,
                    id : data.id
                }
                if(payloadData.id){
                    logger.info(`Successfully retrieved Supplies Information for locationId:${locationId},taxYear:${taxYear}`);
                    dispatch({ type: GET_SUPPLIES_INFO_SUCCESS, payload: payloadData })
                    dispatch(setSuppliesInfoStatus(true));   
                }
            } 
            else if (response.status === 400) {
                logger.error(`Failed to retrieve Supplies Information for locationId:${locationId},taxYear:${taxYear},status code:400`);
                dispatch({type: GET_SUPPLIES_INFO_ERROR});
                dispatch(setSuppliesInfoStatus(false));
            }
            else {
                logger.warn(`Got empty payload->  Supplies Information for locationId:${locationId},taxYear:${taxYear}`);
                dispatch({type: GET_SUPPLIES_INFO_SUCCESS, payload: {}});
                dispatch(setSuppliesInfoStatus(false));
            }
        }).catch((err) => {
            logger.error(`Failed to retrieve Supplies Information for locationId:${locationId},taxYear:${taxYear},err:${err}`);
            dispatch({type: GET_SUPPLIES_INFO_ERROR});
        });
    }
}

export const updateSuppliesInfo = (payload,businessInfoId,isPageSubmitted,lastVisitedPage) => {
    return (dispatch, getState) => {
        
        let suppliesid = getState().suppliesInfo.suppliesInfo.id;
        let locationInfoId,taxFilingPlanId;
        let taxFilingPlanData = getState().taxFilingPlanInfoReducer;
        let locationInfo = getState().locationInfo.locationInfo;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
            }
        }
        if(locationInfoId == null || locationInfoId === undefined){
            locationInfoId = locationInfo != null ? locationInfo.id:'';
        }
        if(taxFilingPlanId == null || taxFilingPlanId === undefined){
            taxFilingPlanId = locationInfo != null ? locationInfo.taxInfoId:'';
        }
        // let businessInfoId = getState().taxFilingPlanInfoReducer.taxFilingPlan.businessInfoId;
       
        let updatePayload = {
            'expenseForYear': payload.totalExpensesYearly,
            'averageReorder': payload.reOrderTimes
        }

        patchSuppliesInfo(suppliesid, updatePayload).then((response) => {
            if (response.status === 200) {
                logger.info(`Successfully updated Supplies information for the suppliesid:${suppliesid}`);
                dispatch({ type: UPDATE_SUPPLIES_INFO_SUCCESS, payload: updatePayload });
                dispatch(getSuppliesInfoRelatedToYearAndLocation());
                dispatch(putTaxInfo(taxFilingPlanId,businessInfoId,locationInfoId,lastVisitedPage,true));
                // dispatch(setAppServiceInfo(response.status,"Supplies Info successfully submitted",false,isPageSubmitted));
            }
        }).catch((err) => {
            logger.error(`Failed to update Supplies Information for suppliesid:${suppliesid},err:${err}`);
            dispatch(setAppServiceInfo(err.response.status,"Supplies Info error",true,isPageSubmitted));
        })
    }
}