import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import { Grid , Box, FormControlLabel, MenuItem, FormLabel, RadioGroup, Radio, InputLabel, Select, OutlinedInput,Typography,Divider, FormHelperText, LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { PRIMARY_COLOR } from "../../../constants/colorScheme";
import { fetchBusinessTypes, fetchSicCodes } from "../../../redux/actions/BusinessInfoAction";
import { getBusinessTypes, getSicCodes } from "../selectors/BusinessInfoSelector";
import { getAdditionalBusinessInfoData, verifyAdditionalBusinessInfoZipCode } from "../../../redux/actions/AdditionalBusinessInfoActions";
import { getAdditionalBusinessCityValue, getAdditionalBusinessStateValue, getAddtBizPayload, getIsLoadingAdditionalBusinessStateCityValues } from "../selectors/AdditionalBusinessInfoSelectors";
import { submitAdditionalBusinessInfo } from "../../../redux/actions/AdditionalBusinessInfoActions";
import { getCityValue, getStateValue } from "../AppClientSelectors";
import { getTaxFillingData } from '../selectors/TaxFilingPlanSelector';
import { setAppServiceInfo } from '../../../redux/actions/AppServiceInfoAction';
import { formatNumberInput } from '../../../util/utilities';
import { now } from "moment";

const useStyles = makeStyles((theme) => ({
    fieldContainer: {
      width:'90vw',
      [theme.breakpoints.down("sm")]: {
        width: '45vw',
        left: '20%'
        },
        "& .MuiInputLabel-root": {
            color: PRIMARY_COLOR,
            fontWeight: 'bold'
        },
    },
  }));



export default function AdditionalBusinessInformation({nextButton,saveButton}) {
    const { register, handleSubmit, setValue, reset, setError,formState: { errors } } = useForm({criteriaMode: 'all'});
    const dispatch = useDispatch();
    const classes = useStyles();
    const onSubmit = (data,lastVisitedPage) => {
        let businessSoldDate = new Date(data.businessSoldDate);
       let businessStartDateAtLoc = new Date(data.businessStartDateAtLoc);
       let businessMovedDate = new Date(data.businessMovedDate);
       let businessClosedDate = new Date(data.businessClosedDate);
    //    if(data.businessType === null || data.businessType === undefined){
    //     setError('businessType',{message: 'business type is required'});
        
    //    }
       setError("businessType", {
        types: {
          required: "This is required",
          minLength: "This is minLength"
        }
      });
       if(data.assetsConfirmationInd === 'Yes'){
        data.assetsConfirmationInd = 1;
       } else{
        data.assetsConfirmationInd = 0;
       }

        let parsedData = {
            ...data,
            state: Number.isInteger(additionalBusinessInfo.state) ? additionalBusinessInfo.state : getIdOfValue('state', additionalBusinessInfo.state),
            city: Number.isInteger(additionalBusinessInfo.city) ? additionalBusinessInfo.city : getIdOfValue('city', additionalBusinessInfo.city),
            id: additionalBusinessInfo.id,
            businessSoldDate:  new Date( businessSoldDate.getTime() + Math.abs(businessSoldDate.getTimezoneOffset()*60000) ).getTime(),
            businessStartDateAtLoc: new Date( businessStartDateAtLoc.getTime() + Math.abs(businessStartDateAtLoc.getTimezoneOffset()*60000) ).getTime(),
            businessMovedDate: new Date( businessMovedDate.getTime() + Math.abs(businessMovedDate.getTimezoneOffset()*60000) ).getTime(),
            businessClosedDate: new Date( businessClosedDate.getTime() + Math.abs(businessClosedDate.getTimezoneOffset()*60000) ).getTime()
        }
        Object.keys(parsedData).forEach(key => {
            if (!parsedData[key] & key!== 'assetsConfirmationInd') {
                delete parsedData[key];
            }
        });
        dispatch(submitAdditionalBusinessInfo({...parsedData }, true,lastVisitedPage));
    }

    const [assetsConfirmationInd,setAssetsConfirmationInd] = useState('No');
    const BUSINESS_TYPE = useSelector(getBusinessTypes);
    const SIC_CODE = useSelector(getSicCodes);
    const stateValue = useSelector(getAdditionalBusinessStateValue);
    const cityValue = useSelector(getAdditionalBusinessCityValue);
    const isLoadingStateValues = useSelector(getIsLoadingAdditionalBusinessStateCityValues);
    const stateValues = useSelector(getStateValue);
    const cityValues = useSelector(getCityValue);
    const additionalBusinessInfoData = useSelector(getAddtBizPayload);
    const [additionalBusinessInfo, setAdditionalBusinessInfo] = useState({ ...additionalBusinessInfoData });
    const taxFilingPlanData = useSelector(getTaxFillingData);
    const [businessType,setBusinessType] = useState([]);
    const [sicCode,setSicCode] = useState([]);
     
    let KeyValuePairsToGetCDFMeaning = {}
    BUSINESS_TYPE.forEach((value) => (
        KeyValuePairsToGetCDFMeaning[value.display] = value.cdfMeaning
    ))
    
    const zipCodeLookUpValues = (zipcode) => {
        if (zipcode.length === 5) {
            dispatch(verifyAdditionalBusinessInfoZipCode(additionalBusinessInfo,zipcode));
        } else {
            setValue('city', '');
            setValue('state', '');
        }
    }

    const getIdOfValue = (valueType, value) => {
        switch (valueType) {
            case 'state':
                const stateId = stateValues.filter((item) =>  (item.codeValueKey === value)).map((item) => item.id);
                return stateId[0];
            case 'city':
                const cityId = cityValues.filter((item) =>  (item.codeValueKey === value)).map((item) => item.id);
                return cityId[0];
            default:
                return '';
        } 
    }

    const getValueOfId = (valueType, value) => {
        switch (valueType) {
            case 'state':
                const stateCodeValue = stateValues.filter((item) =>  (item.id === value)).map((item) => item.codeValueKey);
                return stateCodeValue[0];
            case 'city':
                const cityCodeValue = cityValues.filter((item) =>  (item.id === value)).map((item) => item.codeValueKey);
                return cityCodeValue[0];
            default:
                return '';
        } 
    }

    useEffect(() => {
        dispatch(fetchBusinessTypes());
        setValue('city', cityValue?cityValue:'');
        setValue('state', stateValue?stateValue:'');   

    },[dispatch, cityValue, stateValue])
    

    useEffect(() => {
        if(taxFilingPlanData.isPlanSelected) {
            dispatch(getAdditionalBusinessInfoData());
        }
        if (additionalBusinessInfo.businessType) {
            dispatch(fetchSicCodes(BUSINESS_TYPE.filter((value)=> (value.id === additionalBusinessInfo.businessType))[0].cdfMeaning));
        }
        dispatch(setAppServiceInfo(0,null,false,false));
    }, []);
   
      React.useEffect(() => {
      
      }, [setError])
    

    
    useEffect( () => {
        if(additionalBusinessInfo.businessType && BUSINESS_TYPE !== null && BUSINESS_TYPE !== undefined && BUSINESS_TYPE.length > 0) {
            dispatch(fetchSicCodes(BUSINESS_TYPE.filter((value)=> (value.id === additionalBusinessInfo.businessType))[0].cdfMeaning));
            setSicCode(additionalBusinessInfo.sicCode?additionalBusinessInfo.sicCode:'');
            setError('businessType',{});
        setError('sicCode',{});
        }
    },[BUSINESS_TYPE])
    useEffect(() => {
        
        setBusinessType(additionalBusinessInfo.businessType?additionalBusinessInfo.businessType:'');
        setValue('businessType', additionalBusinessInfo.businessType?additionalBusinessInfo.businessType:'');
        
        setSicCode(additionalBusinessInfo.sicCode?additionalBusinessInfo.sicCode:'');
        setValue('sicCode', additionalBusinessInfo.sicCode?additionalBusinessInfo.sicCode:'');
       
       
    });    
    useEffect(() => {
        setAdditionalBusinessInfo(additionalBusinessInfoData);
        reset();
    }, [additionalBusinessInfoData]);

    const util = (date) => {
        if(date !== undefined && date !== null) {
            if(!String(date).includes("T")) {
                date = new Date(date).toISOString();
            }
        }
        return date;
    }

    // let busSoldDate,busStartDate,busMovedDate,busClosedDate;
    useEffect(() => {
    
        // setValue('businessSoldDate',additionalBusinessInfo.businessSoldDate);
        if(additionalBusinessInfo.businessSoldDate){
          setValue('businessSoldDate',new Date(additionalBusinessInfo.businessSoldDate).toISOString().split('T')[0]);
        } else {
            setValue('businessSoldDate','');
        }
        if(additionalBusinessInfo.businessStartDateAtLoc){
            setValue('businessStartDateAtLoc',new Date(additionalBusinessInfo.businessStartDateAtLoc).toISOString().split('T')[0]);
        } else {
            setValue('businessStartDateAtLoc','');
        }
        if(additionalBusinessInfo.businessMovedDate){
            setValue('businessMovedDate',new Date(additionalBusinessInfo.businessMovedDate).toISOString().split('T')[0]);
         }  else {
            setValue('businessMovedDate','');
        }
         if(additionalBusinessInfo.businessClosedDate){
            setValue('businessClosedDate',new Date(additionalBusinessInfo.businessClosedDate).toISOString().split('T')[0]);
         }  else {
            setValue('businessClosedDate','');
        }
    });

  return (
    <Box style={{position:'relative',left:'2%',bottom:'15%',textAlign:'justify'}}>
    <form onSubmit={handleSubmit(onSubmit)} >
        <Grid container direction="column" spacing={2}>
            <Grid item xs={12} sx={{ m: 1 }}>
                <h1 style={{textAlign:'center'}}>Additional Business Information
                    <Typography className={classes.loginTitle} style={{ float: 'right' }}>
                            <span style={{color: 'red'}}>*</span>  Required fields 
                    </Typography><Divider />
                </h1> 
                <h5> Text to be added </h5>
            </Grid>
           
                          
            <Grid item>
                <InputLabel id="business-Code-label" required>Business Type</InputLabel>
                <Select labelId="business-Code-label"  id="businessType" label="Business Type"  value = {businessType}
                        input={<OutlinedInput label="Business Type" style={{ width: '100%' }} />}
                        {...register('businessType', {
                            required: 'Business Type is required',
                            onChange: (e) => {
                              setAdditionalBusinessInfo({ ...additionalBusinessInfo, businessType: e.target.value });
                              dispatch(fetchSicCodes(BUSINESS_TYPE.filter((value)=> (value.id === e.target.value))[0].cdfMeaning));
                            }
                         })    
                        }
                    error={!businessType && errors.businessType}
                    
                    >
                    {BUSINESS_TYPE.map((eachBiz) => (
                        <MenuItem  key={eachBiz.display}  value={eachBiz.id}  >
                            {`${eachBiz.display}`}
                        </MenuItem>
                    ))}
               
                </Select>
                <FormHelperText error>{!businessType && errors.businessType && errors.businessType.message}</FormHelperText>
            </Grid>
            <Grid item>
                <InputLabel id="Sic-Code-label" required>SIC Code</InputLabel>
                <Select labelId="Sic-Code-label"  id="sicCode" value = {sicCode?sicCode:''}
                    input={<OutlinedInput label="SIC Code" style={{ width: '100%' }} />}
                    {...register('sicCode', {
                        required: 'SicCode is required',
                        onChange: (e) => setAdditionalBusinessInfo({ ...additionalBusinessInfo, sicCode: e.target.value })

                    })}
                    error={!sicCode && errors.sicCode}
                    
                    >
                    {SIC_CODE.map((sicEach) => (
                        <MenuItem  key={sicEach.id}  value={sicEach.id}  >
                            {`${sicEach.codeValueKey}-${sicEach.display}`}
                        </MenuItem>
                    ))}
                    {/* className={classes.fieldContainer} */}
                </Select>
                <FormHelperText error>{!sicCode && errors.sicCode && errors.sicCode.message}</FormHelperText>
            </Grid>
            <Grid item>
                <TextField 
                    id="businessDescription" label="Business Description" variant="outlined" 
                    {...register('businessDescription', 
                        {
                            // required: 'Business Description is required', maxLength: 130,
                            onChange: (e) => {setAdditionalBusinessInfo({...additionalBusinessInfo, businessDescription: e.target.value})}
                        })}
                    InputLabelProps={{ shrink: true }}
                    className={classes.fieldContainer}
                    helperText={errors.businessDescription ? errors.businessDescription.message: ""}
                    error={errors.businessDescription}
                    value = {additionalBusinessInfo.businessDescription?additionalBusinessInfo.businessDescription:''}
                />
            </Grid>
            <Grid item>
                <TextField 
                    id="squareFootOccupied" label="Square Foot Occupied" variant="outlined" type="number"
                    {...register('squareFootOccupied',
                        {
                            // required: 'Squarefoot occupied is required', maxLength: 130,
                            onChange: (e) => {setAdditionalBusinessInfo({...additionalBusinessInfo, squareFootOccupied: e.target.value})}
                        })}
                        onKeyDown={ formatNumberInput }
                    className={classes.fieldContainer}
                    helperText={errors.squareFootOccupied ? errors.squareFootOccupied.message: ""}
                    error={errors.squareFootOccupied}
                    value = {additionalBusinessInfo.squareFootOccupied?additionalBusinessInfo.squareFootOccupied:''}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item>
                <TextField 
                    id="businessSoldDate" label="Business Sold Date" variant="outlined" type='date' InputLabelProps={{ shrink: true }} 
                    {...register('businessSoldDate',
                        {
                            // required: 'Business sold date is required', maxLength: 130,
                            validate: value => { 

                                const today = new Date();
                                const yyyy = today.getFullYear();
                                let mm = today.getMonth() + 1; // Months start at 0!
                                let dd = today.getDate();
                                
                                if (dd < 10) dd = '0' + dd;
                                if (mm < 10) mm = '0' + mm;
                                
                                const formattedToday = yyyy + '-' + mm + '-' + dd;

                                if(value <= formattedToday) {
                                    return true 
                                } else {
                                    return 'Please select valid Business sold date'
                                }
                            },
                            onChange: (e) => {setAdditionalBusinessInfo({...additionalBusinessInfo, businessSoldDate: e.target.value})}
                        })}
                    className={classes.fieldContainer}
                    helperText={errors.businessSoldDate ? errors.businessSoldDate.message: ""}
                    error={errors.businessSoldDate}
                />
            </Grid>
            <Grid item>
                <TextField 
                    id="businessStartDateAtLoc" label="Business Start Date At Location" variant="outlined" type={'date'} InputLabelProps={{ shrink: true, }} 
                    {...register('businessStartDateAtLoc',
                        {
                            // required: 'Business start date is required', maxLength: 130,
                            onChange: (e) => {setAdditionalBusinessInfo({...additionalBusinessInfo, businessStartDateAtLoc: e.target.value})}
                        })}
                    className={classes.fieldContainer}
                    helperText={errors.businessStartDateAtLoc ? errors.businessStartDateAtLoc.message: ""}
                    error={errors.businessStartDateAtLoc}
                />
            </Grid>
            <Grid item>
                <TextField 
                    id="salesTaxPermitNumber" label="Sales Tax Permit Number" variant="outlined" type="number"
                    {...register('salesTaxPermitNumber',
                        { 
                            // required: 'Sales tax permit number is required', maxLength: 130,
                            onChange: (e) => {setAdditionalBusinessInfo({...additionalBusinessInfo, salesTaxPermitNumber: e.target.value})}
                        })}
                        onKeyDown={ formatNumberInput }
                    className={classes.fieldContainer}
                    helperText={errors.salesTaxPermitNumber ? errors.salesTaxPermitNumber.message: ""}
                    error={errors.salesTaxPermitNumber}
                    value = {additionalBusinessInfo.salesTaxPermitNumber?additionalBusinessInfo.salesTaxPermitNumber:''}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item>
                <TextField 
                    id="newOwner" label="New Owner" variant="outlined" 
                    {...register('newOwner',
                        {
                            // required: 'New owner name is required', maxLength: 130,
                            onChange: (e) => {setAdditionalBusinessInfo({...additionalBusinessInfo, newOwner: e.target.value})}
                        })}
                    className={classes.fieldContainer}
                    helperText={errors.newOwner ? errors.newOwner.message: ""}
                    error={errors.newOwner}
                    value = {additionalBusinessInfo.newOwner?additionalBusinessInfo.newOwner:''}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item>
                <TextField 
                    id="businessMovedDate" label="Business Moved Date" variant="outlined" type={'date'} InputLabelProps={{ shrink: true, }} 
                    {...register('businessMovedDate',
                        {
                            // required: 'Business moved date is required', maxLength: 130,
                             validate: value => { 

                                const today = new Date();
                                const yyyy = today.getFullYear();
                                let mm = today.getMonth() + 1; // Months start at 0!
                                let dd = today.getDate();
                                
                                if (dd < 10) dd = '0' + dd;
                                if (mm < 10) mm = '0' + mm;
                                
                                const formattedToday = yyyy + '-' + mm + '-' + dd;

                                if(value <= formattedToday) {
                                    return true 
                                } else {
                                    return 'Please select valid Business moved date'
                                }
                            },
                            onChange: (e) => {setAdditionalBusinessInfo({...additionalBusinessInfo, businessMovedDate: e.target.value})}
                        })}
                    className={classes.fieldContainer}
                    helperText={errors.businessMovedDate ? errors.businessMovedDate.message: ""}
                    error={errors.businessMovedDate}

                />
            </Grid>
            <Grid item>
                    <TextField 
                        id="newAddressLine1" label="New Location Address" 
                        variant="outlined" 
                        {...register('newAddressLine1',
                            {
                                // required: 'Address is required', maxLength: 30,
                                onChange: (e) => {setAdditionalBusinessInfo({...additionalBusinessInfo, newAddressLine1: e.target.value})}
                            })}
                        className={classes.fieldContainer}
                        helperText={errors.newAddressLine1 ? errors.newAddressLine1.message: ""}
                        error={errors.newAddressLine1}
                        value = {additionalBusinessInfo.newAddressLine1?additionalBusinessInfo.newAddressLine1:''}
                        InputLabelProps={{ shrink: true }}
                    />
            </Grid>
            <Grid item>
                <TextField 
                    id="newAddressLine2" label="Address Line 2" 
                    variant="outlined" {...register('newAddressLine2', {  maxLength: 30,  onChange: (e) => {setAdditionalBusinessInfo({...additionalBusinessInfo, newAddressLine2: e.target.value})}})}
                    className={classes.fieldContainer}
                    value = {additionalBusinessInfo.newAddressLine2?additionalBusinessInfo.newAddressLine2:''}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item>
                <TextField 
                    id="zip" label="Zip" 
                    variant="outlined" 
                    {...register('zip', 
                        {
                            // required: 'Zipcode is required', 
                            maxLength: { value: 5, message: 'Only 5 characters are allowed' },
                            pattern: {
                                value: /^[0-9]+$/,
                                message: 'Only numericals are allowed'
                            },
                            onChange: (e) => { setAdditionalBusinessInfo({ ...additionalBusinessInfo, zip: e.target.value }); zipCodeLookUpValues(e.target.value); }
                        })
                    }
                    className={classes.fieldContainer}
                    helperText={errors.zip ? errors.zip.message: ""}
                    error={errors.zip}
                    value = {additionalBusinessInfo.zip?additionalBusinessInfo.zip:''}
                />
            </Grid>
            {isLoadingStateValues && <Grid item> <LinearProgress/> </Grid>}
            <Grid item>
                <TextField 
                    id="city" label="City" 
                    variant="outlined" {...register('city')}
                    className={classes.fieldContainer}
                    InputLabelProps={{ shrink: true }}
                    disabled
                    value={Number.isInteger(cityValue) ? getValueOfId('city',cityValue) : cityValue?cityValue:''}
                />
            </Grid>
            <Grid item>
                <TextField 
                    id="state" label="State" 
                    variant="outlined" {...register('state')}
                    className={classes.fieldContainer}
                    InputLabelProps={{ shrink: true }}
                    disabled
                    value={Number.isInteger(stateValue) ? getValueOfId('state',stateValue) : stateValue?stateValue:''}
                />
            </Grid>
            <Grid item>
                <TextField 
                    id="businessClosedDate" label="Business Closed Date" variant="outlined" type={'date'} InputLabelProps={{ shrink: true, }} 
                    {...register('businessClosedDate',
                        {
                            // required: 'Business closed date is required', maxLength: 130,
                             validate: value => { 

                                const today = new Date();
                                const yyyy = today.getFullYear();
                                let mm = today.getMonth() + 1; // Months start at 0!
                                let dd = today.getDate();
                                
                                if (dd < 10) dd = '0' + dd;
                                if (mm < 10) mm = '0' + mm;
                                
                                const formattedToday = yyyy + '-' + mm + '-' + dd;

                                if(value <= formattedToday) {
                                    return true 
                                } else {
                                    return 'Please select valid Business closed date'
                                }
                            },
                            onChange: (e) => {setAdditionalBusinessInfo({...additionalBusinessInfo, businessClosedDate: e.target.value})}
                        })}
                    className={classes.fieldContainer}
                    helperText={errors.businessClosedDate ? errors.businessClosedDate.message: ""}
                    error={errors.businessClosedDate}
                />
            </Grid>
            <Grid item>
                <FormLabel required component="legend" >Did assets remain in place as of Jan 1?   </FormLabel>
                <RadioGroup
                    row
                    id="assetsInPlace"
                    defaultValue="Yes"
                    className={classes.fieldContainer}
                    {...register('assetsConfirmationInd')}
                    value={(additionalBusinessInfo.assetsConfirmationInd === 1 || additionalBusinessInfo.assetsConfirmationInd === 'Yes') ? "Yes" : 'No'}
                    onChange={e => {
                        setAdditionalBusinessInfo({ ...additionalBusinessInfo, assetsConfirmationInd: e.target.value });
                        setAssetsConfirmationInd(e.target.value);
                    }}
                >
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            </Grid>
        <input type="submit" ref={nextButton} style={{ display: 'none' }} onClick={handleSubmit((data) => {onSubmit(data,'AssetsInfo')})}/>
        <input type="submit" ref={saveButton} style={{ display: 'none' }} onClick={handleSubmit((data) => {onSubmit(data,'AdditionalBusinessInfo')})}/>
        </Grid>
        
    </form>
    </Box>
  );
}
