import config from "../../config/context-root-configs";
import { svcRequest } from "../../util/requestHelper";
import { uspsvalidator } from "../../util/uspsConfig";
import { OPERATING_STATES } from "../../constants/operatingStates";
import { putTaxInfo } from './taxInfoAction';
import { setAppServiceInfo } from './AppServiceInfoAction';

const { logger } = require('../../logger');

export const SET_AGENT_REPRESENTATION_INFO = "SET_AGENT_REPRESENTATION_INFO";
export const SET_AGENT_REPRESENTATION_INFO_SUCCESS = "SET_AGENT_REPRESENTATION_INFO_SUCCESS";
export const SET_AGENT_REPRESENTATION_INFO_ERROR = "SET_AGENT_REPRESENTATION_INFO_ERROR";

export const FETCH_REPRESENTATION_CITY_STATE = 'FETCH_REPRESENTATION_CITY_STATE';
export const FETCH_REPRESENTATION_CITY_STATE_SUCCESS = 'FETCH_REPRESENTATION_CITY_STATE_SUCCESS';
export const FETCH_REPRESENTATION_CITY_STATE_FAILURE = 'FETCH_REPRESENTATION_CITY_STATE_FAILURE';

export const postRepresentationInfo = (payload,isPageSubmitted,lastVisitedPage, updateFlag) => {
    
    return (dispatch) => {
        
            console.log(payload)
            let address = {
                city: payload.city,
                line1: payload.addressLine1,
                line2: payload.addressLine2,
                state: payload.state,
                zipcode: payload.zip
            }
            dispatch( {type:SET_AGENT_REPRESENTATION_INFO} );
        if (updateFlag) {
            let updateAddress = {
                city: payload.city,
                line1: payload.addressLine1,
                line2: payload.addressLine2,
                state: payload.state,
                zipcode: payload.zip,
                id: payload.addressId
            }
            patchAgentRepresentationInfo({ ...payload, address: updateAddress }).then((response) => {
                dispatch( {type:SET_AGENT_REPRESENTATION_INFO_SUCCESS,payload: {...payload,id:response.data.id, address: updateAddress} });
                dispatch(putTaxInfo(payload.taxFilingPlanId,response.id,null,lastVisitedPage,true));
                // dispatch(setAppServiceInfo(response.status,"Representation Info successfully submitted",false,isPageSubmitted));
            }).catch((err) => {
                logger.error(`Failed to update Agent Representation Info for the id: ${payload.id}, error : -> ${err}`);
                dispatch({ type: SET_AGENT_REPRESENTATION_INFO_ERROR, payload: {...payload,error:err}});
                // dispatch(setAppServiceInfo(err.response.status,"Representation Info error",true,isPageSubmitted));
            });  
        } else {
            postAgentRepresentationInfo({...payload,address}).then((response) => {
                dispatch( {type:SET_AGENT_REPRESENTATION_INFO_SUCCESS,payload: {...payload,id:response.data.id} });
                dispatch(putTaxInfo(payload.taxFilingPlanId,response.id,null,lastVisitedPage,true));
                // dispatch(setAppServiceInfo(response.status,"Representation Info successfully submitted",false,isPageSubmitted));
            }).catch((err) => {
                logger.error(`Failed to post Agent Representation Info for the id: ${payload.id}, error : -> ${err}`);
                dispatch({ type: SET_AGENT_REPRESENTATION_INFO_ERROR, payload: {...payload,error:err}});
                dispatch(setAppServiceInfo(err.response.status,"Representation Info error",true,isPageSubmitted));
            });
        }
            
        }
    }

    export const postAgentRepresentationInfo = (payload) => {
        return svcRequest.post(config.representationInfo, payload, {
            timeout: 30000
          }
        );
    }
    export const patchAgentRepresentationInfo = (payload) =>{
        return svcRequest.patch(config.representationInfo + payload.id, payload, {timeout: 30000})
    }

    export const verifyRepresentationInfoZipCode = (representationInfo,zip) => {
        return (dispatch) => {
            dispatch({ type: FETCH_REPRESENTATION_CITY_STATE, payload: zip});
            setTimeout( () => {
                // call to fetch the city and state values from zipcode
                uspsvalidator.cityStateLookup(zip).then((res) => {
                    if(res.State && res.City){
                        logger.info(`Successfully retrieved City: ${res.City}, State: ${res.State}`);
                        if (OPERATING_STATES.includes(res.State)) {
                           
                            dispatch({ type: FETCH_REPRESENTATION_CITY_STATE_SUCCESS, payload: 
                                {...representationInfo,city:res.City,state:res.State,
                                zip: res.Zip5}});
                        }
                        else {
                            dispatch({ type: FETCH_REPRESENTATION_CITY_STATE_FAILURE, payload: { ...representationInfo }  });
                        }
                    }
                    else{
                        dispatch({ type: FETCH_REPRESENTATION_CITY_STATE_FAILURE, payload: { ...representationInfo } });
                    }
                }).catch((err) => {
                    logger.error(`Failed to fetch City,State from BusinessInfoActions, error : -> ${err}`);
                    dispatch({ type: FETCH_REPRESENTATION_CITY_STATE_FAILURE, payload: err});
                })
            }, 2000 )
        }
    }
    
    export const getRepresentation = (taxFilingPlanId) => {
        return (dispatch) => {
            let url = `${config.representationInfo}?taxFilingPlanId=${taxFilingPlanId}`
            svcRequest.get(url, {
                timeout: 30000
            }
            ).then(response => {
                dispatch({type: SET_AGENT_REPRESENTATION_INFO_SUCCESS,payload:response.data});
            });
           
        }

    }