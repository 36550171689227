import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Grid } from '@mui/material';
import { PRIMARY_COLOR } from '../constants/colorScheme';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      position: 'relative',
      bottom: 0,
      background: PRIMARY_COLOR,
      boxShadow: '1px -4px 5px 2px rgb(90 78 80 / 42%)',
      color: 'white',
    },
    grid:{
      padding: '24px',
      minHeight: '60px'
    },
    title: {
        flexGrow: 1,
        textAlign: 'left'
    },
  }));

const Footer = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}> 
            <Grid container alignItems="center" className={classes.grid}>
                <Grid item xs={4} sm={4} md={6} xl={6}>
                    <Typography variant="subtitle1" className={classes.title}>
                        ©2022 Business Property Tax
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default Footer;