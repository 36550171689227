import { 
    GET_TAX_INFO , POST_TAX_INFO_ERROR, PUT_TAX_INFO_ERROR,PUT_TAX_INFO_SUCCESS
} from '../actions/taxInfoAction';
import { LOGOUT } from '../../components/home/login/LoginAction';
import { RESET_TAX_INFO } from '../actions/taxFilingPlanAction';

const initialState = {
    taxInfo: { 
        businessName: "",
        directClientInfoId: 0,
        id: "",
        lastVisitedPage: "",
        status: "",
        taxYear: 0,
        error: false,
        errorResponse: undefined
    }
}

const taxInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TAX_INFO:
            return { ...state, taxInfo: action.payload }
        case PUT_TAX_INFO_SUCCESS:
            return { ...state, taxInfo: {...state.taxInfo,lastVisitedPage:action.payload} }
        case POST_TAX_INFO_ERROR:
            return { ...state, taxInfo: {...state.taxInfo,error: true, errorResponse: action.payload}}
        case PUT_TAX_INFO_ERROR:
            return { ...state, taxInfo: {...state.taxInfo,error: true, errorResponse:action.payload}}
        case LOGOUT:
        case RESET_TAX_INFO:
            return initialState;
        default:
            return state;
    }
};
export default taxInfoReducer;