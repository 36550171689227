import {
    SET_RENDITION_STATUS, SET_RENDITION_STATUS_SUCCESS, GET_RENDITION_LEASEDASSETS_DATA_SUCCESS,
    GET_RENDITION_ASSETS_DATA_SUCCESS,
    GET_RENDITION_SUPPLIES_DATA_SUCCESS,
    GET_RENDITION_INVENTORY_DATA_SUCCESS,
    GET_RENDITION_LOC_DATA_SUCCESS,
    GET_RENDITION_BIZ_DATA_SUCCESS,
    GET_RENDITION_ADDT_BUSINESS_DATA_SUCCESS
} from "../actions/RenditionAction"

const defaultState = {
    status: false,
    leasedAssets: {},
    assets: {},
    supplies: {},
    inventory: {},
    business: {},
    location: {},
    additionalBusiness:{}
}
const RenditionReducer = (state = defaultState, action) => {
    switch(action.type){
        case SET_RENDITION_STATUS_SUCCESS:
            return { ...state, status: action.payload};
        // case GET_RENDITION_LEASEDASSETS_DATA:
        // case GET_RENDITION_LEASEDASSETS_DATA_FAILURE:
        case SET_RENDITION_STATUS:
            return { ...state };
        // case SET_RENDITION_STATUS_FAILURE:
        // case GET_RENDITION_ASSETS_DATA:
        // case GET_RENDITION_ASSETS_DATA_FAILURE:
        case GET_RENDITION_ASSETS_DATA_SUCCESS:
            return { ...state, assets: action.payload };
        case GET_RENDITION_LEASEDASSETS_DATA_SUCCESS:
            return { ...state, leasedAssets: action.payload };
        case GET_RENDITION_SUPPLIES_DATA_SUCCESS:
            return { ...state, supplies: action.payload };
        case GET_RENDITION_INVENTORY_DATA_SUCCESS:
            return { ...state, inventory: action.payload };
        case GET_RENDITION_LOC_DATA_SUCCESS:
            return {...state, location: action.payload}
        case GET_RENDITION_BIZ_DATA_SUCCESS:
            return {...state, business: action.payload}
        case GET_RENDITION_ADDT_BUSINESS_DATA_SUCCESS:
            return {...state, additionalBusiness: action.payload}
        default: 
            return state;
    }
}
export default RenditionReducer;