let config = {}

//auth endpoints
config.loginVerify = '/auth/verify';
config.forgotPwd = '/auth/forgot';
config.register = '/auth/register';
config.verifyCode = '/auth/verifyCode';

//core endpoints
config.codeValues = '/codeValues';

//Core ClientBusiness Endpoint

config.clientBusinessInfo = '/core/directClientBusinessInfo/';
config.additionalBusinessInfo = '/core/additionalBusinessInfo/';
config.clientInfo = '/core/directClient/';

//LocationInfo Endpoint
config.locationInfo = '/core/locationInfo';

//Core Agent endpoints

config.agentBusinessInfo = '/core/agentBusinessInfo/';
config.agentInfo = '/core/agentInfo/';
config.representationInfo = '/core/agentRepresentationInfo/';

config.supplies = '/core/supplies/';
config.leasedAssets = '/core/leasedAsset/';
config.asset = '/core/asset/';

config.inventory = '/core/inventory/';
config.bulkAssets = '/core/bulkAssets/';
config.rendition = '/rendition/';
config.renditionAvailability = '/core/renditionAvailability/';

//Contact us

config.contact = '/auth/contactus';

// Banner info
config.bannerInfo = '/core/bannerInfo';

// Tax Info

config.taxInfo = '/core/taxFilingPlan';

//Tax Filing plans
config.taxInfos = '/core/taxFilingPlans';

export default config;