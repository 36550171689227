import React, { useRef, useState, Fragment } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Fade, TextField, Button, Typography, Box, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useDispatch } from 'react-redux';
import { submit } from './ForgotPwdActions';
import { content } from './ForgotPwdContent';
import { validateMailFormat } from '../../../util/utilities';
import { useHistory } from 'react-router';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../../constants/colorScheme';


const useStyles = makeStyles(() => ({
    box: {
        backgroundColor: 'white',
    },
    formContainer: {
        color: '#2e415c',
        backgroundColor: 'white',
        padding: '30px',
    },
    title: {
        fontWeight: 'bold'
    },
    container: {
        boxShadow: '2px 2px 11px 1px rgb(90 78 80 / 55%)',
        border: '1px solid PRIMARY_COLOR',
        padding: '10px',
        margin: '20px',
    },
    desc: {
        textAlign: 'left',
        fontWeight: '800'
    },
    formInputs:{
        padding: '2px 0',
    },
    fields: {
        minWidth: 'calc(100% - 40px)'
    },
    sendBtn: {
        width: 'calc(100% - 100px)',
        fontWeight: 'bold',
        margin: '10px 0',
        '&:hover': {
            backgroundColor: PRIMARY_COLOR,
            color: SECONDARY_COLOR,
            animation: 0
        }
    },
    actionBtn: {
        width: 'calc(100% - 100px)',
        fontWeight: 'bold',
        margin: '10px 0',
        '&:hover': {
          backgroundColor: PRIMARY_COLOR,
          color: SECONDARY_COLOR,
          animation: 0
      }
    },
  }));

const ForgotPwdView = () => {
    const errObjInitialState = {
        mailErr: null, validationErr: null
    }

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const formRef = useRef(null);
    let mailInput = useRef(null);
    const [errs, setErrs ] = useState(errObjInitialState);
    const [regEmail, setRegEmail ] = useState("");

    const clearValues = () => {
        setErrs({
            mailErr: null, validationErr: null
        });
        mailInput.current.value = "";
    }

    const handleChange = (evt) => {
        if(validateMailFormat(evt.target.value)){
            setRegEmail(evt.target.value);
            setErrs((prevState) => ({...prevState, mailErr: false, validationErr: false}));
        }
        else{
            setRegEmail('');
            setErrs((prevState) => ({...prevState, mailErr: false, validationErr: true}));
        }
    }

    const execScroll = () => formRef.current.scrollIntoView({ behavior: 'smooth' });

    const handleRedirect = (route) => {
        history.push(route);
    }

    const handleSubmit = () => {
        !regEmail ? setErrs((prevState) => ({...prevState, mailErr: true})) 
        : setErrs((prevState) => ({...prevState, mailErr: false}));
        
        if(Object.values(errs).every(item => item === false)){
            dispatch(submit(regEmail));
            clearValues();
        }else{
            execScroll();
        }
    }

    return (
        <Box className={classes.box}>
            <Grid container className={classes.formContainer}  ref={formRef}>
                <Grid item xs={12}>
                    <Typography variant="h4"  className={classes.title} >{content.title.value} </Typography>
                </Grid>
                <Grid container justifyContent='center'>
                    <Grid item xs={12} md={6}>
                        <Fade in={true} timeout={1500}>
                            <Grid container justifyContent='center' alignItems="center" style={{paddingTop: '20px'}}>
                                {content.mainContent.map((item) => (
                                    <Fragment key={item?.key} >
                                        <Grid item  xs={12} className={classes.desc}>
                                            <Typography variant="h5">
                                                {item.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} >
                                        {item.list && item.list.map((listItem) => (
                                                <ListItem component="div" key={listItem?.key} className={classes.listLinks}>
                                                    <ListItemIcon className={classes.caret}>
                                                        <ChevronRightIcon fontSize="small"/>
                                                    </ListItemIcon>
                                                    <ListItemText primary={listItem?.value} />
                                                </ListItem>
                                            ))}
                                        </Grid>
                                    </Fragment>
                                    )) }
                            </Grid>
                        </Fade>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Fade in={true} timeout={2500}>
                            <Grid container justifyContent='center' alignItems="center" className= {classes.container} ref={formRef}>
                                <Grid item xs={12}  className={classes.formInputs}>
                                    <Typography variant="h4"  className={classes.title} >Forgot Your Password? </Typography>
                                </Grid>
                                <Grid item xs={12}  className={classes.formInputs}>
                                    <TextField className={classes.fields} required id="email" label="Email"
                                    variant="standard" type='email' onChange={(e) => handleChange(e)}  error={errs.validationErr || errs.mailErr} 
                                    helperText={errs.validationErr ? `Enter valid email address`: (errs.mailErr ? 
                                        `Enter registered email address` : `Enter registered email address to reset password`)}
                                    inputRef={mailInput}/>
                                </Grid>
                                <Grid item xs={12}  className={classes.formInputs}>
                                    <Button className={classes.sendBtn} size="medium" color="inherit" 
                                        onClick={handleSubmit}>
                                        Submit
                                    </Button>
                                </Grid>
                                <Grid item xs={12} className={classes.formInputs}>
                                    <Button className={classes.actionBtn} size="medium" color="inherit" 
                                        onClick={() => handleRedirect('/register')}>
                                        New User? Click here to register
                                    </Button>
                                </Grid>
                            </Grid>
                        </Fade>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ForgotPwdView;
