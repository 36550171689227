import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import { Grid, Box, FormControlLabel, Checkbox, InputLabel, MenuItem, Modal, Backdrop, Fade, OutlinedInput, Select, Typography, Divider, FormHelperText, LinearProgress, Chip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { clearBusinessInfo, fetchOwnerShipTypes, getSelectedBusinessInfo, postBusinessInformation, verifyBusinessInfoZipCode } from '../../../redux/actions/BusinessInfoAction';
import { PRIMARY_COLOR } from "../../../constants/colorScheme";
import { getBusinessOfUser, getCityValue, getClientDetails, getStateValue } from '../AppClientSelectors';
import { getBusinessCityValue, getBusinessInfo, getBusinessStateValue, getIsLoadingBusinessStateCityValues, getownershipTypes } from '../selectors/BusinessInfoSelector';
import { getTaxInfo } from '../selectors/TaxInfoSelector';
import { getClientCheckDetails, getDirectClientInfoId, getProfilePayload } from '../../profile/userProfile/UserProfileSelectors';
import { getAgentProfile } from '../../profile/agentProfile/AgentProfileSelectors';
import { defineCurrBusiness, loadBusinesses } from "../AppClientActions";
import DuplicateTaxModal from "./ModalContentScreen";
import { GET_BUSINESS_INFO } from "../../../redux/actions/BusinessInfoAction";
import { getTaxFillingData } from '../selectors/TaxFilingPlanSelector';
import { dispatchSavedPlanData } from '../../../util/utilities';
import AddressSuggestionModal from "../../addressSuggestion/AddressSuggestionModal";
import { getBusinessAddressModalState } from "../selectors/BusinessInfoSelector";
import { setAppServiceInfo } from '../../../redux/actions/AppServiceInfoAction';
import { formatMobileNumberInput, formatNumberInput } from '../../../util/utilities';

const useStyles = makeStyles((theme) => ({
    fieldContainer: {
        width: '90vw',
        [theme.breakpoints.down("sm")]: {
            width: '45vw',
            left: '20%'
        },
        "& .MuiInputLabel-root": {
            // color: 'red',
            color: PRIMARY_COLOR,
            fontWeight: 'bold'
        },
    },
    paneRoot: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
    },
    modalContainer: {
        top: '100px',
    },
}));


export default function BusinessInformation({ nextButton, saveButton }) {
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
    const dispatch = useDispatch();
    const clientDetails = useSelector(getClientDetails);
    const directClientInfoId = useSelector(getDirectClientInfoId);
    const agentProfile = useSelector(getAgentProfile);
    const businessInfoData = useSelector(getBusinessInfo);
    const taxInfo = useSelector(getTaxInfo);
    const OWNERSHIP_TYPES = useSelector(getownershipTypes);
    const stateValue = useSelector(getBusinessStateValue);
    const cityValue = useSelector(getBusinessCityValue);
    const stateValues = useSelector(getStateValue);
    const cityValues = useSelector(getCityValue);
    const isLoadingStateValues = useSelector(getIsLoadingBusinessStateCityValues);
    const addressModalState = useSelector(getBusinessAddressModalState);

    const isClient = useSelector(getClientCheckDetails);
    const clientProfile = useSelector(getProfilePayload);
    const [userBusinesses, setUserBusiness] = useState([]);
    const businessesOfUser = useSelector(getBusinessOfUser);
    const [businessValue, setBusinessValue] = useState("");

    const classes = useStyles();
    const [businessInfo, setBusinessInfo] = useState({ ...businessInfoData });
    const taxFilingPlanData = useSelector(getTaxFillingData);


    useEffect(() => {
        if (taxFilingPlanData.isPlanSelected) {
            dispatchSavedPlanData(dispatch, 'BusinessInfo', taxFilingPlanData.taxFilingPlan);
        }
        dispatch(setAppServiceInfo(0, null, false, false));
    }, [dispatch]);


    const getStateId = (stateName) => {
        for (let value of stateValues) {
            if (value.codeValueKey === stateName) {
                return value.id
            }
        }
    }
    const getStateCodeValue = (stateId) => {
        if (stateValues) {
            for (let value of stateValues) {
                if (value.id === stateId) {
                    return value.codeValueKey
                }
            }
        }
    }

    const getCityId = (cityName) => {
        for (let value of cityValues) {
            if (value.codeValueKey === cityName) {
                return value.id
            }
        }
    }

    const getCityCodeValue = (cityId) => {
        if (cityValues) {
            for (let value of cityValues) {
                if (value.id === cityId) {
                    return value.codeValueKey
                }
            }
        }
    }


    const onSubmit = (data, lastVisitedPage) => {
        let mappedData = {
            ...data,
            id: businessInfo.id ? businessInfo.id : "",
            businessName: businessInfo.businessName ? businessInfo.businessName : taxInfo.businessName,
            state: Number.isInteger(businessInfo.state) ? businessInfo.state : getStateId(businessInfo.state),
            city: Number.isInteger(businessInfo.city) ? businessInfo.city : getCityId(businessInfo.city)
        }
       
        dispatch(postBusinessInformation({ ...mappedData, userType: clientDetails.userType, directClientInfoId, agenProfileId: agentProfile ? agentProfile.id : null, taxInfoId: taxInfo.id }, true,lastVisitedPage));

    }
    const [checked, setChecked] = useState(false);

    const handleCheckBox = () => {
        checked ? setChecked(false) : setChecked(true);
    }

    const zipCodeLookUpValues = (zipcode) => {
        if (zipcode.length === 5) {
            dispatch(verifyBusinessInfoZipCode(businessInfo, zipcode));
            setValue('city', '');
            setValue('state', '');
        } else {
            setValue('city', '');
            setValue('state', '');
        }
    }

    const handleBusinessChange = (e) => {
        let selectedBusiness = e.target.value;
        setBusinessValue(selectedBusiness);
        dispatch(defineCurrBusiness(selectedBusiness));
        dispatch(getSelectedBusinessInfo(isClient));
    }

    useEffect(() => {
        if (!businessInfo.error && businessInfo.isSubmitting === 'yes') {
            dispatch({ type: GET_BUSINESS_INFO, payload: { ...businessInfo, isSubmitting: 'no' } });
        }
    }, [businessInfo])

    useEffect(() => {
        dispatch(fetchOwnerShipTypes());
        dispatch(loadBusinesses(isClient, isClient ? clientProfile.id : agentProfile.id, taxInfo.taxYear))
    }, [dispatch])

    useEffect(() => {
        setValue('businessName', taxInfo.businessName);
        setValue('city', Number.isInteger(cityValue) ? getCityCodeValue(cityValue) : cityValue);
        setValue('state', Number.isInteger(stateValue) ? getStateCodeValue(stateValue) : stateValue);
    }, [cityValue, stateValue, taxInfo])

    useEffect(() => {
        setUserBusiness(businessesOfUser);
        reset();
        setBusinessInfo(businessInfoData);
    }, [businessesOfUser, businessInfoData]);


    return (
        <Box style={{ position: 'relative', left: '2%', bottom: '15%', textAlign: 'justify' }}>
            <form onSubmit={handleSubmit(onSubmit)} >
                <Box>
                    <Grid container>
                        <Modal
                            open={addressModalState}
                            hideBackdrop
                            disableEscapeKeyDown={true}
                            aria-labelledby="app-modal"
                            aria-describedby="app-modal-client"
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                            disableScrollLock={true}
                            className={classes.modalRoot}
                        >
                            <Fade in={addressModalState}>
                                <Box>
                                    <AddressSuggestionModal />
                                </Box>
                            </Fade>
                        </Modal>
                    </Grid>
                </Box>
                <Grid container direction="column" spacing={2} >
                    <Grid item xs={12} sx={{ m: 1 }}>
                        <h1 style={{ textAlign: 'center' }}>Business Information
                            <Typography className={classes.loginTitle} style={{ float: 'right' }}>
                                <span style={{ color: 'red' }}>*</span>  Required fields
                            </Typography><Divider />
                        </h1>
                        <h5> Text to be added </h5>
                    </Grid>
                    {userBusinesses.length !== 0 && <><Grid item>
                        <InputLabel id="business-label" required>Existing Businesses</InputLabel>
                        <Select labelId="business-label" id="businessOfUser" defaultValue=""
                            input={<OutlinedInput label="Existing Business" style={{ width: '100%' }} />}
                            {...register('businessOfUser',
                                {
                                    onChange: (e) => handleBusinessChange(e)
                                })}
                            value={businessValue ? businessValue : (taxFilingPlanData.taxFilingPlan ? taxFilingPlanData.taxFilingPlan.businessInfoId : "")}
                        >
                            {userBusinesses.map((eachBiz) => (
                                <MenuItem key={eachBiz.id} value={eachBiz.id}  >
                                    {`${eachBiz.name}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                        <Grid item style={{ marginTop: '5px' }}>
                            <Divider>
                                <Chip label='Select from the above dropdown if you want to work on existing businesses' />
                            </Divider>
                            <Chip label="Reset Form Fields" onClick={() => { setBusinessValue(''); dispatch(defineCurrBusiness("")); dispatch(clearBusinessInfo()) }} />
                        </Grid></>}
                    <Grid item xs={12}>
                        <TextField
                            id="businessName" label="Business Name" variant="outlined"
                            {...register('businessName',
                                {
                                    // required: 'Business name is required',
                                    maxLength: 130,
                                    onChange: (e) => { setBusinessInfo({ ...businessInfo, businessName: e.target.value }); }
                                })}
                            className={classes.fieldContainer}
                            InputLabelProps={{ shrink: true, required: true}}
                            helperText={errors.businessName ? errors.businessName.message : ""}
                            error={errors.businessName}
                            value={businessInfo.businessName === '' ? taxInfo.businessName : businessInfo.businessName?businessInfo.businessName:''}
                        />
                    </Grid>

                    <Grid item>
                        <TextField
                            id="corporationName" label="Corporation Name (If different from business name)" variant="outlined"
                            {...register('corporationName',
                                {
                                    maxLength: 30,
                                    onChange: (e) => { setBusinessInfo({ ...businessInfo, corporationName: e.target.value }); }

                                })}
                            className={classes.fieldContainer}
                            InputLabelProps={{ shrink: true }}
                            helperText={errors.corporationName ? 'Please enter the name within 30 characters' : ""}
                            error={errors.corporationName}
                            value={businessInfo.corporationName?businessInfo.corporationName:''}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="ownerName" label="Owner Name"
                            variant="outlined" {...register('ownerName',
                                {
                                    required: 'Owner name is required',
                                    maxLength: 30,
                                    onChange: (e) => { setBusinessInfo({ ...businessInfo, ownerName: e.target.value }); }

                                })}
                            className={classes.fieldContainer}
                            InputLabelProps={{ shrink: true, required: true }}
                            helperText={errors.ownerName ? errors.ownerName.message : ""}
                            error={errors.ownerName}
                            value={businessInfo.ownerName ? businessInfo.ownerName : ''}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="mailingAddressLine1" label="Company Mailing Address"
                            variant="outlined"
                            {...register('mailingAddressLine1',
                                {
                                    required: 'Address is required', maxLength: 30,
                                    onChange: (e) => { setBusinessInfo({ ...businessInfo, mailingAddressLine1: e.target.value }); }
                                })}
                            className={classes.fieldContainer}
                            InputLabelProps={{ shrink: true, required: true  }}
                            helperText={errors.mailingAddressLine1 ? errors.mailingAddressLine1.message : ""}
                            error={errors.mailingAddressLine1}
                            value={businessInfo.mailingAddressLine1?businessInfo.mailingAddressLine1:''}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="mailingAddressLine2" label="Address Line 2"
                            variant="outlined"
                            {...register('mailingAddressLine2',
                                {
                                    maxLength: 30,
                                    onChange: (e) => { setBusinessInfo({ ...businessInfo, mailingAddressLine2: e.target.value }); }
                                })}
                            className={classes.fieldContainer}
                            InputLabelProps={{ shrink: true }}
                            value={businessInfo.mailingAddressLine2?businessInfo.mailingAddressLine2:''}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="zip" label="Zip" type="number"
                            variant="outlined"
                            {...register('zip',
                                {
                                    required: 'Zipcode is required',
                                    maxLength: { value: 5, message: 'Only 5 characters are allowed' },
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: 'Only numericals are allowed'
                                    },
                                    onChange: (e) => { setBusinessInfo({ ...businessInfo, zip: e.target.value }); zipCodeLookUpValues(e.target.value); }
                                })
                            }
                            onKeyDown={formatNumberInput}
                            className={classes.fieldContainer}
                            InputLabelProps={{ shrink: true, required: true  }}
                            helperText={errors.zip ? errors.zip.message : ""}
                            error={errors.zip}
                            value={businessInfo.zip?businessInfo.zip:''}
                        />
                    </Grid>
                    {isLoadingStateValues && <Grid item>
                        <LinearProgress />
                    </Grid>}
                    <Grid item>
                        <TextField
                            id="city" label="City"
                            variant="outlined" {...register('city', { minLength: 1 })}
                            className={classes.fieldContainer}
                            InputLabelProps={{ shrink: true }}
                            disabled
                            value={Number.isInteger(cityValue) ? getCityCodeValue(cityValue) : cityValue?cityValue:''}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="state" label="State"
                            variant="outlined" {...register('state', { minLength: 1 })}
                            className={classes.fieldContainer}
                            InputLabelProps={{ shrink: true }}
                            disabled
                            value={Number.isInteger(stateValue) ? getStateCodeValue(stateValue) : stateValue?stateValue:''}
                        />
                    </Grid>
                    <Grid item>
                        <InputLabel id="ownership-Code-label" required>OwnerShip Type</InputLabel>
                        <Select labelId="ownership-Code-label" id="ownershipType" defaultValue=""
                            input={<OutlinedInput label="OwnerShip Type" style={{ width: '100%' }} />}
                            {...register('ownershipType',
                                {
                                    required: 'Ownership Type is required',
                                    onChange: (e) => { setBusinessInfo({ ...businessInfo, ownershipType: e.target.value }); }
                                })}
                            error={errors.ownershipType}
                            value={businessInfo.ownershipType?businessInfo.ownershipType:''}
                        >
                            {OWNERSHIP_TYPES.map((eachBiz) => (
                                <MenuItem key={eachBiz.codeValueKey} value={eachBiz.id}  >
                                    {`${eachBiz.codeValueKey}`}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error>{errors.ownershipType && errors.ownershipType.message}</FormHelperText>
                    </Grid>
                    <Grid item>
                        <TextField
                            id="propertyTaxContactPerson" label="Property Tax Contact Person"
                            variant="outlined"
                            {...register('propertyTaxContactPerson',
                                {
                                    required: 'Property Tax contact person is required', maxLength: 30,
                                    onChange: (e) => { setBusinessInfo({ ...businessInfo, propertyTaxContactPerson: e.target.value }); }
                                })}
                            className={classes.fieldContainer}
                            InputLabelProps={{ shrink: true, required: true  }}
                            helperText={errors.propertyTaxContactPerson ? errors.propertyTaxContactPerson.message : ""}
                            error={errors.propertyTaxContactPerson}
                            value={businessInfo.propertyTaxContactPerson ? businessInfo.propertyTaxContactPerson : ''}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="jobTitle" label="Job Title"
                            variant="outlined"
                            {...register('jobTitle',
                                {
                                    required: 'Job Title is required', maxLength: 30,
                                    onChange: (e) => { setBusinessInfo({ ...businessInfo, jobTitle: e.target.value }); }
                                })}
                            className={classes.fieldContainer}
                            InputLabelProps={{ shrink: true, required: true  }}
                            helperText={errors.jobTitle ? errors.jobTitle.message : ""}
                            error={errors.jobTitle}
                            value={businessInfo.jobTitle ? businessInfo.jobTitle : ''}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="mobilePhoneNumber" label="Mobile Phone Number" type="number"
                            variant="outlined"
                            {...register('mobilePhoneNumber',
                                {
                                    required: 'Phone number is required',
                                    maxLength: { value: 10, message: 'Only 10 characters are allowed' },
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: 'Only numericals are allowed'
                                    },
                                    onChange: (e) => { setBusinessInfo({ ...businessInfo, mobilePhoneNumber: e.target.value }); }
                                })}
                            onKeyDown={formatMobileNumberInput}
                            className={classes.fieldContainer}
                            InputLabelProps={{ shrink: true, required: true  }}
                            helperText={errors.mobilePhoneNumber ? errors.mobilePhoneNumber.message : ""}
                            error={errors.mobilePhoneNumber}
                            value={businessInfo.mobilePhoneNumber ? businessInfo.mobilePhoneNumber : ''}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="officePhoneNumber" label="Office Phone Number" type="number"
                            variant="outlined"
                            {...register('officePhoneNumber',
                                {
                                    required: 'Office Phone number is required',
                                    maxLength: { value: 10, message: "Only 10 characters are allowed" },
                                    minLength: { value: 10, message: "Please enter 10 digits" },
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: 'Only numericals are allowed'
                                    },
                                    onChange: (e) => { setBusinessInfo({ ...businessInfo, officePhoneNumber: e.target.value }); }
                                })}
                            onKeyDown={formatMobileNumberInput}
                            className={classes.fieldContainer}
                            InputLabelProps={{ shrink: true, required: true  }}
                            helperText={errors.officePhoneNumber ? errors.officePhoneNumber.message : ""}
                            error={errors.officePhoneNumber}
                            value={businessInfo.officePhoneNumber ? businessInfo.officePhoneNumber : ''}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="emailId" label="Email"
                            variant="outlined"
                            {...register('emailId',
                                {
                                    required: 'Email is required', maxLength: 30,
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: 'Please enter a valid email',
                                    },
                                    onChange: (e) => { setBusinessInfo({ ...businessInfo, emailId: e.target.value }); }
                                })}
                            className={classes.fieldContainer}
                            InputLabelProps={{ shrink: true , required: true }}
                            helperText={errors.emailId ? errors.emailId.message : ''}
                            error={errors.emailId}
                            value={businessInfo.emailId ? businessInfo.emailId : ''}
                        />
                    </Grid>
                    <FormControlLabel control={<Checkbox onChange={handleCheckBox} />} label="Business has multiple locations" />
                    <Grid item>
                        {checked &&
                            <TextField
                                id="noOfLocations" label="Number of Locations"
                                variant="outlined"
                                type='number'
                                className={classes.fieldContainer}
                                {...register('noOfLocations',
                                    {
                                        required: 'Number of locations is required', maxLength: 30,
                                        onChange: (e) => { setBusinessInfo({ ...businessInfo, noOfLocations: e.target.value }); }
                                    })}
                                onKeyDown={formatNumberInput}
                                helperText={errors.noOfLocations ? errors.noOfLocations.message : ""}
                                InputLabelProps={{ shrink: true, required: true  }}
                                error={errors.noOfLocations}
                                value={businessInfo.noOfLocations?businessInfo.noOfLocations:''}
                            />
                        }
                    </Grid>
                    <input type="submit" ref={nextButton} style={{ display: 'none' }} onClick={handleSubmit((data) => { onSubmit(data, 'LocationInfo') })} />
                    <input type="submit" ref={saveButton} style={{ display: 'none' }} onClick={handleSubmit((data) => { onSubmit(data, 'BusinessInfo') })} />
                </Grid>
                <DuplicateTaxModal />
            </form>
        </Box>
    );
}
