import { 
    SET_ASSET_INFO,
    FETCH_ASSET_TYPES, FETCH_ASSET_TYPES_SUCCESS, FETCH_ASSET_TYPES_FAILURE, SET_CURRENT_ASSET_INFO_SUCCESS,
    GET_UPDATE_ASSETS_INFO, GET_UPDATE_ASSETS_INFO_SUCCESS, GET_UPDATE_ASSETS_INFO_FAILURE, RESET,GET_BULKASSET_INFO_SUCCESS,
    GET_BULKASSET_INFO_FAILURE
} from '../actions/AssetsInfoActions';

const defaultState = {
    assetInfo: {
        assetDesc:'',
        assetNum: '',
        assertType: '',
        dateAcquired:'',
        orgPurchasePrice:'',
        quantity:'',
        costCenter: ''
    },
    assetTypes: [],
    bulkAssets: {}
}

const AssetInfoReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_ASSET_INFO:
            return { ...state, assetInfo: action.payload }
        case SET_CURRENT_ASSET_INFO_SUCCESS:
                return { ...state, currentAsset: action.payload }
        case FETCH_ASSET_TYPES:
            return { ...state }
        case FETCH_ASSET_TYPES_SUCCESS:
            return { ...state, assetTypes: action.payload }
        case FETCH_ASSET_TYPES_FAILURE:
            return { ...state, assetTypes: [] }
        case GET_UPDATE_ASSETS_INFO:
            return { ...state, isUpdating: true}
        case GET_UPDATE_ASSETS_INFO_SUCCESS: 
            return { ...state, currentAsset: action.payload }
        case GET_UPDATE_ASSETS_INFO_FAILURE: 
            return { ...state }
        case GET_BULKASSET_INFO_SUCCESS: 
            return { ...state, bulkAssets: action.payload }
        case GET_BULKASSET_INFO_FAILURE: 
            return { ...state,bulkAssets:[],error: action.payload }
        case RESET: 
            return { ...state, currentAsset: [], isUpdating: false}
        default:
            return state;
    }
}

export default AssetInfoReducer;