import React, { useEffect} from 'react';
import { makeStyles } from '@mui/styles';
import {  Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { getModalState, getClientDetails } from './AppClientSelectors';
import { getUserInfo } from '../profile/userProfile/UserProfileActions';
import { getAgentInfo } from '../profile/agentProfile/AgentProfileActions';
import { getIsClientAuthenticated } from '../home/login/LoginSelectors';
import {
  getIsProfileComplete
} from './AppClientSelectors';
import { PRIMARY_COLOR } from '../../constants/colorScheme';
import MainScreen from './MainScreen';
import { fetchCityValues, fetchStateValues, fetchCountyValues } from './AppClientActions';
import { fetchAssetTypes } from '../../redux/actions/AssetsInfoActions';
import UserProfileView from '../profile/userProfile/UserProfileView';
import AgentProfileView from '../profile/agentProfile/AgentProfileView';
import { getTaxFillingData } from './selectors/TaxFilingPlanSelector';
import { getDirectClientInfoId } from '../profile/userProfile/UserProfileSelectors';
import { getAgentProfile } from '../profile/agentProfile/AgentProfileSelectors';
import { getTaxFilingPlans } from '../../redux/actions/taxFilingPlanAction';
import HistoryScreen from './HistoryScreen';


const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  homeContainer: {
    color: '#2e415c',
    backgroundColor: 'white',
    padding: '30px',
  },
  homeContainerBlur: {
    color: '#2e415c',
    backgroundColor: 'transparent',
    filter: 'blur(12px)',
    padding: '30px'
  },
  paneRoot: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
  },
  modalContainer: {
    top: '100px',
  },
  stepperIconActive: {
    color: '#12395b !important',
  },
  icon: {
      fill:"yellowgreen",
  },
  check: {
    marginBottom: '6px',
    color: PRIMARY_COLOR
  },
  arrowStyle: {
    top: '-5px;',
    left: '175px',
    position: 'relative',
  }, 
  chipStyle: {
    border: '1px solid'+PRIMARY_COLOR
  },
  chipText: {
      color: PRIMARY_COLOR 
  }
  
  }));

const AppClientHome = () => {
    const classes = useStyles();
    const modalState = useSelector(getModalState);
    const clientDetails = useSelector(getClientDetails);
    const isClient = useSelector(getIsClientAuthenticated);
    const isProfileComplete = useSelector(getIsProfileComplete);
    const directClientInfoId = useSelector(getDirectClientInfoId);
    const agentProfile = useSelector(getAgentProfile);
    const taxFilingPlanData = useSelector(getTaxFillingData);
  
    const dispatch = useDispatch();

    useEffect(() => {
      if (clientDetails && clientDetails.userType === 'AGENT') {
        if(clientDetails.userId) {
          dispatch(getAgentInfo(clientDetails.userId));
        }
      } else if(clientDetails && (clientDetails.userType === 'CLIENT' || clientDetails.userType === 'BUSINESS-OWNER')){
        if(clientDetails.userId) {
          dispatch(getUserInfo(clientDetails.userId));
        }
      }
    },[dispatch, isClient, clientDetails])

    useEffect(() => {
      dispatch(fetchStateValues());
      dispatch(fetchCountyValues());
      dispatch(fetchCityValues());
      dispatch(fetchAssetTypes());
    },[dispatch]);

    useEffect(() => {
      if(directClientInfoId) {
        dispatch(getTaxFilingPlans(directClientInfoId, null,'history'));
      } else if(agentProfile && agentProfile.id){
        dispatch(getTaxFilingPlans(null,agentProfile.id,'history'));
      }
    },[isProfileComplete,agentProfile,directClientInfoId,dispatch]);
   
    return (
        <Grid container  className={(modalState === false) ? classes.homeContainer : classes.homeContainerBlur}>
          <Grid item xs={12}>
            { isProfileComplete ? 
                  ((taxFilingPlanData && taxFilingPlanData.taxFilingPlans && taxFilingPlanData.taxFilingPlans.taxfilingPlans && taxFilingPlanData.taxFilingPlans.taxfilingPlans.length > 0 &&
                    !taxFilingPlanData.isPlanSelected && !taxFilingPlanData.isNewPlan) 
                      ? <HistoryScreen firstName={clientDetails.firstName} /> : <MainScreen />)
              : 
            ((clientDetails && (clientDetails.userType === 'CLIENT' || clientDetails.userType === 'BUSINESS-OWNER')) ? 
              <UserProfileView/> : <AgentProfileView />)}
          </Grid>
          
        </Grid>
    );
}
export default AppClientHome;