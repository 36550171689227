import * as React from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { standardButtons } from '../../styles/mainPagesCSS';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Tax from './pages/TaxInfo';
import BusinessInformation from './pages/BusinessInformation';
import AdditionalBusinessInformation from './pages/AdditionalBusinessInformation';
import LocationInformation from './pages/LocationInformation';
import Representation from './pages/Representation';
import { getClientAuthentication } from './selectors/AppSelector';
import useMediaQuery from '@mui/material/useMediaQuery';
import AssetsView from './pages/AssetsView';
import Supplies from './pages/Supplies';
import LeasedAssetsView from './pages/LeasedAssetsView';
import { getTaxInfo } from './selectors/TaxInfoSelector';
import { getBusinessInfo } from './selectors/BusinessInfoSelector';
import { getLocationInfo } from './selectors/LocationInfoSelector';
import InventoryInformation from './pages/InventoryInformation';
import { getDirectClientInfoId } from '../profile/userProfile/UserProfileSelectors';
import { getAgentProfile } from '../profile/agentProfile/AgentProfileSelectors';
import Rendition from './pages/Rendition';
import { getTaxFillingData } from './selectors/TaxFilingPlanSelector';
import { useEffect, useState } from 'react';
import { putTaxInfo } from '../../redux/actions/taxInfoAction';
import { getLocationInfo as retrieveLocationInfo } from '../../redux/actions/LocationInfoAction';
import { getCityValue } from "./AppClientSelectors";
import { appServiceData as getAppServiceData } from './selectors/AppServiceInfoSelector';
import { getTaxFilingPlans, resetTaxInfo } from '../../redux/actions/taxFilingPlanAction';
import { useHistory } from 'react-router';
import { getBulkAssets } from './selectors/AssetsInfoSelector';
import { getSecondFormSectionStatus, getThirdFormSectionStatus, getInventoryResaleFlagStatus } from './selectors/InventoryInfoSelectors';

const userSteps = ['Tax Year', 'Business', 'Location', 'Additional Business Info', 'Assets', 'Leased Assets', 'Supplies', 'Inventory', 'Rendition'];
const agentSteps = ['Tax Year', 'Respresentation', 'Business', 'Location', 'Additional Business Info', 'Assets', 'Leased Assets', 'Supplies', 'Inventory', 'Rendition'];


const useStyles = makeStyles((theme) => ({
  errorStyle: {
    width: '100%'
  },

  paneContainer: {
    [theme.breakpoints.down("md")]: {
      left: '30%',
      position: 'relative',
      bottom: '10%'
    },
  },
  buttonStyle: {
    [theme.breakpoints.down("md")]: {
      position: 'relative',
      left: '50%',
    }
  },

}));

export default function MainScreen() {

  const taxFilingPlanData = useSelector(getTaxFillingData);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const buttonStackRef = useRef(null);
  const stepperRef = useRef(null);

  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const orientation = matches ? "vertical" : "horizontal";
  const buttonDirection = matches ? "column" : "row";
  const isClientAuthenticated = useSelector(getClientAuthentication);
  const taxInfo = useSelector(getTaxInfo);
  const businessInfo = useSelector(getBusinessInfo);
  const locationInfo = useSelector(getLocationInfo);
  const dispatch = useDispatch();
  const directClientInfoId = useSelector(getDirectClientInfoId);
  const agentProfile = useSelector(getAgentProfile);
  const cityValues = useSelector(getCityValue);
  const appServiceData = useSelector(getAppServiceData);
  const [buttonAction, setButtonAction] = useState(null);
  const history = useHistory();
  const [generationFlag, setGenerationFlag] = useState(false);
  const bulkAssets = useSelector(getBulkAssets);
  const [isDisabled, setDisabled] = useState(false);
  const secondFormSectionStatus = useSelector(getSecondFormSectionStatus);
  const thirdFormSectionStatus = useSelector(getThirdFormSectionStatus);
  const inventoryResaleFlagStatus = useSelector(getInventoryResaleFlagStatus);
  const [open, setOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [alert1, setAlert1] = useState(false);
  const [alert2, setAlert2] = useState(false);

  const handleAgreeClick = () => {
    if ( alert1 && secondFormSectionStatus === true &&  thirdFormSectionStatus === true) {
      setOpen(false);
      setButtonAction("next");
      ref1.current.click();
      handleNavigationLogic("next");
    } else {
      setOpen(false)
    }
    if (alert2) {
      setOpen(false);
      setButtonAction("next");
      ref1.current.click();
      handleNavigationLogic("next");
    }
  };

  const handleDisAgreeClick = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (activeStep === steps.length) {
      setGenerationFlag(true);
    }
  })


  useEffect(() => {
    if (!locationInfo || locationInfo.id === null || locationInfo.id === undefined) {
      dispatch(retrieveLocationInfo(taxInfo.locationInfoId));
    }
  }, [taxInfo])

  let taxBusinessLocationLabel = `${taxInfo.taxYear}? Tax Year -${taxInfo}.taxYear: `
  if (taxInfo && taxInfo.taxYear) {
    taxBusinessLocationLabel = `Tax Year - ${taxInfo.taxYear} `;
  }
  if (taxInfo && taxInfo.businessName) {
    taxBusinessLocationLabel = taxBusinessLocationLabel + `| Business Name - ${businessInfo.businessName ? businessInfo.businessName : taxInfo.businessName} `;
  }
  if (locationInfo && locationInfo.city) {
    let cityName = '';
    if (cityValues) {
      cityName = cityValues.filter((item) => (item.id === locationInfo.city)).map((item) => item.codeValueKey);
      taxBusinessLocationLabel = taxBusinessLocationLabel + `| Location Name - ${cityName}`;
    }
  }
  let steps = isClientAuthenticated ? userSteps : agentSteps;


  const identifyPage = () => {

    if (isClientAuthenticated) {
      switch (taxFilingPlanData.taxFilingPlan.lastVisitedPage) {
        case 'BasicInfo':
          return 0;
        case 'BusinessInfo':
          return 1;
        case 'LocationInfo':
          return 2;
        case 'AdditionalBusinessInfo':
          return 3;
        case 'AssetsInfo':
          return 4;
        case 'LeasedAssetsInfo':
          return 5;
        case 'SuppliesInfo':
          return 6;
        case 'InventoryInfo':
          return 7;
        case 'Rendition':
          return 8;
        case 'Draft Rendition':
          return 8;
        default:
          return 0;

      }
    } else {
      switch (taxFilingPlanData.taxFilingPlan.lastVisitedPage) {
        case 'BasicInfo':
          return 0;
        case 'RepresentationInfo':
          return 1;
        case 'BusinessInfo':
          return 2;
        case 'LocationInfo':
          return 3;
        case 'AdditionalBusinessInfo':
          return 4;
        case 'AssetsInfo':
          return 5;
        case 'LeasedAssetsInfo':
          return 6;
        case 'SuppliesInfo':
          return 7;
        case 'InventoryInfo':
          return 8;
        case 'Rendition':
          return 9;
        case 'Draft Rendition':
          return 9;
        default:
          return 0;

      }
    }
  }
  useEffect(() => {
    if (taxFilingPlanData.isPlanSelected) {
      setActiveStep(identifyPage());
      // dispatch(setSelectedPlanFalse());
    }
  }, [])


  const isStepOptional = (step) => {
    return true;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNavigationLogic = (action) => {

    let taxInfoId = taxInfo.id;
    let businessInfoId = businessInfo.id;
    let locationInfoId = locationInfo.id;
    if (taxInfoId === undefined || taxInfoId === 0) {
      if (taxFilingPlanData && taxFilingPlanData.taxFilingPlan) {
        taxInfoId = taxFilingPlanData.taxFilingPlan.id;
      }
    }
    if (businessInfoId === undefined || businessInfoId === 0) {
      if (taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
        if (taxFilingPlanData.taxFilingPlan) {
          businessInfoId = taxFilingPlanData.taxFilingPlan.businessInfoId;
        }
      }
    }
    if (locationInfoId === undefined || locationInfoId === 0) {
      if (taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
        if (taxFilingPlanData.taxFilingPlan) {
          locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
        }
      }
    }

    if (isClientAuthenticated && (activeStep === 4 || activeStep === 5)) {
      let lastVisitedPage = null;
      if (activeStep === 4) {
        if (action === "next") {
          lastVisitedPage = "LeasedAssetsInfo";
        } else if (action === "save") {
          lastVisitedPage = "AssetsInfo";
        }
        dispatch(putTaxInfo(taxInfoId, businessInfoId, locationInfoId, lastVisitedPage, true));
      } else if (activeStep === 5) {
        if (action === "next") {
          lastVisitedPage = "SuppliesInfo";
        } else if (action === "save") {
          lastVisitedPage = "LeasedAssetsInfo";
        }
        dispatch(putTaxInfo(taxInfoId, businessInfoId, locationInfoId, lastVisitedPage, true));
      }
    } else if (!isClientAuthenticated && (activeStep === 5 || activeStep === 6)) {
      let lastVisitedPage = null;
      if (activeStep === 5) {
        if (action === "next") {
          lastVisitedPage = "LeasedAssetsInfo";
        } else if (action === "save") {
          lastVisitedPage = "AssetsInfo";
        }
      } else if (activeStep === 6) {
        if (action === "next") {
          lastVisitedPage = "SuppliesInfo";
        } else if (action === "save") {
          lastVisitedPage = "LeasedAssetsInfo";
        }
      }
      dispatch(putTaxInfo(taxInfoId, businessInfoId, locationInfoId, lastVisitedPage, true));
    }
  }

  useState(()=> {
    if (inventoryResaleFlagStatus) {
      setAlert1(true);
      setAlert2(false);
    }else {
      setAlert2(true);
      setAlert1(false);
    }
  },[getInventoryResaleFlagStatus])

  const handleNext = () => {
    // setButtonAction("next");
    // ref1.current.click();
    // handleNavigationLogic("next");
    if ((isClientAuthenticated && activeStep === 7)||(!isClientAuthenticated && activeStep === 8)) {
      if (inventoryResaleFlagStatus) {
         
        if (!secondFormSectionStatus || !thirdFormSectionStatus) {
          setOpen(true)
          setDialogMessage('Please review all sections of the form by expanding the section using the icon below. Move forward with the process by doing so!!!');
        }
        if (secondFormSectionStatus && thirdFormSectionStatus) {
          handleAgreeClick()
        }
      }
      if (!inventoryResaleFlagStatus) {
        setOpen(true);
        setDialogMessage("You are about to skip this");
      
      }
    } else {
      setButtonAction("next");
      ref1.current.click();
      handleNavigationLogic("next");
    }
  }

  useEffect(() => {
    if (buttonAction === 'next') {
      if (!appServiceData.isError && appServiceData.isPageSubmitted) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        stepperRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
    if (appServiceData.isError && appServiceData.isPageSubmitted) {
      stepperRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (buttonAction === 'save') {
      if (!appServiceData.isError && appServiceData.isPageSubmitted) {
        stepperRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }


  }, [appServiceData]);

  useEffect(()=> {
    if(bulkAssets.data && bulkAssets.data.status === 'INPROGRESS'){
      if ((isClientAuthenticated && (activeStep === 4 || activeStep === 5)) ||
      (!isClientAuthenticated && (activeStep === 5 || activeStep === 6))) {
      //  ref1.current.disabled()
      console.log('hey am selected');
      setDisabled(true);
      }
    }
  },[bulkAssets])
  const renderPanes = (currPaneStep) => {

    if (isClientAuthenticated) {
      switch (currPaneStep) {
        case 0:
          return <Tax nextButton={ref1} saveButton={ref2} />;
        case 1:
          return <BusinessInformation nextButton={ref1} saveButton={ref2} />;
        case 2:
          return <LocationInformation nextButton={ref1} saveButton={ref2} />;
        case 3:
          return <AdditionalBusinessInformation nextButton={ref1} saveButton={ref2} />;
        case 4:
          return <AssetsView nextButton={ref1} buttonStackRef={buttonStackRef} saveButton={ref2}/>
        case 5:
          return <LeasedAssetsView nextButton={ref1} buttonStackRef={buttonStackRef} saveButton={ref2}/>
        case 6:
          return <Supplies nextButton={ref1} saveButton={ref2} />
        case 7:
          return <InventoryInformation nextButton={ref1} saveButton={ref2} />
        case 8:
          return <Rendition nextButton={ref1} saveButton={ref2} />
        default:
          return <React.Fragment />
      }
    }
    else {
      switch (currPaneStep) {
        case 0:
          return <Tax nextButton={ref1} saveButton={ref2} />;
        case 1:
          return <Representation nextButton={ref1} saveButton={ref2} />;
        case 2:
          return <BusinessInformation nextButton={ref1} saveButton={ref2} />;
        case 3:
          return <LocationInformation nextButton={ref1} saveButton={ref2} />;
        case 4:
          return <AdditionalBusinessInformation nextButton={ref1} saveButton={ref2} />;
        case 5:
          return <AssetsView nextButton={ref1} buttonStackRef={buttonStackRef} saveButton={ref2} />
        case 7:
          return <Supplies nextButton={ref1} saveButton={ref2} />
        case 6:
          return <LeasedAssetsView nextButton={ref1} buttonStackRef={buttonStackRef} saveButton={ref2}/>
        case 8:
          return <InventoryInformation nextButton={ref1} saveButton={ref2} />
        case 9:
          return <Rendition nextButton={ref1} saveButton={ref2} />
        default:
          return <React.Fragment />
      }
    }

  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSave = () => {
    setButtonAction("save");
    ref2.current.click();
    handleNavigationLogic("save");
  };

  return (

    <Box sx={{ width: '100%', height: '100%', overflowX: 'hidden' }}>
      <Grid container>
        <Stepper activeStep={activeStep} sx={{ width: '100%', height: '100px' }} orientation={orientation} ref={stepperRef}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div className={classes.errorStyle}>
          {appServiceData.isError && appServiceData.isPageSubmitted && <Alert severity="error" >System Error Occurred</Alert>}
          {!appServiceData.isError && appServiceData.isPageSubmitted && <Alert severity="success" >Successfully saved details!</Alert>}
          {bulkAssets.data && bulkAssets.data.status === 'INPROGRESS' && <Alert severity="success" >Your last bulk assets upload is in progress, please come back after 4-6 hours</Alert>}
        </div>
        <Grid item>
        <Dialog
        open={open}
        onClose={handleDisAgreeClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Attention: Please take note of this important message."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
              <Button onClick={handleDisAgreeClick}>Cancel</Button>
              <Button onClick={handleAgreeClick}>Proceed</Button>
        </DialogActions>
      </Dialog>
        </Grid>
        <Grid item xs={12}>
          <React.Fragment>
            {(activeStep !== 0) ? <div style={{ fontWeight: 'bolder', position: 'relative', marginBottom: '1%', textAlign: 'right', right: '5%' }}>{taxBusinessLocationLabel}</div> : <React.Fragment />}
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }} className={classes.paneContainer}>

              {renderPanes(activeStep, dispatch, directClientInfoId, agentProfile)}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, width: '100%' }}>

              {generationFlag === false && activeStep !== steps.length && <ThemeProvider theme={standardButtons}>
                <Stack spacing={2} style={{ width: '100%' }} direction={buttonDirection} justifyContent="center" className={classes.buttonStyle}
                  ref={buttonStackRef}>
                  {(activeStep !== 0 ? <Button
                    variant="contained" size="medium"

                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1, width: '200px', height: '50px' }}
                  >
                    Previous
                  </Button> : <React.Fragment />)}
                  <Box sx={{ flex: '1 1 auto' }} />
                  {activeStep !== steps.length - 1 && (
                    <Button variant="contained" size="medium" onClick={handleSave} sx={{ mr: 1, width: '200px', height: '50px' }}>
                      Save
                    </Button>
                  )}

                  <Button variant="contained" size="medium" onClick={handleNext} sx={{ mr: 1, width: '200px', height: '50px' }} disabled={isDisabled}>
                    {activeStep === steps.length - 1 ? 'Generate Rendition' : 'Next'}
                  </Button>
                </Stack>
              </ThemeProvider>}
              {
                generationFlag &&
                <ThemeProvider theme={standardButtons}>
                  <Stack spacing={2} style={{ width: '100%' }} direction={buttonDirection} justifyContent="center" className={classes.buttonStyle}>
                    <Button variant="contained" size="medium" sx={{ mr: 1, width: '200px', height: '50px' }}
                      onClick={e => {
                        dispatch(resetTaxInfo());
                        history.push('/historyScreen')
                        dispatch(getTaxFilingPlans(directClientInfoId, agentProfile ? agentProfile.id : null, 'history'));
                        setActiveStep(0);
                      }}
                    >
                      Home
                    </Button>
                  </Stack>
                </ThemeProvider>
              }
            </Box>
          </React.Fragment>
          {/* )} */}
        </Grid>
      </Grid>
    </Box>
  );
}
