import React, { Fragment, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Fade, TextField, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio,
   FormHelperText, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { registerAction } from './RegisterActions';
import { content } from './RegisterContent';
import { validateMailFormat } from '../../../util/utilities';
import { getRegisterPayload } from './RegisterSelectors';
import { useHistory } from 'react-router';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../../constants/colorScheme';


const useStyles = makeStyles(() => ({
    box: {
        backgroundColor: 'white',
    },
    formContainer: {
        color: '#2e415c',
        backgroundColor: 'white',
        padding: '30px',
    },
    title: {
        fontWeight: 'bold'
    },
    container: {
        boxShadow: '2px 2px 11px 1px rgb(90 78 80 / 55%)',
        border: '1px solid PRIMARY_COLOR',
        padding: '10px',
        margin: '20px',
    },
    desc: {
        textAlign: 'left',
        fontWeight: '800'
    },
    formInputs:{
        padding: '15px 0',
    },
    fields: {
        minWidth: 'calc(100% - 40px)'
    },
    sendBtn: {
        width: 'calc(100% - 100px)',
        fontWeight: 'bold',
        margin: '10px 0',
        '&:hover': {
            backgroundColor: PRIMARY_COLOR,
            color: SECONDARY_COLOR,
            animation: 0
        }
    },
    actionBtn: {
        width: 'calc(100% - 100px)',
        fontWeight: 'bold',
        margin: '10px 0',
        '&:hover': {
          backgroundColor: PRIMARY_COLOR,
          color: SECONDARY_COLOR,
          animation: 0
      }
    }
  }));

const RegisterView = () => {
    const errObjInitialState = {
        nameErr: false, mailErr: false, validationErr: false, matchErr: false,
        pwdErr: false, cnfPwdErr: false, agentCheckErr: false
    }

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const formRef = useRef(null);
    let mailInput = useRef(null);
    let cnfPwdInput = useRef(null);
    const [errs, setErrs ] = useState(errObjInitialState);
    const registerInitPayload = useSelector(getRegisterPayload);
    const [registerPayload, setRegisterPayload] = useState(registerInitPayload);

    const clearValues = () => {
        setRegisterPayload({
            name: '', email: '', pwd: '', cnfPwd: '', agentCheck: ''
        });
        setErrs({
            nameErr: null, mailErr: null, validationErr: null, matchErr: null,
            pwdErr: null, cnfPwdErr: null, agentCheckErr: null
        });
        mailInput.current.value = "";
        cnfPwdInput.current.value = "";
    }

    const handleTxt = (evt, txtType) => {
        switch(txtType){
            case 'name':
                setRegisterPayload({...registerPayload, name: evt.target.value});
                setErrs((prevState) => ({...prevState, nameErr: false}));
                break;
            case 'pwd':
                setRegisterPayload({...registerPayload, pwd: evt.target.value});
                setErrs((prevState) => ({...prevState, pwdErr: false}));
                break;
            case 'cnfPwd':
                setRegisterPayload({...registerPayload, cnfPwd: evt.target.value});
                setErrs((prevState) => ({...prevState, cnfPwdErr: false}));
                break;
            case 'email':
                if(validateMailFormat(evt.target.value)){
                    setRegisterPayload({...registerPayload, email: evt.target.value});
                    setErrs((prevState) => ({...prevState, mailErr: false, validationErr: false}));
                }
                else{
                    setErrs((prevState) => ({...prevState, mailErr: true, validationErr: true}));
                }
                break;  
            case 'agentCheck':
                setRegisterPayload({...registerPayload, agentCheck: evt.target.value});
                setErrs((prevState) => ({...prevState, agentCheckErr: false}));
                break;
            
            default: 
        }
    }

    const execScroll = () => formRef.current.scrollIntoView({ behavior: 'smooth' });

    const handleRedirect = (route) => {
        history.push(route);
    }

    const handleSend = () => {
        !registerPayload.name ? setErrs((prevState) => ({...prevState, nameErr: true})) 
        : setErrs((prevState) => ({...prevState, nameErr: false}));
        !registerPayload.pwd ? setErrs((prevState) => ({...prevState, pwdErr: true})) 
        : setErrs((prevState) => ({...prevState, pwdErr: false}));
        !registerPayload.cnfPwd ? setErrs((prevState) => ({...prevState, cnfPwdErr: true})) 
        : setErrs((prevState) => ({...prevState, cnfPwdErr: false}));
        !(registerPayload.cnfPwd === registerPayload.pwd) ? setErrs((prevState) => ({...prevState, matchErr: true})) 
        : setErrs((prevState) => ({...prevState, matchErr: false}));
        !registerPayload.email ? setErrs((prevState) => ({...prevState, mailErr: true})) 
        : setErrs((prevState) => ({...prevState, mailErr: false}));
        registerPayload.agentCheck === '' ? setErrs((prevState) => ({...prevState, agentCheckErr: true})) 
        : setErrs((prevState) => ({...prevState, agentCheckErr: false}));

        if(Object.values(errs).every(item => item === false)){
            dispatch(registerAction(registerPayload));
            clearValues();
        }else{
            execScroll();
        }
    }

    return (
        <Box className={classes.box}>
            <Grid container className={classes.formContainer}  ref={formRef}>
                <Grid item xs={12}>
                <Typography variant="h4"  className={classes.title} >{content.title.value} </Typography>
                </Grid>
                <Grid container justifyContent='center'>
                    <Grid item xs={12} md={6}>
                        <Fade in={true} timeout={1500}>
                            <Grid container justifyContent='center' alignItems="center" style={{paddingTop: '20px'}}>
                                {content.mainContent.map((item) => (
                                    <Fragment key={item?.key} >
                                        <Grid item  xs={12} className={classes.desc}>
                                            <Typography variant="h5">
                                                {item.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} >
                                        {item.list && item.list.map((listItem) => (
                                                <ListItem component="div" key={listItem?.key} className={classes.listLinks}>
                                                    <ListItemIcon className={classes.caret}>
                                                        <ChevronRightIcon fontSize="small"/>
                                                    </ListItemIcon>
                                                    <ListItemText primary={listItem?.value} />
                                                </ListItem>
                                            ))}
                                        </Grid>
                                    </Fragment>
                                    )) }
                            </Grid>
                        </Fade>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Fade in={true} timeout={2500}>
                            <Grid container justifyContent='center' alignItems="center" className= {classes.container} ref={formRef}>
                                <Grid item xs={12} >
                                    <Typography variant="h4"  className={classes.title} > Register </Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.formInputs}>
                                    <TextField required id="name" label="Name" variant="standard"
                                        className={classes.fields} onChange={(e) => handleTxt(e, 'name')}  error={errs.nameErr} 
                                        helperText={errs.nameErr && `Enter Full Name`} value={registerPayload.name}/>
                                </Grid>
                                <Grid item xs={12}  className={classes.formInputs}>
                                    <TextField className={classes.fields} required id="email" label="Email" 
                                    variant="standard" type='email' onChange={(e) => handleTxt(e, 'email')}  error={errs.validationErr || errs.mailErr} 
                                    helperText={errs.validationErr ? `Enter valid email address`: (errs.mailErr && `Enter email address`)}
                                    inputRef={mailInput}/>
                                </Grid>
                                <Grid item xs={12} className={classes.formInputs}>
                                    <TextField required id="pwd" label="Password" variant="standard" type="password" value={registerPayload.pwd}
                                        className={classes.fields} onChange={(e) => handleTxt(e, 'pwd')}  error={errs.pwdErr} 
                                        helperText={errs.pwdErr && `Enter a password`}/>
                                </Grid>
                                <Grid item xs={12} className={classes.formInputs}>
                                    <TextField required id="cnfPwd" label="Confirm Password" variant="standard" type="password"
                                        className={classes.fields} onChange={(e) => handleTxt(e, 'cnfPwd')}  
                                        error={errs.cnfPwdErr || errs.matchErr} inputRef={cnfPwdInput}
                                        helperText={errs.cnfPwdErr ? `Confirm your password` : (errs.matchErr && `Should match password`)}/>
                                </Grid>
                                <Grid item xs={12} className={classes.formInputs}>
                                    <FormControl  >
                                        <FormLabel component="legend" required>Are you an agent?</FormLabel>
                                        <RadioGroup row style={{justifyContent:'center'}} 
                                            aria-label="agentCheck"
                                            name="agentCheck"
                                            value={registerPayload.agentCheck}
                                            onChange={(e) => handleTxt(e, 'agentCheck')}
                                        >
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="No" />
                                        </RadioGroup>
                                        <FormHelperText error={errs.agentCheckErr}>{errs.agentCheckErr && `Choose an option`}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                
                                <Grid item xs={12} >
                                    <Button className={classes.sendBtn} color="inherit" variant='contained'
                                        onClick={handleSend}>
                                        Register
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={6} className={classes.formInputs}>
                                    <Button className={classes.actionBtn} size="medium" color="inherit" 
                                        onClick={() => handleRedirect('/forgotPwd')}>
                                        Forgot Password
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={6} className={classes.formInputs}>
                                    <Button className={classes.actionBtn} size="medium" color="inherit" 
                                        onClick={() => handleRedirect('/')}>
                                        Already registered? Click to Login!
                                    </Button>
                                </Grid>
                            </Grid>
                        </Fade>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default RegisterView;
