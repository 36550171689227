export const homeContent = {
    title: {
        key: 'homeTitle',
        value: 'Business Property Tax Group'
    },
    mainContent: [
        {
            key: 'homeList1',
            value: `The Business Property Tax Group has represented business owners with their property tax matters for over 27 years. 
            We are committed to assisting every taxpayer to assure they are in full compliance with all property tax laws and filing deadlines.
             Our purpose is to assure that our clients pay no more than their fair share of property taxes in Texas.`
        },
        {
            key: 'homeList2',
            value: `Specializing in business property tax has been one of our main focuses for the past decade. As one of the largest firms
            in the State of Texas representing business property, we service Austin, Dallas, Fort Worth, Houston, McAllen, San Antonio and 
            many other areas.`
        },
    ],
}