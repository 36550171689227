import { NotificationManager } from "react-notifications";
import { getBusinessInfoFromBusinessId } from "../../api/BusinessInfoApi";
import { getLocationInfoFromLocId } from "../../api/LocationInfoApi";
import { getAllAssets, postRendition, getAllLeasedAssets, getAllInventory, getAllSupplies } from "../../api/RenditionApi";
import { setModalState } from "../../components/appClient/AppClientActions";
import { putTaxInfo } from './taxInfoAction';
import { setAppServiceInfo } from './AppServiceInfoAction';
import { getAdditionalBusinessInfo } from "./AdditionalBusinessInfoActions";
import { getRepresentation } from "./RepresentationAction";

const {logger} = require('../../logger')

export const SET_RENDITION_STATUS = 'SET_RENDITION_STATUS';
export const SET_RENDITION_STATUS_SUCCESS = 'SET_RENDITION_STATUS_SUCCESS';
export const SET_RENDITION_STATUS_FAILURE = 'SET_RENDITION_STATUS_FAILURE';

export const GET_RENDITION_LEASEDASSETS_DATA = "GET_RENDITION_LEASEDASSETS_DATA";
export const GET_RENDITION_LEASEDASSETS_DATA_SUCCESS = "GET_RENDITION_LEASEDASSETS_DATA_SUCCESS";
export const GET_RENDITION_LEASEDASSETS_DATA_FAILURE = "GET_RENDITION_LEASEDASSETS_DATA_FAILURE";

export const GET_RENDITION_ASSETS_DATA = 'GET_RENDITION_ASSETS_DATA';
export const GET_RENDITION_ASSETS_DATA_SUCCESS = 'GET_RENDITION_ASSETS_DATA_SUCCESS';
export const GET_RENDITION_ASSETS_DATA_FAILURE = 'GET_RENDITION_ASSETS_DATA_FAILURE';

export const GET_RENDITION_SUPPLIES_DATA = 'GET_RENDITION_SUPPLIES_DATA';
export const GET_RENDITION_SUPPLIES_DATA_SUCCESS = 'GET_RENDITION_SUPPLIES_DATA_SUCCESS';
export const GET_RENDITION_SUPPLIES_DATA_FAILURE = 'GET_RENDITION_SUPPLIES_DATA_FAILURE';

export const GET_RENDITION_INVENTORY_DATA = 'GET_RENDITION_INVENTORY_DATA';
export const GET_RENDITION_INVENTORY_DATA_SUCCESS = 'GET_RENDITION_INVENTORY_DATA_SUCCESS';
export const GET_RENDITION_INVENTORY_DATA_FAILURE = 'GET_RENDITION_INVENTORY_DATA_FAILURE';

export const GET_RENDITION_LOC_DATA_SUCCESS = 'GET_RENDITION_LOC_DATA_SUCCESS';
export const GET_RENDITION_BIZ_DATA_SUCCESS = 'GET_RENDITION_BIZ_DATA_SUCCESS';

export const GET_RENDITION_ADDT_BUSINESS_DATA  = 'GET_RENDITION_ADDT_BUSINESS_DATA';
export const GET_RENDITION_ADDT_BUSINESS_DATA_SUCCESS = 'GET_RENDITION_ADDT_BUSINESS_DATA_SUCCESS';
export const GET_RENDITION_ADDT_BUSINESS_DATA_FAILURE = 'GET_RENDITION_ADDT_BUSINESS_DATA_FAILURE';

export const postRenditionAction = (isPageSubmitted) => {
    return (dispatch, getState) => { 
        let assets = getState().rendition.assets;
        let leasedAssets = getState().rendition.leasedAssets;
        let supplies = getState().rendition.supplies;
        let inventory = getState().rendition.inventory;
        let business = getState().rendition.business;
        let location = getState().rendition.location;
        let isClientAuthenticated = getState().appCtx.isClientAuthenticated;
        let isAuthorized = getState().appCtx.isAuthorized;
        let additionalBusiness = getState().rendition.additionalBusiness;
        let representativeInfo = '';
        let userProfileData = '';
        let taxFilingPlanId = getState().taxInfoReducer.taxInfo.id;
        // let businessInfoId = getState().businessInfo.businessInfo.id;
        // let locationInfoId = getState().locationInfo.locationInfo.id;
        let locationInfoId,businessInfoId;
        let taxFilingPlanData = getState().taxFilingPlanInfoReducer;
        let locationInfo = getState().locationInfo.locationInfo;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                businessInfoId = getState().taxFilingPlanInfoReducer.taxFilingPlan.businessInfoId;
            }
        }
        if(locationInfoId == null || locationInfoId === undefined){
            locationInfoId = locationInfo != null ? locationInfo.id:'';
        }
        if(businessInfoId === null || businessInfoId === undefined) {
            businessInfoId = getState().businessInfo.businessInfo.id;
        }

        if (isAuthorized && isClientAuthenticated) {
            userProfileData = getState().userProfile.profile;
            representativeInfo = {
                address: {
                    "line1": userProfileData.addressLine1,
                    "line2": userProfileData.addressLine2,
                    "email": userProfileData.email,
                    "city": userProfileData.city,
                    "state": userProfileData.state,
                    "zipcode": userProfileData.zip,
                },
                "id": userProfileData.id,
                "clientName": userProfileData.name,
                "phoneNumber":userProfileData.phone,
                "emailId":userProfileData.email,
                "userId": userProfileData.userId
            }
        } else if (isAuthorized) {
            let additionalrep = getState().representation.representation;
            representativeInfo = {...getState().agentProfile.agentprofile, securedPartyConsentInd: additionalrep.securedPartyConsentInd };
        }
        // let countyValue = getState().appClientCtx.countyValues.filter((item) => item.id === location.county)[0];
        // let stateValue = getState().appClientCtx.stateValues.filter((item) => item.id === location.address.state)[0];
        let postPayload = {
            values: { assets, leasedAssets, supplies, inventory, location, business, representativeInfo, additionalBusiness },
            stateCode: location.address.state,
            countyCode: location.county,
            taxYear: getState().taxFilingPlanInfoReducer.taxFilingPlan.taxYear,
            taxFilingPlanId
        }
        postRendition(postPayload).then((response) => {
            if (response.status === 200) {
                logger.info(`Successfully posted Rendition`);
                // dispatch(putTaxInfo(taxFilingPlanId,businessInfoId,locationInfoId,"RenditionInformation"));
                dispatch(putTaxInfo(taxFilingPlanId,businessInfoId,locationInfoId,"Draft Rendition",true));
                let file = new Blob([response.data], {
                    type: 'application/pdf'
                });
                let fileURL = window.URL.createObjectURL(file);
                window.open(fileURL);
                dispatch(setModalState(false));
                dispatch(setAppServiceInfo(response.status,"Rendition Info successfully submitted",false,isPageSubmitted));
            } else {
                NotificationManager.error("Couldn't generate rendition");
                dispatch(setModalState(false));
                dispatch(setAppServiceInfo(response.status,"Rendition Info error",true,isPageSubmitted));
            }
        }).catch((err) => {
            logger.error(`Failed to post Rendition,error:${err}`);
            NotificationManager.error("Couldn't generate rendition");
            dispatch(setModalState(false));
            dispatch(setAppServiceInfo(err.response.status,"Rendition Info error",true,isPageSubmitted));
        });
    }
}

export const getRenditionData = () => {
    return (dispatch, getState) => {
        dispatch(loadAssetData());
        dispatch(loadLeasedAssetsData());
        dispatch(loadInventoryData());
        dispatch(loadSuppliesData());
        dispatch(loadBusinessData());
        dispatch(loadLocationData());
        dispatch(loadAdditionalBusinessData());
        dispatch(loadRepresentationData());
    }
}

export const loadRepresentationData = () => {
    return (dispatch, getState) => { 
        let locationInfoId,taxFilingPlanId;
        let taxFilingPlanData = getState().taxFilingPlanInfoReducer;
        let locationInfo = getState().locationInfo.locationInfo;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
            }
        }
        if(locationInfoId == null || locationInfoId === undefined){
            locationInfoId = locationInfo != null ? locationInfo.id:'';
        }
        if(taxFilingPlanId == null || taxFilingPlanId === undefined){
            taxFilingPlanId = locationInfo != null ? locationInfo.taxInfoId:'';
        }

        dispatch(getRepresentation(taxFilingPlanId));
    }
}

export const loadAssetData = () => { 
    return (dispatch, getState) => {
        let locationInfoId,taxFilingPlanId;
        let taxFilingPlanData = getState().taxFilingPlanInfoReducer;
        let locationInfo = getState().locationInfo.locationInfo;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
            }
        }
        if(locationInfoId == null || locationInfoId === undefined){
            locationInfoId = locationInfo != null ? locationInfo.id:'';
        }
        if(taxFilingPlanId == null || taxFilingPlanId === undefined){
            taxFilingPlanId = locationInfo != null ? locationInfo.taxInfoId:'';
        }
        
        dispatch({ type: GET_RENDITION_ASSETS_DATA })
        getAllAssets(locationInfoId, taxFilingPlanId).then((response) => {
            if (response.status === 200) {
                logger.info(`successfully loaded AssetData in RenditionAction.js`);
                dispatch({type: GET_RENDITION_ASSETS_DATA_SUCCESS, payload: response.data})
            }
        }).catch((err) => {
            logger.error(`Failed to load AssetData,error:${err} in RenditionAction.js`);
            dispatch({type: GET_RENDITION_ASSETS_DATA_FAILURE, payload: err})
        })
    }
}
export const loadLeasedAssetsData = () => {
    return (dispatch, getState) => {

        let locationInfoId,taxFilingPlanId;
        let taxFilingPlanData = getState().taxFilingPlanInfoReducer;
        let locationInfo = getState().locationInfo.locationInfo;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
            }
        }
        if(locationInfoId == null || locationInfoId === undefined){
            locationInfoId = locationInfo != null ? locationInfo.id:'';
        }
        if(taxFilingPlanId == null || taxFilingPlanId === undefined){
            taxFilingPlanId = locationInfo != null ? locationInfo.taxInfoId:'';
        }
        
        dispatch({ type: GET_RENDITION_LEASEDASSETS_DATA })
        getAllLeasedAssets(locationInfoId, taxFilingPlanId).then((response) => {
            if (response.status === 200) {
                logger.info(`Successfully retrieved leased Asset data in RenditionAction.js`);
                dispatch({type: GET_RENDITION_LEASEDASSETS_DATA_SUCCESS, payload: response.data})
            }
        }).catch((err) => {
            logger.error(`Failed to load LeasedAssets data,error:${err} in RenditionAction.js`);
            dispatch({type: GET_RENDITION_LEASEDASSETS_DATA_FAILURE, payload: err})
        })
    }
}

export const loadSuppliesData = () => {
    return (dispatch, getState) => { 
        let locationInfoId,taxFilingPlanId;
        let taxFilingPlanData = getState().taxFilingPlanInfoReducer;
        let locationInfo = getState().locationInfo.locationInfo;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
            }
        }
        if(locationInfoId == null || locationInfoId === undefined){
            locationInfoId = locationInfo != null ? locationInfo.id:'';
        }
        if(taxFilingPlanId == null || taxFilingPlanId === undefined){
            taxFilingPlanId = locationInfo != null ? locationInfo.taxInfoId:'';
        }
        
        let taxYear = taxFilingPlanData.taxFilingPlan.taxYear;
        
        dispatch({type: GET_RENDITION_SUPPLIES_DATA})
        getAllSupplies(locationInfoId, taxFilingPlanId, taxYear).then((response) => {
            if (response.status === 200) {
                logger.info(`Successfully retrieved Supplies data in RenditionAction.js`);
                dispatch({type: GET_RENDITION_SUPPLIES_DATA_SUCCESS, payload: response.data})
            }
        }).catch((err) => {
            logger.error(`Failed to load Supplies data,error:${err} in RenditionAction.js`);
            dispatch({type: GET_RENDITION_SUPPLIES_DATA_FAILURE, payload: err})
        })
    }
}

export const loadInventoryData = () => {
    return (dispatch, getState) => {
        let locationInfoId,taxFilingPlanId;
        let taxFilingPlanData = getState().taxFilingPlanInfoReducer;
        let locationInfo = getState().locationInfo.locationInfo;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
            }
        }
        if(locationInfoId == null || locationInfoId === undefined){
            locationInfoId = locationInfo != null ? locationInfo.id:'';
        }
        if(taxFilingPlanId == null || taxFilingPlanId === undefined){
            taxFilingPlanId = locationInfo != null ? locationInfo.taxInfoId:'';
        }

        let taxYear = taxFilingPlanData.taxFilingPlan.taxYear;
        dispatch({type: GET_RENDITION_INVENTORY_DATA})
        getAllInventory(locationInfoId, taxFilingPlanId, taxYear).then((response) => {
            if (response.status === 200) {
                logger.info(`Successfully retrieved Inventory Data for the locationInfoId:${locationInfoId}`);
                console.log(response.data);
                dispatch({type: GET_RENDITION_INVENTORY_DATA_SUCCESS, payload: response.data})
            }
        }).catch((err) => {
            logger.error(`Failed to load Inventory data,error:${err} in RenditionAction.js`);
            dispatch({type: GET_RENDITION_INVENTORY_DATA_FAILURE, payload: err})
        });
    }
}

export const loadBusinessData = () => {
    return (dispatch, getState) => {
        let isClient = getState().appCtx.isClientAuthenticated;
        let currBusiness = getState().taxFilingPlanInfoReducer.taxFilingPlan.businessInfoId;
        getBusinessInfoFromBusinessId(isClient, currBusiness).then((response) => {
            if (response.status === 200) {
                logger.info(`Successfully retrieved BusinessInfo using current business:${currBusiness}`);
                dispatch({type: GET_RENDITION_BIZ_DATA_SUCCESS, payload: response.data})
            }
        }).catch((err) => {
            logger.error(`Failed to load Business data using current business:${currBusiness},error:${err} in RenditionAction.js`);
        });
    };
}

export const loadLocationData = () => {
    return (dispatch, getState) => {
        let currLocation,taxFilingPlanId;
        let taxFilingPlanData = getState().taxFilingPlanInfoReducer;
        let locationInfo = getState().locationInfo.locationInfo;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                currLocation = taxFilingPlanData.taxFilingPlan.locationInfoId;
                taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
            }
        }
        if(currLocation == null || currLocation === undefined){
            currLocation = locationInfo != null ? locationInfo.id:'';
        }

        
        getLocationInfoFromLocId(currLocation).then((response) => {
            if (response.status === 200) {
                logger.info(`Successfully retrieved location data for the current location:${currLocation}`);
                dispatch({type: GET_RENDITION_LOC_DATA_SUCCESS, payload: response.data});
            }
        }).catch((err) => {
            logger.error(`Failed to load Location data for the current location:${currLocation},error:${err} in RenditionAction.js`);
        });
    };
}

export const loadAdditionalBusinessData = () => {
    return (dispatch, getState) => {
        dispatch({ type: GET_RENDITION_ADDT_BUSINESS_DATA });
        let taxFilingPlanId = getState().taxFilingPlanInfoReducer.taxFilingPlan.id;
        getAdditionalBusinessInfo(taxFilingPlanId).then((response) => {
            let payload = response.data;
            if(payload) {
            let getParsedPayload = {
                businessType: payload.businessType,
                sicCode: payload.sicCode,
                businessDescription: payload.businessDescription,
                squareFootOccupied: payload.occupiedArea,
                businessSoldDate: payload.businessSoldDate,
                businessStartDateAtLoc: payload.businessStartDate,
                businessMovedDate: payload.businessMovedDate,
                businessClosedDate: payload.businessClosedDate,
                newOwner: payload.newOwnerName,
                salesTaxPermitNumber: payload.salesTaxPermitNr,
                newAddressLine1: payload.newAddress? payload.newAddress.line1: '',
                newAddressLine2: payload.newAddress? payload.newAddress.line2:'', 
                state: payload.newAddress?payload.newAddress.state:'', 
                city: payload.newAddress?payload.newAddress.city:'',
                zip: payload.newAddress?payload.newAddress.zipcode:'',
                assetsConfirmationInd: payload.assetsConfirmationInd,  
                id: payload.id
            }
            dispatch({ type: GET_RENDITION_ADDT_BUSINESS_DATA_SUCCESS, payload: getParsedPayload });
        } else{
            dispatch({ type: GET_RENDITION_ADDT_BUSINESS_DATA_FAILURE, payload: {} });
        }
        })
    }
}
