import { getTaxInformationPlans,getTaxInformationPlan } from '../services/taxService';
import {GET_TAX_INFO} from './taxInfoAction';

export const GET_TAX_FILING_PLANS_INFO = "GET_TAX_FILING_PLANS_INFO";
export const GET_TAX_FILING_PLAN_INFO = "GET_TAX_FILING_PLAN_INFO";
export const SET_SELECTED_PLAN_FALSE = "SET_SELECTED_PLAN_FALSE";
export const SET_SELECTED_PLAN_TRUE = "SET_SELECTED_PLAN_TRUE";
export const SET_NEW_PLAN_TRUE = 'SET_NEW_PLAN_TRUE';
export const SET_NEW_PLAN_FALSE = 'SET_NEW_PLAN_FALSE';
export const RESET_TAX_INFO = 'RESET_TAX_INFO';

export const getTaxFilingPlans = (directClientInfoId,agentProfileId,expand) => {
    return dispatch => {
        getTaxInformationPlans(directClientInfoId,agentProfileId,expand).then((response) => {
             if (response && response.status === 200) {
                 let data = response.data;
                
                 dispatch({type: GET_TAX_FILING_PLANS_INFO, payload: data})
             }
         });
    }

}

export const getTaxFilingPlan = (taxFilingPlanId) => {
    return dispatch => {
        getTaxInformationPlan(taxFilingPlanId).then((response) => {
             if (response && response.status === 200) {
                 let data = response.data;
                
                 dispatch({type: GET_TAX_FILING_PLAN_INFO, payload: data});
                 dispatch({type: GET_TAX_INFO, payload: data});
             }
         });
    }

}

export const setSelectedPlanFalse = () => {
    return dispatch => {
        dispatch({type:SET_SELECTED_PLAN_FALSE});
    }
}

export const setNewPlan = () => {
    return dispatch => {
        dispatch({type:SET_NEW_PLAN_TRUE});
    }
}

export const setNewPlanFalse = () => {
    return dispatch => {
        dispatch({type:SET_NEW_PLAN_FALSE});
    }
}

export const resetTaxInfo = () => {
    return dispatch => {
        dispatch({ type: RESET_TAX_INFO });
    }
}
