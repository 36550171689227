import React, {useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useForm,useFormState } from "react-hook-form";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { Grid,Box,Divider,Typography } from '@mui/material';
import { postTaxInformation, putTaxInfo} from '../../../redux/actions/taxInfoAction';
import { makeStyles } from '@mui/styles';
import { getClientDetails } from '../AppClientSelectors';
import { PRIMARY_COLOR } from "../../../constants/colorScheme";
import { getDirectClientInfoId } from '../../profile/userProfile/UserProfileSelectors';
import { getAgentProfile } from '../../profile/agentProfile/AgentProfileSelectors';
import { useEffect } from "react";
import { getTaxFillingData } from '../selectors/TaxFilingPlanSelector';
import { dispatchSavedPlanData } from '../../../util/utilities';
import { setAppServiceInfo } from '../../../redux/actions/AppServiceInfoAction';
import { getTaxInfo } from '../selectors/TaxInfoSelector';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    width:'90vw',
    [theme.breakpoints.down("sm")]: {
      width: '70vw',
    },
    "& .MuiInputLabel-root": {
      // color: 'red',
      color: PRIMARY_COLOR,
      fontWeight: 'bold'
  },
  },
}));

export default function TaxInfo({nextButton,saveButton}) {
  const classes = useStyles();
  const { register, handleSubmit, control} = useForm({validations: { // all our validation rules go here
    taxYear: {
      required: {
        value: true,
        message: 'This field is required',
      },
    },
  }, });
  const { errors } = useFormState({
    control
  });
  const dispatch = useDispatch();
  const clientDetails = useSelector(getClientDetails);
  const directClientInfoId = useSelector(getDirectClientInfoId);
  const agentProfile = useSelector(getAgentProfile);
  const taxFilingPlanData = useSelector(getTaxFillingData);
  const taxInfoData = useSelector(getTaxInfo);
  let taxFilingPlan = taxFilingPlanData.taxFilingPlan;
  if(taxFilingPlanData && !taxFilingPlanData.isPlanSelected) {
    if(taxInfoData !== null) {
      taxFilingPlan = {...taxInfoData}
    } else {
     taxFilingPlan = {};
    }
  }
  
  const [taxInfo, setTaxInfo] = useState({ ...taxFilingPlan });

  const onSubmit = (data,action) => {
    // throw new Error("something is wrong");
      //TODO: here we invoke calls using action creators and on successful service call,we store data in redux store
      let taxFilingPlanId = null,businessInfoId = null,locationInfoId = null,lastVisitedPage = null;
      if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
        if(taxFilingPlanData.taxFilingPlan){
            taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
            businessInfoId = taxFilingPlanData.taxFilingPlan.businessInfoId;
            locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
        }
    }
    if(taxFilingPlanId === null || taxFilingPlanId === undefined) {
      taxFilingPlanId = taxInfoData.id;
    }
    let userType = clientDetails.userType;
    if(action === 'next'){
      if(userType === 'CLIENT' || userType === 'BUSINESS-OWNER'){
        lastVisitedPage = 'BusinessInfo';
      } else {
        lastVisitedPage = 'RepresentationInfo';
      }
      
    } else if(action === 'save'){
        lastVisitedPage = 'BasicInfo';
     
    }
    if(taxFilingPlanId !== null && taxFilingPlanId !== undefined && taxFilingPlanId !== "")  {
      dispatch(putTaxInfo(taxFilingPlanId,businessInfoId,locationInfoId,lastVisitedPage,true));
    } else {
      dispatch(postTaxInformation(data,directClientInfoId,agentProfile?agentProfile.id: null,userType,true,lastVisitedPage));
    }
  }

  useEffect( () => {
    if(taxFilingPlanData.isPlanSelected && !taxFilingPlanData.isNewPlan){
        dispatchSavedPlanData(dispatch,'BasicInfo',taxFilingPlanData.taxFilingPlan);
    }
    dispatch(setAppServiceInfo(0,null,false,false));

  },[dispatch]);
  var currentDate = new Date();
  return (
    <Box style={{position:'relative',left:'2%',bottom:'15%',textAlign:'justify',minHeight:'360px'}}>
    <form  >
        <Grid container direction="column">
             <Grid item xs={12} sx={{m:1}}>
                <h1 style={{textAlign:'center'}}>Tax Year
                  <Typography className={classes.loginTitle} style={{ float: 'right' }}>
                        <span style={{color: 'red'}}>*</span>  Required fields 
                  </Typography><Divider />
                </h1> 
            </Grid>
            <Grid item xs={12} sx={{fontWeight: 'bold', color: PRIMARY_COLOR}}>Select the tax year you want to work on from the drop down below:</Grid>
            <Grid item xs={12} >
            <FormControl sx={{ m: 1}} className={classes.fieldContainer}>
                <InputLabel htmlFor="taxYear" >Tax Year</InputLabel>
                <Select
                    labelId="taxYear"
                    id="taxYear"
                    required
                    label="taxYear"
                    {...register('taxYear', {  required: "Please enter your first name.", onChange: (e) => { setTaxInfo({...taxInfo, taxYear: e.target.value}); }  })}
                    value={taxInfo.taxYear}    
                >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                <MenuItem value={currentDate.getFullYear() - 1}>{currentDate.getFullYear() - 1}</MenuItem>
                <MenuItem value={currentDate.getFullYear() - 2}>{currentDate.getFullYear() - 2}</MenuItem>
                <MenuItem value={currentDate.getFullYear() - 3}>{currentDate.getFullYear() - 3}</MenuItem>
                </Select>
                </FormControl>
                {errors.taxYear && <p>Please select Tax Year</p>}
                {/* {errors.taxYear && errors.taxYear.type === "required" && <span>This is required</span>} */}
         </Grid>
         <Grid item xs={12} sx={{fontWeight: 'bold', color: PRIMARY_COLOR}}> 
          Please enter Business name
        </Grid>
        <Grid item xs={12} sx={{ m: 1 ,width: '70vw' }}>
        <TextField id="businessName" label="Business Name" variant="outlined" required {...register('businessName', { required: true, maxLength: 30, onChange: (e) => { setTaxInfo({...taxInfo, businessName: e.target.value}); } })}
        className={classes.fieldContainer} value={taxInfo.businessName} InputLabelProps={{shrink: true}} />
        {errors.businessName && <p>Please enter Business name</p>}
        </Grid>

        
        <input type="submit" ref={nextButton} style={{ display: 'none' }} onClick={handleSubmit((data) => {onSubmit(data,'next')})}/>
        <input type="submit" ref={saveButton} style={{ display: 'none' }} onClick={handleSubmit((data) => {onSubmit(data,'save')})}/>
        </Grid>
        
    </form>
    </Box>
  );
}
