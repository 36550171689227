import { assetTypes } from "../../api/AuthApi";
import { NotificationManager } from 'react-notifications';
import { svcRequest } from "../../util/requestHelper";
import config from "../../config/context-root-configs";
import { setAssetInfoStatus } from "../../components/appClient/AppClientActions";
const { logger } = require('../../logger');

export const FETCH_ASSET_TYPES = "FETCH_ASSET_TYPES";
export const FETCH_ASSET_TYPES_SUCCESS = "FETCH_ASSET_TYPES_SUCCESS";
export const FETCH_ASSET_TYPES_FAILURE = "FETCH_ASSET_TYPES_FAILURE";

export const SET_ASSET_INFO = "SET_ASSET_INFO";
export const SET_ASSET_INFO_SUCCESS = "SET_ASSET_INFO_SUCCESS";
export const SET_BULKASSET_INFO_SUCCESS = "SET_BULKASSET_INFO_SUCCESS";
export const GET_BULKASSET_INFO_SUCCESS = "GET_BULKASSET_INFO_SUCCESS";
export const GET_BULKASSET_INFO_FAILURE = "GET_BULKASSET_INFO_FAILURE";

export const DELETE_ASSETS_SUCCESS = "DELETE_ASSETS_SUCCESS";
export const SET_CURRENT_ASSET_INFO_SUCCESS = "SET_CURRENT_ASSET_INFO_SUCCESS";

export const GET_UPDATE_ASSETS_INFO = "GET_UPDATE_ASSETS_INFO";
export const GET_UPDATE_ASSETS_INFO_SUCCESS = "GET_UPDATE_ASSETS_INFO_SUCCESS";
export const GET_UPDATE_ASSETS_INFO_FAILURE = "GET_UPDATE_ASSETS_INFO_FAILURE";

export const RESET = 'RESET'

export const fetchAssetTypes = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_ASSET_TYPES });
        let types = [];
        logger.info('Fetching Asset types. File Name: AssetsInfoActions.js');
        assetTypes().then((response) => {
            if (response && response.status === 200) {
                // let data = response.data;
                // data.forEach((value) => (
                //     types.push(value.display)
                // ))
                dispatch({type: FETCH_ASSET_TYPES_SUCCESS, payload: response.data})
            }
            else {
                if(response){
                    logger.error(`Failed to fetch Asset types from AssetsInfoActions where 
                    we are getting response status: ${response.status} and response:${response}`
                    );
                }
                dispatch({type: FETCH_ASSET_TYPES_FAILURE, payload: types});
            }
            
        }).catch((err) => {
            logger.error(`Failed to fetch Asset types from AssetsInfoActions, error : -> ${err}`);
            dispatch({ type: FETCH_ASSET_TYPES_FAILURE, payload: types });
            NotificationManager.error(err.message);
        });
        
    }
}

export const submitAssetsInfo = (payload) => {
    return (dispatch) => {
        postAsset(payload).then(res => {
            logger.info('Successfully added Asset. File Name: AssetsInfoActions.js');
            dispatch({type: SET_CURRENT_ASSET_INFO_SUCCESS, payload})
        }).catch((err) => {
            logger.error(`Failed to Submit Asset types from AssetsInfoActions, error : -> ${err}`);
            NotificationManager.error(err.message)
        });
        
        
    }
}
export const postAsset = (payload) => {
    return svcRequest.post(config.asset, payload)
  }

  export const getBulkAssets = (locationInfoId,assetType) => {
    return (dispatch) => {
        svcRequest.get(config.bulkAssets, { params: { locationInfoId: locationInfoId,assetType:assetType } }).then(response => {
            dispatch({type: GET_BULKASSET_INFO_SUCCESS, payload: response});
        }).catch(error => {
            dispatch({type: GET_BULKASSET_INFO_FAILURE, payload: error});
        });
    }
  }

  export const getAssets = (locationInfo,taxFilingPlanId,offset,limit) => {
      //&sortBy=dateAcquired&sortType=ascending
    return (dispatch, getState) => {
        svcRequest.get(config.asset+`?locationInfoId=${locationInfo}&taxFilingPlanId=${taxFilingPlanId}&offset=${offset}&limit=${limit}&sortBy=dateAcquired&sortType=ascending`).then(res => {
            let assets = res.data.assets;
            let items = [];
            assets.forEach((item) => {
                let responseMod = {
                    ...item, 
                    assetType: getState().assets.assetTypes.filter((type) => type.id === item.assetType)[0].display
                }
                items.push(responseMod)
            })
            let finalResponse = {
                totalItems: res.data.totalItems, 
                assets: items

            }
            dispatch({type: SET_ASSET_INFO, payload: finalResponse});
            if (res.data.totalItems) {
                dispatch(setAssetInfoStatus(true));
            } else {
                dispatch(setAssetInfoStatus(false));
            }
        }).catch((err) => {
            logger.error(`Failed to retrieve Assets,FileName: AssetsInfoActions, error : -> ${err}`);
            NotificationManager.error(err.message);
        });
       
        
    }
}
  
export const getFilteredAssets = (taxInfoId, locationInfo, offset, limit, description, assetType) => {
    
    let reqParams = {
        taxFilingPlanId: taxInfoId,
        locationInfoId: locationInfo,
        offset: offset,
        limit: limit, 
        description: description, 
        assetType: assetType
    }
    return svcRequest.get(config.asset, {params: reqParams},{
        timeout: 30000
      })
}
  
export const deleteAssetInfoById = (asset, rowsPerPage) => {
    return (dispatch, getState) => {
       
            svcRequest.delete(config.asset + asset).then((response) => {
                if (response.status === 200) {
                    logger.info('Successfully deleted Asset');
                    dispatch({ type: DELETE_ASSETS_SUCCESS })
                    let locationId = getState().locationInfo.locationInfo.id;
                    let taxInfoId = getState().taxInfoReducer.taxInfo.id;
                    dispatch(getAssets(locationId,taxInfoId, 0, rowsPerPage))
                }
            }).catch((err) => {
                logger.error(`Failed to delete Asset,FileName: AssetsInfoActions, error : -> ${err}`);
            });
    }
}

export const deleteBulkAssetInfo = (asset, rowsPerPage) => {
    return (dispatch, getState) => {
            svcRequest.delete(config.asset+ 'bulk/delete',  { data: { payload: asset } }).then((response) => {
                if (response.status === 200) {
                    logger.info('Successfully deleted Asset');
                    dispatch({ type: DELETE_ASSETS_SUCCESS })
                    let locationId = getState().locationInfo.locationInfo.id;
                    let taxInfoId = getState().taxInfoReducer.taxInfo.id;
                    dispatch(getAssets(locationId,taxInfoId, 0, rowsPerPage))
                }
            }).catch((err) => {
                logger.error(`Failed to delete Asset,FileName: AssetsInfoActions, error : -> ${err}`);
            });
    }
}
const getFormattedDate = (date) => {
    let dateObj = new Date(date)
    let finalDateString = dateObj.getFullYear() + '-' + ('0' + (dateObj.getMonth()+1)).slice(-2) + '-' + ('0' + dateObj.getDate()).slice(-2) 
    
    return finalDateString
}
export const getAssetsInfoById = (assetId) => {
    return (dispatch) => {
        dispatch({ type: GET_UPDATE_ASSETS_INFO });
        svcRequest.get(config.asset + assetId).then((response) => {
            
            if (response.status === 200) {
                logger.info(`Successfully retrieved Assets for the assetId ${assetId}`);
                let data = response.data
                let getPayload = {
                    assetDesc: data.assetDescription,
                    assetNum: data.assetNumber,
                    assetType: data.assetType,
                    costCenter: data.costCenter,
                    dateAcquired: getFormattedDate(data.dateAcquired),
                    id: data.id,
                    locationInfoId: data.locationInfoId,
                    orgPurchasePrice: data.originalPurchasePrice,
                    quantity: data.quantity
                }
                dispatch({type: GET_UPDATE_ASSETS_INFO_SUCCESS, payload: getPayload})
            } else {
                logger.error(`Failed to retrieve asset by Id,FileName: AssetsInfoActions`);
                dispatch({type: GET_UPDATE_ASSETS_INFO_FAILURE, payload: []})
            }
        }).catch((err) => {
            logger.error(`Failed to retrieve asset by Id,FileName: AssetsInfoActions, error : -> ${err}`);
            dispatch({type: GET_UPDATE_ASSETS_INFO_FAILURE, payload: err});
        });
    }
}

export const updateAssetsInfo = (payload) => {
    return (dispatch, getState) => {
        svcRequest.patch(config.asset+payload.id, payload).then((response) => {
            if (response.status === 200) {
                logger.info(`Successfully updated Asset Info`);
                let locationId = getState().locationInfo.locationInfo.id;
                let taxInfoId = getState().taxInfoReducer.taxInfo.id;
                dispatch(getAssets(locationId, taxInfoId,0, 5));
            }
        }).catch((err) => {
            logger.error(`Failed to update asset info,FileName: AssetsInfoActions, error : -> ${err}`);
        });
    }
}

export const getFilteredAssetsInfo = (description, assetType, limit) => {
    return (dispatch, getState) => {
        
        let locationInfoId,taxInfoId;
        let taxFilingPlanData = getState().taxFilingPlanInfoReducer;
        let locationInfo = getState().locationInfo.locationInfo;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                taxInfoId = taxFilingPlanData.taxFilingPlan.id;
            }
        }
        if(locationInfoId == null || locationInfoId === undefined){
            locationInfoId = locationInfo != null ? locationInfo.id:'';
        }
        if(taxInfoId == null || taxInfoId === undefined){
            taxInfoId = locationInfo != null ? locationInfo.taxInfoId:'';
        }
        getFilteredAssets(taxInfoId, locationInfoId, 0, limit, description, assetType).then((response) => {
            let assets = response.data.assets;
            let items = [];
            assets.forEach((item) => {
                let responseMod = {
                    ...item, 
                    assetType: getState().assets.assetTypes.filter((type) => type.id === item.assetType)[0].display
                }
                items.push(responseMod)
            })
            let finalResponse = {
                totalItems: response.data.totalItems, 
                assets: items

            }
            dispatch({type: SET_ASSET_INFO, payload: finalResponse});
        }).catch((err) => {
            logger.error(`Failed to retrieve Filtered Assets,FileName: AssetsInfoActions, error : -> ${err}`);
        })
   }
}

export const clearAssetsForm = () => {
    return (dispatch) => {
        dispatch({ type: RESET });
    }
}