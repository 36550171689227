import React, {useState, useRef, useEffect} from "react";
import { useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import { Grid , Box, FormControlLabel, MenuItem, FormLabel, RadioGroup, Radio, InputLabel, Select, 
    OutlinedInput,Typography,Divider, InputAdornment, Checkbox, ListItemText, FormHelperText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PRIMARY_COLOR } from "../../../constants/colorScheme";
import { getInventoryInfoByLocIdTaxYearAndTaxFillingId, submitInventoryInfo, updateInventoryInfo, updateInvForResaleFlag, updateSecondFormSectionStatus, updateThirdFormSectionStatus } from '../../../redux/actions/InventoryInfoActions';
import { getInventoryInfo, getIsInventoryUpdating } from "../selectors/InventoryInfoSelectors";
import { formatNumberInput } from '../../../util/utilities';
import { setAppServiceInfo } from '../../../redux/actions/AppServiceInfoAction';
const useStyles = makeStyles((theme) => ({
    fieldContainer: {
      width:'90vw',
      [theme.breakpoints.down("sm")]: {
        width: '45vw',
        left: '20%'
        },
        "& .MuiInputLabel-root": {
            color: PRIMARY_COLOR,
            fontWeight: 'bold'
        },
    },
  }));



export default function InventoryInformation({nextButton,saveButton}) {
    const { register, handleSubmit, reset,formState: { errors } } = useForm();
    const dispatch = useDispatch();
    const classes = useStyles();
    const sellOutOfState = "No";
    const secondSection = useRef(null);
    const thirdSection = useRef(null);
    const portionOfInvTransOutStateLastYear = 'No';
    const percGoodsSigDiff = 'No';
    const options = ["Audited Financial Statements ", "Sales Records", "Internal Records", "Bill of Lading"]
    const [optionName, setOptionName] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const inventoryInfoData = useSelector(getInventoryInfo);
    const [inventoryInfoForm, setInventoryInfoForm] = useState({ ...inventoryInfoData });

    const [secondFormSection, setSecondFormSection] = useState(false);
    const [thirdFormSection, setThirdFormSection] = useState(false);
    const updateFlag = useSelector(getIsInventoryUpdating);

    const onSubmit = (data,lastVisitedPage) => {
        console.log(data);
        let payload = {
            ...data,
            invForResale: data.invForResale === 'Yes' ? true : false,
            sellOutOfState: data.sellOutOfState === 'Yes' ? true : false,
            invTransOutStateThisYear: data.invTransOutStateThisYear === 'Yes' ? true : false, 
            portionOfInvTransOutStateLastYear: data.portionOfInvTransOutStateLastYear === 'Yes' ? true : false,
            percGoodsSigDiff: data.percGoodsSigDiff === 'Yes' ? true : false,
            invValueOnDec: data.invValueOnDec!==undefined ? parseInt(data.invValueOnDec) : 0,
            totSaleOutOfState: data.totSaleOutOfState !== undefined ? parseInt(data.totSaleOutOfState): 0,
            percentOfGoodsOutState: data.percentOfGoodsOutState!==undefined ? parseInt(data.percentOfGoodsOutState): 0,
            totCostShippedOutState: data.totCostShippedOutState!==undefined ? parseInt(data.totCostShippedOutState) : 0,
            totInvJan: data.January ? parseInt(data.January) : 0,
            totInvFeb: data.February ? parseInt(data.February) : 0,
            totInvMar: data.March ? parseInt(data.March) :0 ,
            totInvApr: data.April ? parseInt(data.April) : 0,
            totInvMay: data.May ? parseInt(data.May) :0,
            totInvJune: data.June ? parseInt(data.June):0,
            totInvJuly: data.July ? parseInt(data.July):0,
            totInvAug: data.August ? parseInt(data.August):0,
            totInvSep: data.September ? parseInt(data.September):0,
            totInvOct: data.October ? parseInt(data.October):0,
            totInvNov: data.November ? parseInt(data.November):0,
            totInvDec: data.December ? parseInt(data.December) : 0,
            recordTypes: (data.recordTypes!==undefined && typeof(data.recordTypes) === 'string') ? data.recordTypes.split(','): data.recordTypes

        }
        // if (secondFormSection && thirdFormSection) {
            updateFlag ? dispatch(updateInventoryInfo(payload,true,lastVisitedPage)) : dispatch(submitInventoryInfo(payload,true,lastVisitedPage));
        // } else {
        //     setErrorMessage('Please checkout the remaining sections of the form!!!')
        // }
        }
    
    const handleFormSections = (sectionName, sectionNameAsString) => {
        if (sectionNameAsString === 'secondSection') {
            // secondFormSection  ? setSecondFormSection(false) : setSecondFormSection(true)
            setSecondFormSection(true);
            dispatch(updateSecondFormSectionStatus(true));
        }
        if (sectionNameAsString === 'thirdSection') {
            setThirdFormSection(true);
            dispatch(updateThirdFormSectionStatus(true));
        }
        sectionName.current?.scrollIntoView({behavior: 'smooth'});
    }

    
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 
    'July', 'August', 'September', 'October', 'November', 'December'];
    
    useEffect(() => {
        if (secondFormSection) {
            secondSection.current?.scrollIntoView({behavior: 'smooth'})
        }
        if (thirdFormSection) {
            thirdSection.current?.scrollIntoView({behavior: 'smooth'})
        }
    }, [secondFormSection, thirdFormSection])

    useEffect(() => {
        setInventoryInfoForm(inventoryInfoData);
        reset();
    }, [inventoryInfoData])
    
    useEffect(() => {
        dispatch(getInventoryInfoByLocIdTaxYearAndTaxFillingId());
        reset();
        dispatch(setAppServiceInfo(0,null,false,false));
    }, [dispatch])

    useEffect(() => {
        if (thirdFormSection && secondFormSection) {
            setErrorMessage("");
        }
    }, [thirdFormSection, secondFormSection])
    
    useEffect(() => {
		if(inventoryInfoData.invForResale){
		    dispatch(updateInvForResaleFlag(true))
		}
		else{
		    dispatch(updateInvForResaleFlag(false))
		}
    }, [inventoryInfoData.invForResale])
    
    const setValueToMonthFields = (month) => {
        switch (month) {
            case 'January': return inventoryInfoForm.totInvJan;
            case 'February': return inventoryInfoForm.totInvFeb;
            case 'March': return inventoryInfoForm.totInvMar;
            case 'April': return inventoryInfoForm.totInvApr;
            case 'May': return inventoryInfoForm.totInvMay;
            case 'June': return inventoryInfoForm.totInvJune;
            case 'July': return inventoryInfoForm.totInvJuly;
            case 'August': return inventoryInfoForm.totInvAug;
            case 'September': return inventoryInfoForm.totInvSep;
            case 'October': return inventoryInfoForm.totInvOct;
            case 'November': return inventoryInfoForm.totInvNov;
            case 'December': return inventoryInfoForm.totInvDec;
            default:
                return 0;
        } 
    }

    const onChangeMonthValue = (month, value) => {
        switch (month) {
            case 'January': setInventoryInfoForm({ ...inventoryInfoForm, totInvJan: value }); return;
            case 'February': setInventoryInfoForm({ ...inventoryInfoForm, totInvFeb: value }); return;
            case 'March': setInventoryInfoForm({ ...inventoryInfoForm, totInvMar: value }); return;
            case 'April': setInventoryInfoForm({ ...inventoryInfoForm, totInvApr: value }); return;
            case 'May': setInventoryInfoForm({ ...inventoryInfoForm, totInvMay: value }); return;
            case 'June': setInventoryInfoForm({ ...inventoryInfoForm, totInvJune: value }); return;
            case 'July': setInventoryInfoForm({ ...inventoryInfoForm, totInvJuly: value }); return;
            case 'August': setInventoryInfoForm({ ...inventoryInfoForm, totInvAug: value }); return;
            case 'September': setInventoryInfoForm({ ...inventoryInfoForm, totInvSep: value }); return;
            case 'October': setInventoryInfoForm({ ...inventoryInfoForm, totInvOct: value }); return;
            case 'November':  setInventoryInfoForm({ ...inventoryInfoForm, totInvNov: value }); return;
            case 'December': setInventoryInfoForm({ ...inventoryInfoForm, totInvDec: value }); return;
            default:
                return 0;
        } 
    }
    
  return (
    <Box style={{position:'relative',left:'1%',bottom:'15%',textAlign:'justify'}}>
    <form onSubmit={handleSubmit(onSubmit)} >
        <Grid container direction="column" spacing={2}>
            {/* { errorMessage && <Alert severity="warning"> { errorMessage } </Alert>} */}
            <Grid item xs={12} sx={{ m: 1 }}>
                <h1 style={{textAlign:'center'}}>Inventory Information
                    <Typography className={classes.loginTitle} style={{ float: 'right' }}>
                            <span style={{color: 'red'}}>*</span>  Required fields 
                    </Typography><Divider />
                </h1> 
                <h5> Enter the following information if you need to add inventory information. </h5>
                </Grid>
                <Grid item>
                    <FormLabel required component="legend">Does your business hold inventory for resale?   </FormLabel>
                    <RadioGroup
                        row
                        name="invForResale"
                        defaultValue="No"
                        {...register('invForResale')}
                        onChange={e => {
                            setInventoryInfoForm({ ...inventoryInfoForm, invForResale: (e.target.value === 'Yes') ? true : false });
                            if (e.target.value === 'No') {
                                setThirdFormSection(false);
                                setSecondFormSection(false);
                                dispatch(updateInvForResaleFlag(false));
                            }
                            if (e.target.value === 'Yes') {
                                dispatch(updateInvForResaleFlag(true));
                            }
                        }}
                        value={inventoryInfoForm.invForResale === true ? "Yes" : "No"}
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>

                {inventoryInfoForm.invForResale && <> <Grid item  >
                    <TextField
                        id="descOfInv"
                        label="Description for business hold inventory for resale?"
                        variant="outlined"
                        className={classes.fieldContainer}
                        InputLabelProps={{ required: true }} 
                        {...register('descOfInv', {
                            required: 'Required Description for the inventory',
                            onChange : (e) =>{ setInventoryInfoForm({...inventoryInfoForm, descOfInv : e.target.value })}
                        })}
                        
                        disabled={ inventoryInfoForm.invForResale === false}
                        error={errors.descOfInv}
                        helperText={errors.descOfInv ? errors.descOfInv.message : ""}
                        value={inventoryInfoForm.descOfInv}
                    />
                </Grid>
                <Grid item  >
                    <TextField
                        id="invValueOnDec"
                        label="Inventory Value on December 31"
                        variant="outlined"
                        type={'number'}
                        className={classes.fieldContainer}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            inputProps: {min: 1}
                        }}
                        InputLabelProps={{ required: true }} 
                        {...register('invValueOnDec',{
                            required:  'Require inventory value on December',
                            onChange: (e)=>{setInventoryInfoForm({...inventoryInfoForm, invValueOnDec: e.target.value})},
                            min: {value: 1, message: "Value must be greater than 0"}

                        })}
                        onKeyDown={ formatNumberInput }
                        disabled = {inventoryInfoForm.invForResale === false}
                        error = {errors.invValueOnDec}
                        helperText={errors.invValueOnDec ? errors.invValueOnDec.message : ""}
                        value={inventoryInfoForm.invValueOnDec}
                    />
                </Grid>
                </>}
                
                {inventoryInfoForm.invForResale === true && <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2} style={{paddingTop: '20px'}}>
                    <Grid item>
                        <Fab color="primary">
                              <ExpandMoreIcon onClick={() => { handleFormSections(secondSection, 'secondSection'); }} />
                        </Fab>
                        <Typography> Continue </Typography>
                    </Grid>
                </Grid>}
                {secondFormSection && inventoryInfoForm.invForResale === true &&  <>
                <Grid item xs={12} ref={secondSection}>
                <FormLabel required component="legend">Do you sell and ship inventory to customers out of state?   </FormLabel>
                    <RadioGroup
                        row
                        name="sellOutOfState"
                        defaultValue="No"
                        {...register('sellOutOfState')}
                        onChange={e => {
                            setInventoryInfoForm({ ...inventoryInfoForm, sellOutOfState: (e.target.value === 'Yes') ? true : false });
                        }}
                        value={inventoryInfoForm.sellOutOfState === true ? "Yes": "No"}
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>

                {inventoryInfoForm.sellOutOfState && <><Grid item xs={12}>
                    <TextField
                        {...register('totSaleOutOfState',{
                            required: 'Require Total cost of goods sold for the year',
                            onChange: (e) =>{ setInventoryInfoForm({...inventoryInfoForm, totSaleOutOfState : e.target.value })},
                            min: {value: 1, message: "Must be greater than 0"}
                        })}
                        disabled={inventoryInfoForm.sellOutOfState === false}
                        id="totSaleOutOfState"
                        label="Enter the Total Cost of Goods Sold for Year"
                        InputProps={{ inputProps: { min: 1 } }}
                        variant="outlined"
                        InputLabelProps={{ required: true }} 
                        type={'number'}
                        onKeyDown={ formatNumberInput }
                        className={classes.fieldContainer}
                        value={inventoryInfoForm.totSaleOutOfState}
                        error={errors.totSaleOutOfState}
                        helperText={errors.totSaleOutOfState ? errors.totSaleOutOfState.message: ''}
                    />
                </Grid>

                <Grid item>
                <Grid container spacing={2}>
                    {months.map((month) => (
                        <Grid item xs={8} md={6} lg={4}  key={month}>
                            <TextField
                                id={month}
                                {...register(month)}
                                key={month}
                                label={month}
                                type={'number'}
                                onKeyDown={ formatNumberInput }
                                disabled={ inventoryInfoForm.sellOutOfState === false}
                                onChange={(e) => onChangeMonthValue(month, e.target.value)}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                value={setValueToMonthFields(month)}
                            />
                        </Grid>
                    ))}
                </Grid>
                </Grid>

                <Grid item xs={12} className={classes.formInputs}>
                    <TextField
                        required= {sellOutOfState ==='Yes'}
                        disabled={ inventoryInfoForm.sellOutOfState === false}
                        id="percentOfGoodsOutState"
                        label="Enter Percentage of Sales to Customers Out-of-State"
                        variant="outlined"
                        type={'number'}
                        InputProps={{ inputProps: { min: 1, max: 100 } }}
                        onKeyDown={ formatNumberInput }
                        InputLabelProps={{ required: true }}
                        className={classes.fieldContainer}
                        {...register('percentOfGoodsOutState', 
                            {
                                required: 'Required Percentage of sales',
                                onChange: (e) => { setInventoryInfoForm({...inventoryInfoForm, percentOfGoodsOutState : e.target.value })},
                                min: { value: 1, message: "Value must be greater than 0" },
                                max: { value: 100, message: "Value must be less than 100"   }
                            }
                        )}
                        value={inventoryInfoForm.percentOfGoodsOutState}
                        error={errors.percentOfGoodsOutState}
                        helperText = {errors.percentOfGoodsOutState ? errors.percentOfGoodsOutState.message : ''}
                    />
                </Grid>
                <Grid item xs={12} className={classes.formInputs}>
                    <TextField
                        required= {sellOutOfState ==='Yes'}
                        disabled={ inventoryInfoForm.sellOutOfState === false}
                        id="totCostShippedOutState"
                        label="Total Cost of Goods Out-of-State"
                        variant="outlined"
                        type={'number'}
                        InputLabelProps={{ required: true }}
                        onKeyDown={ formatNumberInput }
                        className={classes.fieldContainer}
                        {...register('totCostShippedOutState',{
                            required: 'Require Total cost of goods', 
                            onChange: (e) => { setInventoryInfoForm({ ...inventoryInfoForm, totCostShippedOutState: e.target.value }) },
                            min: { value: 1, message: "Value must be greater than 0" }
                        })}
                        value={inventoryInfoForm.totCostShippedOutState}
                        error={errors.totCostShippedOutState}
                        helperText = {errors.totCostShippedOutState ? errors.totCostShippedOutState.message : ''}
                    />
                </Grid> </>}
                <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2} style={{paddingTop: '20px'}}>
                    <Grid item>
                        <Fab color="primary" >
                                  <ExpandMoreIcon onClick={() => { handleFormSections(thirdSection, 'thirdSection')}}/>
                        </Fab>
                        <Typography> Continue </Typography>
                    </Grid>
                </Grid>
                </>}
                {thirdFormSection && inventoryInfoForm.invForResale === true && <><Grid item xs={12} ref={thirdSection}>
                    <FormLabel  component="legend">Will portions of this inventory be Transported Out-of-State this year ?</FormLabel>
                        <RadioGroup
                            row
                            name="invTransOutStateThisYear"
                            defaultValue='No'
                            {...register('invTransOutStateThisYear')}
                            onChange={e => {
                                setInventoryInfoForm({...inventoryInfoForm, invTransOutStateThisYear: (e.target.value === 'Yes') ? true: false})
                            }}
                            value = {inventoryInfoForm.invTransOutStateThisYear === true ? "Yes": "No"}
                        >
                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                        </RadioGroup>
                </Grid>
                <Grid item xs={12} >
                    <FormLabel component="legend">Were portions of Your Inventory Out-of-State During Last Year ? </FormLabel>
                        <RadioGroup
                            row
                            name="portionOfInvTransOutStateLastYear"
                            defaultValue={"No"}
                            {...register('portionOfInvTransOutStateLastYear')}
                            onChange={e => {
                                setInventoryInfoForm({ ...inventoryInfoForm, portionOfInvTransOutStateLastYear: (e.target.value === 'Yes') ? true : false });

                            }}
                            value = {inventoryInfoForm.portionOfInvTransOutStateLastYear === true ?  "Yes": "No"}
                        >
                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                        </RadioGroup>
                </Grid>

                {inventoryInfoForm.portionOfInvTransOutStateLastYear && <><Grid item xs={12} className={classes.formInputs}>
                    <TextField
                        required={portionOfInvTransOutStateLastYear === 'Yes'}
                        disabled={inventoryInfoForm.portionOfInvTransOutStateLastYear === false}
                        id="noOfMonthInvOutOfState"
                        label="If Inventory Was Transported for Only Part of last Year, Enter the number of Months Inventory was Shipped Out-of-State Last Year"
                        variant="outlined"
                        type={'number'}
                        InputProps={{ inputProps: { min: 1, max: 12 } }}
                        InputLabelProps= {{required: true}}
                        onKeyDown={ formatNumberInput }
                        className={classes.fieldContainer}
                        {...register('noOfMonthInvOutOfState',{
                            required:  'Require Number of months inventory was shipped out of state', 
                            onChange: (e) => { setInventoryInfoForm({...inventoryInfoForm, noOfMonthInvOutOfState : e.target.value })},
                            min: 1 ,
                            max:{ value: 12 , message: "Month value cannot be greater than 12"}
                        })}
                        value={inventoryInfoForm.noOfMonthInvOutOfState}
                        error={errors.noOfMonthInvOutOfState}
                        helperText = {errors.noOfMonthInvOutOfState ? errors.noOfMonthInvOutOfState.message : ''}
                    />
                </Grid>

                <Grid item xs={12} className={classes.formInputs}>
                    <InputLabel id="-label" style={{textAlign:'left'}} required={inventoryInfoForm.portionOfInvTransOutStateLastYear === true} >Select ALL Types of Records To Support The Amounts Given</InputLabel>
                    <Select
                        disabled={ inventoryInfoForm.portionOfInvTransOutStateLastYear === false}
                        id="recordTypes"
                        multiple
                        {...register('recordTypes',{
                            required:  'Require record types', 
                            onChange: (e) => {
                                const {
                                    target: { value },
                                    } = e;
                                    setOptionName(
                                    typeof value === 'string' ? value.split(',') : value,
                                );
                                setInventoryInfoForm({ ...inventoryInfoForm, recordTypes: value }); 
                            }
                        })}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => selected.join(', ')}
                        error={errors.recordTypes}
                        // helperText = {errors.recordTypes ? errors.recordTypes.message : ''}
                        className={classes.fieldContainer}
                        value={inventoryInfoForm.recordTypes===undefined ? [] : inventoryInfoForm.recordTypes}
                        >
                        {options.map((option) => (
                            <MenuItem key={option} value={option}>
                            <Checkbox checked={optionName.indexOf(option) > -1} />
                            <ListItemText primary={option} />
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText error>{errors.recordTypes ? errors.recordTypes.message : ''}</FormHelperText>
                </Grid></>}
            
                <Grid item xs={12}>
                <FormLabel component="legend">Will the Percentage of Goods Transported Out-of-State This year be Significantly Different From Last Year?  </FormLabel>
                    <RadioGroup
                        row
                        name="percGoodsSigDiff"
                        defaultValue={"No"}
                        {...register('percGoodsSigDiff')}
                        onChange={e => {
                            setInventoryInfoForm({ ...inventoryInfoForm, percGoodsSigDiff: (e.target.value === 'Yes') ? true : false });
                        }}
                        value = {inventoryInfoForm.percGoodsSigDiff === true ? "Yes" : "No"}
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>
                {inventoryInfoForm.percGoodsSigDiff && <><Grid item xs={12}>
                    <TextField
                        disabled={ inventoryInfoForm.percGoodsSigDiff === false}
                        required={percGoodsSigDiff === 'Yes'}
                        label='Explain Why'
                        id='percGoodsSigDiffWhy'
                        multiline
                        rows={4}
                        InputLabelProps={{required:true}}
                        className={classes.fieldContainer}
                        inputProps={{maxLength:100}}
                        {...register('percGoodsSigDiffWhy',{
                            required: 'Required field', 
                            onChange: (e) => { setInventoryInfoForm({...inventoryInfoForm, percGoodsSigDiffWhy : e.target.value })}
                        })}
                        value={inventoryInfoForm.percGoodsSigDiffWhy}
                        error={errors.percGoodsSigDiffWhy}
                        helperText = {errors.percGoodsSigDiffWhy ? errors.percGoodsSigDiffWhy.message : ''}
                    />
                </Grid></>}</>}
            <input type="submit" ref={nextButton} style={{ display: 'none' }} onClick={handleSubmit((data) => {onSubmit(data,'Rendition')})}/>
            <input type="submit" ref={saveButton} style={{ display: 'none' }} onClick={handleSubmit((data) => {onSubmit(data,'InventoryInfo')})}/>
        </Grid>
    </form>
    </Box>
  );
}
