import React, { useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import { Grid , Box, Button,Typography,Divider,Modal, Backdrop, Fade,LinearProgress} from '@mui/material';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme,ThemeProvider} from '@mui/material/styles';
import { standardButtons } from '../../../styles/mainPagesCSS';
import { useSelector } from "react-redux";
import { submitLeasedAssetsDetails, updateLeasedAssetsDetails, verifyLeasedAssetsZipCode} from "../../../redux/actions/LeasedAsestAction";
import { getLeasedAssetPayload,getLeasedAssetsAddressSelection,getLoadedCity, getLoadedState } from "../selectors/LeasedAssetSelectors";
import { getStateValue, getCityValue } from '../AppClientSelectors';
import { validateNumericFormat } from '../../../util/utilities';
import { getLocationInfo } from '../selectors/LocationInfoSelector';
import { getTaxFillingData } from '../selectors/TaxFilingPlanSelector';
import { getLeasedAssetsModalState, getIsLoadingStateCity } from "../selectors/LeasedAssetSelectors";
import AddressSuggestionModal from "../../addressSuggestion/AddressSuggestionModal";
import { appServiceData as getAppServiceData } from '../selectors/AppServiceInfoSelector';
import { setAppServiceInfo } from '../../../redux/actions/AppServiceInfoAction';
import { formatNumberInput } from '../../../util/utilities';


const useStyles = makeStyles((theme) => ({
    fieldContainer: {
      width:'80vw',
      margin:1,
      [theme.breakpoints.down("sm")]: {
        width: '45vw',
        left: '20%'
      },
    },
    buttonStyle: {
        [theme.breakpoints.down("md")]: {
          position:'relative',
          left: '50%',
        }
      }
  }));


export default function NewLeasedAssetForm({closeAction,updateAsset}) {
    const classes = useStyles();
   
    const dispatch = useDispatch();
    const loadedCity = useSelector(getLoadedCity);
    const loadedState = useSelector(getLoadedState);
    const leasedAssetInitPayload = useSelector(getLeasedAssetPayload);
    const isloadedStateCity = useSelector(getIsLoadingStateCity);
    
   
    const { register, setValue, handleSubmit, formState: { errors } } = useForm();
    const stateValues = useSelector(getStateValue);
    const cityValues = useSelector(getCityValue);
    const appServiceData = useSelector(getAppServiceData);
   

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const buttonDirection = matches? "column":"row";
    let updateFlag = updateAsset;
    const locationInfo = useSelector(getLocationInfo);
    const taxFilingPlanData = useSelector(getTaxFillingData);
    const addressModalState = useSelector(getLeasedAssetsModalState);
    const addressSelected = useSelector(getLeasedAssetsAddressSelection);
    const [selectionFlag, setSelectionFlag] = useState(false);
    const [leaseStartDate, setLeaseStartDate] = useState(new Date());

    useEffect(()=> {
        dispatch(setAppServiceInfo(0,null,false,false));
    },[dispatch]);

    useEffect(() => {
        if(!appServiceData.isError && appServiceData.isPageSubmitted) {
            closeAction();
        }
    },[appServiceData]);

    useEffect(() => {
        if(leasedAssetInitPayload && updateAsset) {
            setValue('leasedAssetDescription',leasedAssetInitPayload.leasedAssetDescription);
            setValue('leasedNumber',leasedAssetInitPayload.leasedNumber);
            setValue('leasingCompanyName',leasedAssetInitPayload.leasingCompanyName);
            setValue('leaseStartDate',leasedAssetInitPayload.leaseStartDate);
            setValue('leaseEndDate',leasedAssetInitPayload.leaseEndDate);
            setValue('leaseCompMailAddr',leasedAssetInitPayload.leaseCompMailAddr);
            setValue('zip',leasedAssetInitPayload.zip);
            setValue('city',leasedAssetInitPayload.city);
            setValue('state',leasedAssetInitPayload.state);
        }
       

    },[leasedAssetInitPayload,updateAsset]);
    
    useEffect(() => {
        if (addressSelected) {
            setValue('leaseCompMailAddr', leasedAssetInitPayload.leaseCompMailAddr);
        }

    },[addressSelected, leasedAssetInitPayload]);

   
 

    const getCityId = (cityName) => {
        for (let value of cityValues) {
            if (value.codeValueKey === cityName) {
                return value.id
            }
        }  
    }

    const getStateId = (stateName) => {
        for(let value of stateValues){
            if(value.codeValueKey === stateName){
                return value.id
            }
        }
        
    }

    const getStateDisplayValue = (loadedState) => {
        let value;
        stateValues.forEach((ele) => {
            if (ele.id === loadedState) {
                value = ele.codeValueKey
            }
        });
        if(value === undefined) {
            value = loadedState;
        }
        return value;
    }

    const getCityDisplayValue = (loadedCity) => {
        let value;
        cityValues.forEach((ele) => {
            if (ele.id === loadedCity) {
                value = ele.codeValueKey
            }
        });
        if(value === undefined) {
            value = loadedCity;
        }
        return value;
    }

    const fetchCityStateValue = (val) => {
        if(validateNumericFormat(val)){
            if(val.length === 5){
                dispatch(verifyLeasedAssetsZipCode(val));
              
            }
        }
    }

    useEffect(() => {
        if (addressSelected) {
            setSelectionFlag(true);
        }
    },[addressSelected])


    const onSubmit = data => {
        //TODO: here we invoke calls using action creators and on successful service call,we store data in redux store
        console.log(data);
        let locationInfoId = null,taxFilingPlanId = null;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
            }
        }
        if(locationInfoId == null || locationInfoId === undefined){
            locationInfoId = locationInfo != null ? locationInfo.id:'';
        }
        if(taxFilingPlanId == null || taxFilingPlanId === undefined){
            taxFilingPlanId = locationInfo != null ? locationInfo.taxInfoId:'';
        }
        if (updateFlag) {
            dispatch(updateLeasedAssetsDetails({...data,
                    city:getCityId(loadedCity),state:getStateId(loadedState),
                    locationInfoId:locationInfoId,taxFilingPlanId:taxFilingPlanId,leasedAssetId:leasedAssetInitPayload.id},true)) 
            closeAction();
        } else {
           
                dispatch(submitLeasedAssetsDetails({...data,
                    state: Number.isInteger(loadedState) ? loadedState : getStateId(loadedState),
                    city: Number.isInteger(loadedCity) ? loadedCity : getCityId(loadedCity), 
                    locationInfoId:locationInfoId,taxFilingPlanId:taxFilingPlanId},true,closeAction));
                 
        }
    }
    return ( 
        <Box style={{position:'relative',left:'2%',bottom:'1%',textAlign:'justify',minHeight:'350px',width:'100%'}}>
            <form onSubmit={handleSubmit(onSubmit)} >
            <Box>
        <Grid container>
            <Modal
                open={addressModalState}
                hideBackdrop
                disableEscapeKeyDown={true}
                aria-labelledby="app-modal"
                aria-describedby="app-modal-client"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
                disableScrollLock={true}
                className={classes.modalRoot}
            >
                <Fade in={addressModalState}>
                    <Box>
                        <AddressSuggestionModal/>
                    </Box>
                </Fade>
            </Modal>
        </Grid>
    </Box>
                <Grid container direction="column">
                    <Grid item xs={6} sx={{ m: 1 }}>
                        <h1 style={{textAlign:'center'}}>Add Asset
                            <Typography className={classes.loginTitle} style={{ float: 'right' }}>
                                <span style={{color: 'red'}}>*</span>  Required fields 
                            </Typography><Divider />
                        </h1> 
                    </Grid>
                    <Grid item xs={12} sx={{ m: 1 }}>
                        <TextField id="leasedAssetDescription" label="Asset Description" 
                        InputLabelProps={{ shrink: true, required: true }} variant="outlined"
                         {...register('leasedAssetDescription', 
                         { required: 'Please enter Leased Asset description', maxLength: 30 })}
                        className={classes.fieldContainer}
                        helperText={errors.leasedAssetDescription ? errors.leasedAssetDescription.message : ""}
                        error={errors.leasedAssetDescription} />
                    </Grid>
                    <Grid item xs={12} sx={{ m: 1 }}>
                        <TextField id="leasedNumber" type="number" label="Asset Number" 
                        InputLabelProps={{ shrink: true, required: true }} variant="outlined"
                         {...register('leasedNumber', { required: 'Please enter asset number', maxLength: 30 })}
                         helperText={errors.leasedNumber ? errors.leasedNumber.message : ""}
                         onKeyDown={ formatNumberInput }
                         error={errors.leasedNumber}
                        className={classes.fieldContainer}/>
                    </Grid>
                    <Grid item xs={12} sx={{ m: 1 }}>
                        <TextField id="leasingCompanyName" label="Leasing Company Name" 
                        InputLabelProps={{ shrink: true, required: true }} variant="outlined" 
                        {...register('leasingCompanyName', { required: 'Please enter Leasing Company name', maxLength: 30 })}
                        helperText={errors.leasingCompanyName ? errors.leasingCompanyName.message : ""}
                        error={errors.leasingCompanyName}
                        className={classes.fieldContainer}/>
                    </Grid>
                    <Grid item xs={12} sx={{ m: 1}}>
                        <TextField
                            
                            id="leaseStartDate"
                            type='date'
                            label='Lease Start Date'
                            variant="outlined"
                            className={classes.fieldContainer}
                            InputLabelProps={{ shrink: true, required: true }}
                            {...register('leaseStartDate', { required: 'Please enter Leasing start date',
                             min: Date.now(),
                             onChange: (e) => {setLeaseStartDate(e.target.value);} })}
                            inputProps={{ min: Date.now() }}
                            helperText={errors.leaseStartDate ? errors.leaseStartDate.message : ""}
                            error={errors.leaseStartDate}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ m: 1}}>
                        <TextField
                            id="leaseEndDate"
                            type='date'
                            label='Lease End Date'
                            variant="outlined"
                            className={classes.fieldContainer}
                            InputLabelProps={{ shrink: true,  required: true}}
                            {...register('leaseEndDate', { required: 'Please enter Leasing end date', maxLength: 30,
                            validate: value => { 

                                console.log('leased end date is '+leaseStartDate);
                               
                                if(value > leaseStartDate) {
                                    return true 
                                } else {
                                    return 'Please select valid Lease end date'
                                }
                            }, })}
                            helperText={errors.leaseEndDate ? errors.leaseEndDate.message : ""}
                            error={errors.leaseEndDate}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ m: 1 }}>
                        <TextField id="leasingCompMailingAddress" label="Leasing Company Mailing Address" 
                        InputLabelProps={{ shrink: true, required: true }} variant="outlined" 
                        {...register('leaseCompMailAddr', { required: 'Please enter Company Mailing address', maxLength: 30 })}
                        className={classes.fieldContainer}
                        helperText={errors.leaseCompMailAddr ? errors.leaseCompMailAddr.message : ""}
                        error={errors.leaseCompMailAddr}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ m: 1 }}>
                        <TextField id="zip" label="Zip" type="number" variant="outlined" {...register('zip', 
                        { required: 'please enter zip code',  maxLength: { value: 5, message: 'Only 5 characters are allowed' } })} InputLabelProps={{ shrink: true,  required: true }}
                        className={classes.fieldContainer} onChange={(e) => {
                            setValue('zip',e.target.value);
                            fetchCityStateValue(e.target.value);
                            setValue('city',getCityDisplayValue(loadedCity));
                            setValue('state',getStateDisplayValue(loadedState));
                            }
                        }
                        onKeyDown={ formatNumberInput }
                        helperText={errors.zip ? errors.zip.message : ""}
                        error={errors.zip}
                        />
                    </Grid>
                    {isloadedStateCity && <Grid item>
                         <LinearProgress />
                    </Grid>}
                    <Grid item xs={12} sx={{ m: 1 }}>
                        <TextField id="city" label="City" variant="outlined" InputLabelProps={{ shrink: true }} {...register('city', { maxLength: 30 })}
                        className={classes.fieldContainer}  value={getCityDisplayValue(loadedCity)}
                        helperText={errors.city ? errors.city.message : ""}
                        error={errors.city} disabled
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ m: 1 }}>
                        <TextField id="state" label="State" variant="outlined" InputLabelProps={{ shrink: true }} {...register('state', {  maxLength: 30 })}
                        className={classes.fieldContainer} value={getStateDisplayValue(loadedState)}
                        helperText={errors.state ? errors.state.message : ""}
                        error={errors.state} disabled
                        />
                    </Grid>
                    
                </Grid>
                <ThemeProvider theme={standardButtons}>
                    <Stack spacing={2} style={{ width:'100%',marginTop: '50px' }} direction={buttonDirection} justifyContent="center" className={classes.buttonStyle}>
                        <Button variant="contained" size="medium" onClick={()=> closeAction()} sx={{ mr: 1 , width: '200px',height:'50px' }}>
                        Back
                        </Button>
                        <Button  variant="contained" size="medium" sx={{ mr: 1 , width: '200px',height:'50px' }} type="submit">
                            Save Asset
                        </Button>
                    </Stack>
                </ThemeProvider>
            </form>
        </Box>
    );
}