import { setClientDetails, setModalState, setProfileStatus } from "../../appClient/AppClientActions";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { uspsvalidator } from "../../../util/uspsConfig";
import { push } from 'connected-react-router';
import { OPERATING_STATES } from "../../../constants/operatingStates";
import { svcRequest } from "../../../util/requestHelper";
import config from "../../../config/context-root-configs";
import { SET_PROFILE_STATUS } from '../../appClient/AppClientActions';

export const SET_PROFILE_DETAILS = "SET_PROFILE_DETAILS";
export const SET_PROFILE_DETAILS_SUCCESS = "SET_PROFILE_DETAILS_SUCCESS";
export const SET_PROFILE_DETAILS_ERROR = "SET_PROFILE_DETAILS_ERROR";

export const GET_PROFILE_DETAILS = "GET_PROFILE_DETAILS";
export const GET_PROFILE_DETAILS_SUCCESS = "GET_PROFILE_DETAILS_SUCCESS";
export const GET_PROFILE_DETAILS_ERROR = "GET_PROFILE_DETAILS_ERROR";

export const FETCH_PROFILE_CITY_STATE = 'FETCH_CITY_STATE';
export const FETCH_PROFILE_CITY_STATE_SUCCESS = 'FETCH_PROFILE_CITY_STATE_SUCCESS';
export const FETCH_PROFILE_CITY_STATE_FAILURE = 'FETCH_PROFILE_CITY_STATE_FAILURE';

export const SET_PARTIAL_PROFILEINFO_SUCCESS = 'SET_PARTIAL_PROFILEINFO_SUCCESS';
export const SET_USERPROFILE_ADDRESS_INFO = 'SET_USERPROFILE_ADDRESS_INFO';
export const SET_ADDRESS_MODAL_STATE = 'SET_ADDRESS_MODAL_STATE';

export const SET_ADDRESS_SELECTED_FLAG = 'SET_ADDRESS_SELECTED_FLAG';

export const submitProfileDetails = (payload) => {
    console.log("action payload -")
    console.log(payload)
    const clientAddress = {
        city: payload.city,
        line1: payload.addressLine1,
        line2: payload.addressLine2,
        state: payload.state,
        zipcode: payload.zip
    }
    return (dispatch,getState) => {
        if (getState().userProfile.addressSelected) {
            postUserDetails(dispatch,payload,getState,clientAddress);
           
        } else {
            dispatch(verifyUserProfileAddress(dispatch,payload,getState,clientAddress, false));
        }
    }
}

const postUserDetails = (dispatch,payload,getState,clientAddress) => {
    dispatch({ type: SET_PROFILE_DETAILS_SUCCESS, payload });
                
    postUserInfo({
        clientName: payload.name, emailId: payload.email, userId: payload.userId,
        phoneNumber: payload.phone, address: clientAddress
    }).then(res => {
        if (res && res.status === 200 && res.data) {
            let clientDetails = getState().appClientCtx.clientDetails;
            dispatch(setClientDetails({ ...clientDetails,firstName: payload.name, email: payload.email, userId: res.data.userId }));
            dispatch({ type: SET_PROFILE_DETAILS_SUCCESS, payload: { ...payload, id: res.data.id } });
            dispatch(setProfileStatus(true));
            dispatch(push('/appClientHome'));
            dispatch(setModalState(false));
        }

    }).catch(err => {
        if (err.response && err.response.status === 422) {
            dispatch({ type: SET_PROFILE_DETAILS_ERROR, payload: err.response.data.errors });
            NotificationManager.error(`Invalid Client Details!`);
            dispatch(push("/"));
        }
        else if (err.response && err.response.status === 403) {
            dispatch({ type: SET_PROFILE_DETAILS_ERROR, payload: err.response.data.error });
            NotificationManager.error(`${err.response.data.error}. 
            Please check your registered mail inbox to verify your email ID`);
            dispatch(push("/"));
        }
        else if (err.response && err.response.status === 401) {
            dispatch({ type: SET_PROFILE_DETAILS_ERROR, payload: err.response.data.error });
            NotificationManager.error(`${err.response.data.error}. Wrong password!`);
            dispatch(push("/"));
        }
        else if (err.response && err.response.status === 404) {
            dispatch({ type: SET_PROFILE_DETAILS_ERROR, payload: err.response.data.error });
            NotificationManager.error(err.response.data.error);
            dispatch(push("/"));
        }
        else {
            let errors = '';
            if (err && err.response && err.response.data) {
                errors = err.response.data.errors;
            }
            dispatch({ type: SET_PROFILE_DETAILS_ERROR, payload: errors });
            NotificationManager.error(`Internal Server error - please try again later!`);
            dispatch(push("/"));
        }
    });
}

export const verifyProfileZipCode = (zip) => {
    return (dispatch) => {
        dispatch({ type: FETCH_PROFILE_CITY_STATE, payload: zip});
        setTimeout( () => {
            // call to fetch the city and state values from zipcode
            uspsvalidator.cityStateLookup(zip).then((res) => {
                if(res.State && res.City){
                    if (OPERATING_STATES.includes(res.State)) {
                        dispatch({ type: FETCH_PROFILE_CITY_STATE_SUCCESS, payload: res});
                    }
                    else {
                        dispatch({ type: FETCH_PROFILE_CITY_STATE_FAILURE, payload: res });
                        NotificationManager.error("We are not serving this area at this particular moment.")
                    }
                    
                }
                else{
                    dispatch({ type: FETCH_PROFILE_CITY_STATE_FAILURE, payload: res});
                    NotificationManager.error(res.message);
                }
            }).catch((err) => {
                dispatch({ type: FETCH_PROFILE_CITY_STATE_FAILURE, payload: err});
            })
        }, 2000 )
    }
}
//Post ClientInfo
export const postUserInfo = (payload) => {
    return svcRequest.post(config.clientInfo, payload, {
        timeout: 30000
      }
    );
}

//GET getAgentInfo
export const getUserInfo = (userId) => {
    return dispatch => {
        svcRequest.get(`${config.clientInfo}${userId}`, {
            timeout: 30000
        }
        ).then(response => {
            if (response.data && response.data.address) {
                let data = response.data;
                let parsedData = {
                    addressLine1: data.address.line1,
                    addressLine2: data.address.line2,
                    city: data.address.city,
                    email: data.emailId,
                    id: data.id,
                    name: data.clientName,
                    phone: data.phoneNumber,
                    state: data.address.state,
                    userId: data.userId,
                    zip: data.address.zipcode
                }
                dispatch({type: GET_PROFILE_DETAILS_SUCCESS,payload: parsedData});
                if(response.data && Object.keys(response.data).length !== 0){
                 dispatch({type: SET_PROFILE_STATUS,payload: true});
                } 
            }
        });

       
    }
}

export const submitProfileDetailsForUpdate = (payload) => {
    const clientAddress = {
        city: payload.city,
        line1: payload.addressLine1,
        line2: payload.addressLine2,
        state: payload.state,
        zipcode: payload.zip
    }
    return (dispatch,getState) => {
        if (getState().userProfile.addressSelected) {
            dispatch(updateProfileInfo(payload));
        } else {
            dispatch(verifyUserProfileAddress(dispatch,payload,getState,clientAddress, true));
        }
    }
}

export const patchUserInfo = ( payload) => {
    return svcRequest.patch(config.clientInfo+payload.userId, payload, {
        timeout: 30000
      }
    );
}

export const updateProfileInfo = (payload) => {
    return (dispatch) => {
    const address = {
        city: payload.city,
        line1: payload.addressLine1,
        line2: payload.addressLine2,
        state: payload.state,
        zipcode: payload.zip
    }
    let updatePayload = {
        clientName: payload.name, 
        phoneNumber: payload.phone,
        emailId: payload.email,
        userId: payload.userId,
        address
    }
    patchUserInfo(updatePayload).then((response) => {
        if (response.status === 200) {
            dispatch(push("/appClientHome"));
       }
    }).catch((error)=>{NotificationManager.error(error.message)})
   }
}

export const verifyUserProfileAddress = (dispatch,payload,getState,clientAddress,updateFlag) => {
    console.log("verify")
    console.log(payload)
    const addressObj = {
        Address1: payload.addressLine1,
        Address2: payload.addressLine2,
        City: payload.city,
        State: payload.state,
        Zip5: payload.zip   
    }
    return (dispatch, getState) => {
        uspsvalidator.verify(addressObj).then((response) => {
            let line1 = false;
            let line2 = false;
            if (response.Address1 !== undefined) {
                if (response.Address1 === addressObj.Address1) {
                    line1 = true;
                }
            }
            if (response.Address2 !== undefined) {
                if (response.Address2 === addressObj.Address2) {
                    line2 = true;
                }
            }
            if (line1 || line2) {
                updateFlag === true ? updateProfileInfo(payload) : postUserDetails(dispatch,payload,getState,clientAddress);
               
            } else {
                let addressPayload = {
                    ...payload, 
                    uspsAddress: response
                }
                dispatch({ type: SET_PARTIAL_PROFILEINFO_SUCCESS, payload: addressPayload });
                dispatch(setAddressPopUpModalState(true))

            }
        }).catch((err)=>NotificationManager.error(err.message))
    }
}

export const setAddressForUser = (payload, addressType) => {
    return (dispatch) => {
        let addressPayload = '';
        if (addressType === 'userAddress') {
            addressPayload = {
                addressLine1: payload.addressLine1,
                addressLine2: payload.addressLine2,
                city: payload.city,
                state: payload.state
            }
        } else {
            addressPayload = {
                addressLine1: payload.Address1,
                addressLine2: payload.Address2 === undefined ? '' : payload.Address2,
                city: payload.City, 
                state: payload.State
            }
        }   
        dispatch({ type: SET_USERPROFILE_ADDRESS_INFO, payload: addressPayload });
        window.location.reload();
        dispatch(setAddressPopUpModalState(false));
    }
}

export const setAddressPopUpModalState = (flag) => {
    return(dispatch) => {
        dispatch({ type: SET_ADDRESS_MODAL_STATE, payload: flag});
    }
}

//Post ClientInfo
export const postClientInfo = (payload) => {
    return svcRequest.post(config.clientInfo, payload, {
        timeout: 30000
      }
    );
}

export const setAddressSelectedFlag = (selectionFlag) => {
    return (dispatch) => {
        dispatch({type: SET_ADDRESS_SELECTED_FLAG, payload: selectionFlag })
    }
}