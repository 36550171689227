export const colHeaders = [
    {
      id: 'assetDescription',
      numeric: false,
      disablePadding: true,
      label: 'Description',
    },
    {
      id: 'assetNumber',
      numeric: true,
      disablePadding: false,
      label: 'Asset Number',
    },
    {
      id: 'assetType',
      numeric: false,
      disablePadding: false,
      label: 'Asset type',
    },
    {
      id: 'dateAcquired',
      numeric: false,
      disablePadding: false,
      label: 'Date Acquired (MM/DD/YYYY)',
    },
    {
      id: 'originalPurchasePrice',
      numeric: true,
      disablePadding: false,
      label: 'Original Purchase price ($)',
    },
    {
      id: 'quantity',
      numeric: true,
      disablePadding: false,
      label: 'Quantity',
    },
    {
      id: 'costCenter',
      numeric: true,
      disablePadding: false,
      label: 'CostCenter',
    },
  ];

  //TO DO- fetch data
  export const rowData = [
      {assetDescription : 'Test Formatter 1', assetNumber: 2345, 
      assetType: 'Furniture', dateAcquired: '11/16/2020', originalPurchasePrice: 500, 
      quantity: 1, costCenter: 5},
      {assetDescription : 'Test Formatter 2', assetNumber: 2346, 
      assetType: 'Furniture', dateAcquired: '12/16/2020', originalPurchasePrice: 756, 
      quantity: 1, costCenter: 5},
      {assetDescription : 'Test Formatter 3', assetNumber: 2347, 
      assetType: 'Furniture', dateAcquired: '11/20/2020', originalPurchasePrice: 1200, 
      quantity: 1, costCenter: 5},
      {assetDescription : 'Test Formatter 4', assetNumber: 2348, 
      assetType: 'Furniture', dateAcquired: '01/25/2021', originalPurchasePrice: 690, 
      quantity: 1, costCenter: 5},
      {assetDescription : 'Test Formatter 5', assetNumber: 2349, 
      assetType: 'Furniture', dateAcquired: '01/30/2021', originalPurchasePrice: 100, 
      quantity: 1, costCenter: 5}
  ]