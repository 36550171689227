import { setProfileStatus, setClientDetails, setModalState } from "../../appClient/AppClientActions";
import { uspsvalidator } from "../../../util/uspsConfig";
import { NotificationManager } from 'react-notifications';
import { push } from "connected-react-router";
import { OPERATING_STATES } from "../../../constants/operatingStates";
import { svcRequest } from "../../../util/requestHelper";
import config from "../../../config/context-root-configs";
import { SET_PROFILE_STATUS } from '../../appClient/AppClientActions';
import { setAddressPopUpModalState } from "../userProfile/UserProfileActions";

export const SET_AGENT_PROFILE_DETAILS = "SET_AGENT_PROFILE_DETAILS";
export const SET_AGENT_PROFILE_DETAILS_SUCCESS = "SET_AGENT_PROFILE_DETAILS_SUCCESS";
export const SET_AGENT_PROFILE_DETAILS_ERROR = "SET_AGENT_PROFILE_DETAILS_ERROR";

export const GET_AGENT_PROFILE_DETAILS = "GET_AGENT_PROFILE_DETAILS";
export const GET_AGENT_PROFILE_DETAILS_SUCCESS = "GET_AGENT_PROFILE_DETAILS_SUCCESS";
export const GET_AGENT_PROFILE_DETAILS_ERROR = "GET_AGENT_PROFILE_DETAILS_ERROR";


export const FETCH_CITY_STATE = 'FETCH_CITY_STATE';
export const FETCH_CITY_STATE_SUCCESS = 'FETCH_CITY_STATE_SUCCESS';
export const FETCH_CITY_STATE_FAILURE = 'FETCH_CITY_STATE_FAILURE';

export const SET_PARTIAL_AGENTPROFILEINFO_SUCCESS = 'SET_PARTIAL_AGENTPROFILEINFO_SUCCESS';
export const SET_AGENTPROFILE_ADDRESS_INFO = 'SET_AGENTPROFILE_ADDRESS_INFO';

export const SET_AGENT_PROFILE_UPDATE_SUCCESS = 'SET_AGENT_PROFILE_UPDATE_SUCCESS';
export const SET_AGENT_ADDRESS_SELECTED_FLAG = 'SET_AGENT_ADDRESS_SELECTED_FLAG';

export const submitAgentProfileDetails = (payload) => {
    
    

    return (dispatch, getState) => {
        if (getState().agentProfile.addressSelected) {
            postAgentDetails(dispatch,payload,getState);
        } else {
            dispatch(verifyAgentProfileAddress(payload, false));
        }              
    }
}

export const postAgentDetails = (dispatch,payload,getState) => {
    const agentAddress = {
        city: payload.cityValue,
        line1: payload.address.line1,
        state: payload.stateValue,
        zipcode: payload.address.zipcode
    }
    let codesPayload = [];
    if(payload.codes) {payload.codes.forEach((item) => {
        let eachCode = { 'agentCountyCode': item };
        codesPayload.push(eachCode);
    })
    }
    postAgentInfo({agentName:payload.name,emailId:payload.email,agentCompanyName:payload.agentCompName,
        userId:payload.userId,
        phoneNumber:payload.phoneNum,address:agentAddress,tdlr:'troy',agentCountyCodes:codesPayload}).then( res => {
            if(res && res.status === 200 && res.data){
                let clientDetails = getState().appClientCtx.clientDetails;
                dispatch(setClientDetails({...clientDetails,firstName: payload.name, email: payload.email, userId: res.data.userId}));
                    dispatch({type: SET_AGENT_PROFILE_DETAILS_SUCCESS,payload: {...payload,id:res.data.id}});
                dispatch(setProfileStatus(true));
                dispatch(push('/appClientHome'));
            }

        }).catch( err => {
            if(err.response && err.response.status === 422){
                dispatch({type: SET_AGENT_PROFILE_DETAILS_ERROR, payload: err.response.data.errors});
                NotificationManager.error(`Invalid Profile Details!`);
                dispatch(push("/"));
            }
            else if(err.response && err.response.status === 403 ){
                dispatch({type: SET_AGENT_PROFILE_DETAILS_ERROR, payload: err.response.data.error});
                NotificationManager.error(`${err.response.data.error}. 
                    Please check your registered mail inbox to verify your email ID`);
                dispatch(push("/"));
            }
            else if(err.response && err.response.status === 401 ){
                dispatch({type: SET_AGENT_PROFILE_DETAILS_ERROR, payload: err.response.data.error});
                NotificationManager.error(`${err.response.data.error}. Wrong password!`);
                dispatch(push("/"));
            }
            else if(err.response && err.response.status === 404 ){
                dispatch({type: SET_AGENT_PROFILE_DETAILS_ERROR, payload: err.response.data.error});
                NotificationManager.error(err.response.data.error);
                dispatch(push("/"));
            }
            else{
                dispatch({type: SET_AGENT_PROFILE_DETAILS_ERROR, payload: err.response.data.errors});
                NotificationManager.error(`Internal Server error - please try again later!`);
                dispatch(push("/"));
            }
        });
    // dispatch(push('/appClientHome'));
}

export const verifyAgentProfileZipCode = (zip) => {
    return (dispatch) => {
        dispatch({ type: FETCH_CITY_STATE, payload: zip});
        setTimeout( () => {
            // call to fetch the city and state values from zipcode
            uspsvalidator.cityStateLookup(zip).then((res) => {
                if(res.State && res.City){
                    if (OPERATING_STATES.includes(res.State)) {
                        dispatch({ type: FETCH_CITY_STATE_SUCCESS, payload: res});  
                    }
                    else {
                        dispatch({ type: FETCH_CITY_STATE_FAILURE, payload: res });
                        NotificationManager.error("We are not serving this area at this particular moment.")
                    }
                }
                else{
                    dispatch({ type: FETCH_CITY_STATE_FAILURE, payload: res});
                    NotificationManager.error(res.message);
                }
            }).catch((err) => {
                dispatch({ type: FETCH_CITY_STATE_FAILURE, payload: err});
            })
        }, 2000 )
    }
}
//Post AgentInfo
export const postAgentInfo = (payload) => {
    return svcRequest.post(config.agentInfo, payload, {
        timeout: 30000
      }
    );
}

//GET getAgentInfo
export const getAgentInfo = (userId) => {
    return dispatch => {
        svcRequest.get(`${config.agentInfo}${userId}`, {
            timeout: 30000
        }
        ).then(response => {
            if (response.status === 200) {
                let data = response.data;
                let codesData = [];
                if (data.agentCountyCodes) {
                    data.agentCountyCodes.forEach((ele) => {
                        codesData.push(ele.agentCountyCode);
                    })
                }

                let responseData = {
                    ...data,
                    name: data.agentName,
                    phoneNum: data.phoneNumber,
                    email: data.emailId,
                    agentCompName: data.agentCompanyName,
                    codes: codesData
                }
                console.log(responseData);
                dispatch({type: GET_AGENT_PROFILE_DETAILS_SUCCESS,payload: responseData});
                if(response.data && Object.keys(response.data).length !== 0){
                    dispatch({type: SET_PROFILE_STATUS,payload: response.data});
                }else{
                    dispatch({type: SET_PROFILE_STATUS,payload: false});
                }
            } else {
                dispatch({type: SET_PROFILE_STATUS,payload: false});
            }
            
        });

        
    }
}

export const submitAgentProfileDetailsForUpdate = (payload) => {
    const agentAddress = {
        city: payload.cityValue,
        line1: payload.address.line1,
        state: payload.stateValue,
        zipcode: payload.address.zipcode
    }
    let codesPayload = [];
    if(payload.codes) {payload.codes.forEach((item) => {
        let eachCode = { 'agentCountyCode': item };
        codesPayload.push(eachCode);
    })
    }
    return (dispatch, getState) => {
        if (getState().agentProfile.addressSelected) {
            dispatch(updateAgentProfileInfo(payload))
        } else {
            dispatch(verifyAgentProfileAddress( payload, true))
        }
    }
}



export const patchAgentInfo = ( payload) => {
    return svcRequest.patch(config.agentInfo+payload.userId, payload, {
        timeout: 30000
      }
    );
}

export const updateAgentProfileInfo = (payload) => {
    return (dispatch) => {
    const address = {
        city: payload.cityValue,
        line1: payload.address.line1,
        line2: payload.address.line2,
        state: payload.stateValue,
        zipcode: payload.zip
    }
    let updatePayload = {
        agentName: payload.name, 
        phoneNumber: payload.phoneNum,
        emailId: payload.email,
        userId: payload.userId,
        address
    }
    patchAgentInfo(updatePayload).then((response) => {
        if (response.status === 200) {
            dispatch({ type: SET_AGENT_PROFILE_UPDATE_SUCCESS, payload: payload });
            dispatch(push("/appClientHome"));
       }
    }).catch((error)=>{NotificationManager.error(error.message)})
   }
}

export const verifyAgentProfileAddress = (payload, updateFlag) => {
    const addressObj = {
        Address1: payload.address.line1,
        Address2: payload.address.line2,
        City: payload.cityValue,
        State: payload.stateValue,
        Zip5: payload.address.zipcode
    }
    return (dispatch,getState) => {
    uspsvalidator.verify(addressObj).then((response) => {
        let line1 = false;
        let line2 = false;
        if (response.Address1 !== undefined) {
            if (response.Address1 === addressObj.Address1) {
                line1 = true;
            }
        }
        if (response.Address2 !== undefined) {
            if (response.Address2 === addressObj.Address2) {
                line2 = true;
            }
        }
        if (line1 || line2) {
            updateFlag === false ? postAgentDetails(dispatch,payload,getState) : updateAgentProfileInfo(payload);
        } else {
            let addressPayload = {
                ...payload, 
                uspsAddress: response
            }
            dispatch({ type: SET_PARTIAL_AGENTPROFILEINFO_SUCCESS, payload: addressPayload });
            dispatch(setAddressPopUpModalState(true))
        }
    }).catch((err)=>NotificationManager.error(err.message))
    }
    
}

export const setAddressForAgent = (payload, addressType) => {
    return (dispatch) => {
        let addressPayload = '';
        console.log(payload)
        if (addressType === 'userAddress') {
            addressPayload = {
                addressLine1: payload.address.line1,
                addressLine2: payload.address.line2,
                zip: payload.address.zipcode
            }
        } else {
            addressPayload = {
                addressLine1: payload.Address1,
                addressLine2: payload.Address2 === undefined ? '' : payload.Address2,
                zip: payload.Zip5
            }
        }   
        dispatch({ type: SET_AGENTPROFILE_ADDRESS_INFO, payload: addressPayload });
        window.location.reload();
        dispatch(setAddressPopUpModalState(false));

    }
}

export const setAgentAddressSelectedFlag = (selectionFlag) => {
    return (dispatch) => {
        dispatch({type: SET_AGENT_ADDRESS_SELECTED_FLAG, payload: selectionFlag })
    }
}