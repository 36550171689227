import { svcRequest } from "./requestHelper";
import config from "../../config/context-root-configs";



export const saveTaxInformation = (payload) => {
        return svcRequest.post(config.taxInfo,payload, {
            timeout: 30000
        }) ;
}

export const updateTaxInformation = (id,payload) => {
    return svcRequest.patch(`${config.taxInfos}/${id}`,payload, {
        timeout: 30000
    }) ;
}



export const getTaxInformationPlan = (id) => {
    return svcRequest.get(config.taxInfos+"/"+id, {
        timeout: 30000
    }) ;
}

export const getTaxInformationPlans = (directClientInfoId,agentId,expand) => {
    let id = directClientInfoId?`directClientInfoId=${directClientInfoId}&`:`agentId=${agentId}&`;
    let queryParams = `${id}&expand=${expand}`;
    return svcRequest.get(`${config.taxInfos}?${queryParams}`, {
        timeout: 30000
    }) ;
}

export const deleteTaxInformationPlan = (id) => {
    return svcRequest.delete(config.taxInfos+"/"+id, {
        timeout: 30000
    }) ;
}

