import Moment from 'moment';
import { uspsvalidator } from "../util/uspsConfig";

export function isValidBulkImportDocument(values,isAssets) {
  if(isAssets) {
    if (
      values &&
      values.length > 1 && 
      values[0] &&
      'S.No' === values[0][0] &&
      'Asset Description' === values[0][1] &&
      'Asset Number' === values[0][2] &&
      'Asset Category' === values[0][3] &&
      'Date Acquired' === values[0][4] &&
      'Original Price Purchased' === values[0][5] &&
      'Quantity' === values[0][6] &&
      'Cost Center' === values[0][7]
    ) {
      return true;
    }
    return false;
  } else {
    if (
      values &&
      values.length > 1 && 
      values[0] &&
      'S.No' === values[0][0] &&
      'Leased Asset Description' === values[0][1] &&
      'Leasing Company Name' === values[0][2] &&
      'Lease Number' === values[0][3] &&
      'Lease Start Date' === values[0][4] &&
      'Lease End Date' === values[0][5] &&
      'Address' === values[0][6] &&
      'Zip Code' === values[0][7]
    ) {
      return true;
    }
    return false;
  }
}

export function validateAssetInfo(inputValues, assetCategoryLookup,isAssets,stateValues, cityValues,taxInfoId) {
  console.log('in validate');
  console.log('inputValues', inputValues);
  console.log('assetCategoryLookup', assetCategoryLookup);
  console.log('1.1:', new Date().toLocaleString());
  const errors = [];
  
  if (inputValues.length > 1) {
    //Remove heading object
    let assets_info = inputValues.slice(1);
    let assets_info_with_errors = [];
    let formatted_assets_info = [];
    let hasErrors = false;
    let result;
    assets_info.map((asset, index) => {
      //validate each asset details and populate additional field 'error' to the same asset object
     
      const formatted_asset = {};
      asset[0] ? (formatted_asset.sno = asset[0]) : (formatted_asset.sno = index + 1);
      if(isAssets){
        !asset[1] ? errors.push('Asset Description is required') : (formatted_asset.assetDescription = asset[1]);
        !asset[2] ? errors.push('Asset Number is required') : (formatted_asset.assetNumber = asset[2]);
        if (!asset[3]) {
          errors.push('Asset Category is required');
        } else {
          const assetCategoryObj = assetCategoryLookup.filter((assetCategoryObj) => {
            return assetCategoryObj.display === asset[3];
          });
          formatted_asset.assetCategory = asset[3];
          if (!assetCategoryObj || assetCategoryObj.length === 0) {
            errors.push('Asset Category is invalid');
          } else {
            formatted_asset.assetType = assetCategoryObj[0].id;
          }
        }

        if (!asset[4]) {
          errors.push('Date Acquired is required');
        } else {
          let date;
          //if date is available then covert it and validate the value
          const convertedValue = getValidDateFromExcelDateValue(asset[4]);
          if (!Moment(convertedValue).isValid()) {
            errors.push('Date Acquired is not in a valid format');
          } else {
            
            date = new Date(Moment(convertedValue).format('l'));
          }
         
          formatted_asset.dateAcquired = new Date( date.getTime() + Math.abs(date.getTimezoneOffset()*60000) ).getTime()
        }

        if (!asset[5]) {
          errors.push('Original Price Purchased is required');
        } else if (isNaN(asset[5])) {
          errors.push('Original Price Purchased is not in a valid format');
        } else {
          formatted_asset.originalPurchasePrice = asset[5].toString();
        }

        if (!asset[6]) {
          errors.push('Quantity is required');
        } else if (isNaN(asset[6])) {
          errors.push('Quantity is not in a valid format');
        } else {
          formatted_asset.quantity = asset[6].toString();
        }

        formatted_asset.costCenter = asset[7].toString();
        formatted_asset.taxFilingPlanId = taxInfoId;
    } else {
      !asset[1] ? errors.push('Leased Asset Description is required') : (formatted_asset.leasedAssetDescription = asset[1]);
      !asset[2] ? errors.push('Leasing Asset Comapny is required') : (formatted_asset.leasingCompanyName = asset[2]);
      !asset[3] ? errors.push('Leased Asset Number is required') : (formatted_asset.leasedNumber = asset[3]);
      if (!asset[4]) {
        errors.push('Lease Start Date is required');
      } else if(asset[4]){
        //if date is available then covert it and validate the value
        const convertedStartValue = getValidDateFromExcelDateValue(asset[4]);
        if (!Moment(convertedStartValue).isValid()) {
          errors.push('Lease Start Date is not in a valid format');
        } else {
          formatted_asset.leaseStartDate = Moment(convertedStartValue).format('l');
        }
      }
      if (!asset[5]) {
        errors.push('Lease End Date is required');
      } else if(asset[5]){
        //if date is available then covert it and validate the value
        const convertedEndValue = getValidDateFromExcelDateValue(asset[5]);
        if (!Moment(convertedEndValue).isValid()) {
          errors.push('Lease End Date is not in a valid format');
        } else {
          formatted_asset.leaseEndDate = Moment(convertedEndValue).format('l');
        }
      }
      let addressLine = '';
      let zipCode = '';
      let city = '';
      let state = '';
      !asset[6] ? errors.push('Address line is required') : (addressLine = asset[6]);
      !asset[7] ? errors.push('Zip code is required') : (zipCode = asset[7]);
      result = uspsvalidator.cityStateLookup(asset[7]).then((res) => {
        formatted_asset.address = {line1: addressLine, zipcode: zipCode, state: getStateId(res.State,stateValues), city:getCityId(res.City,cityValues)}
        return {state: res.State, city: res.City}
      }).catch((err)=>{errors.push('Enter a valid zipcode')});
      formatted_asset.address = {...formatted_asset.address, result: result}

    //   setTimeout(function() {

    //     !asset[7] ? errors.push('Zipcode is required') : uspsvalidator.cityStateLookup(asset[7]).then((res) => {
    //       if(res.State && res.City){
    //         zipCode = asset[7];
    //         city = getCityId(res.City,cityValues);
    //         state = getStateId(res.State,stateValues);
    //         formatted_asset.address = {line1:addressLine,line2:'',zipcode:zipCode,state,city };
    //       } else {
    //         errors.push('Enter a valid Zipcode')
    //       }
    //     });
    //  }, 1000);
     formatted_asset.taxFilingPlanId = taxInfoId;
     

    }

      if (errors && errors.length !== 0) {
        hasErrors = true;
        formatted_asset.errors = errors;
        assets_info_with_errors.push(formatted_asset);
      } else {
        // Avoid populated formatted assets when errors are
        if (!hasErrors) {
          formatted_assets_info.push(formatted_asset);
        }
      }

      return formatted_assets_info;
    });
    console.log('1.2:', new Date().toLocaleString());
    console.log('assets_info_with_errors', assets_info_with_errors);
    console.log('formatted_assets_info', formatted_assets_info);
    return {
      assets_info_with_errors,
      formatted_assets_info: hasErrors ? '' : formatted_assets_info,
    };
  }

  return errors;
}

function getValidDateFromExcelDateValue(inputDate) {
  // JavaScript dates can be constructed by passing milliseconds
  return new Date((inputDate - (25567 + 1)) * 86400 * 1000);
}

const getStateId = (stateName,stateValues) => {
  for(let value of stateValues){
      if(value.codeValueKey === stateName){
          return value.id
      }
  }
  
}
const getCityId = (cityName,cityValues) => {
  for (let value of cityValues) {
      if (value.codeValueKey === cityName) {
          return value.id
      }
  }  
}